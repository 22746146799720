import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { GetOwnerInfoResponseData } from "../types/authTypes";
import {
  GetEntityInfoResponseData,
  GetIndividualInfoResponseData,
  UpdateOwnerPayloadData,
  UpdateIndividualPayloadData,
  UpdateEntityPayloadData,
  GetSettingsDataPayload,
} from "../types/userTypes";

type UserState = {
  ownerInfo: GetOwnerInfoResponseData | null;
  individualInfo: GetIndividualInfoResponseData | null;
  entityInfo: GetEntityInfoResponseData | null;
};

const initialState: UserState = {
  ownerInfo: null,
  individualInfo: null,
  entityInfo: null,
};

const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getSettingsData(_, __: PayloadAction<GetSettingsDataPayload>) {},
    getOwnerInfo(_, __: PayloadAction<number>) {},
    setOwnerInfo(state, action: PayloadAction<GetOwnerInfoResponseData | null>) {
      state.ownerInfo = action.payload;
    },
    updateOwnerInfo(_, __: PayloadAction<UpdateOwnerPayloadData>) {},

    getIndividualInfo(_, __: PayloadAction<number>) {},
    setIndividualInfo(state, action: PayloadAction<GetIndividualInfoResponseData | null>) {
      state.individualInfo = action.payload;
    },
    updateIndividualInfo(_, __: PayloadAction<UpdateIndividualPayloadData>) {},

    getEntityInfo(_, __: PayloadAction<number>) {},
    setEntityInfo(state, action: PayloadAction<GetEntityInfoResponseData | null>) {
      state.entityInfo = action.payload;
    },
    updateEntityInfo(_, __: PayloadAction<UpdateEntityPayloadData>) {},
  },
});

export const {
  getOwnerInfo,
  setOwnerInfo,
  updateOwnerInfo,
  getIndividualInfo,
  setIndividualInfo,
  updateIndividualInfo,
  getEntityInfo,
  setEntityInfo,
  updateEntityInfo,
  getSettingsData,
} = UserSlice.actions;

export default UserSlice.reducer;

export const userName = UserSlice.name;

export const UserSelectors = {
  getOwnerInfo: (state: RootState) => state.user.ownerInfo,
  getIndividualInfo: (state: RootState) => state.user.individualInfo,
  getEntityInfo: (state: RootState) => state.user.entityInfo,
};
