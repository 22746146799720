import React from "react";

export const TickSquareIcon = ({ width = "17", height = "17", fill = "#42AC4D" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.57 2.448H5.42946C3.28958 2.448 1.948 3.96312 1.948 6.10725V11.8929C1.948 14.037 3.28321 15.5522 5.42946 15.5522H11.5693C13.7162 15.5522 15.0522 14.037 15.0522 11.8929V6.10725C15.0522 3.96312 13.7162 2.448 11.57 2.448Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.97949 9.00009L7.66108 10.681L11.0228 7.31921"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
