import { tarifCardType } from "../../redux/types/@types"

export const tariffs:tarifCardType[]= [
  {
    header: "Бесплатный тариф",
    code: "free",
    describe: "Описание тарифа",
    price: "",
    features:[
      "шахматка",
      "ручное заполнение брони"
    ]
  }
]