import React from "react";

export const WarningIcon = ({ width = "6", height = "24", fill = "#ffffff" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 6 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.00005 4.00002C6.52075e-05 8.00004 2.77427 17 3.00008 17C3.22589 17 5.99999 8.00004 4.99998 4.00002C3.99996 -9.53674e-06 2.00003 -1.66893e-06 1.00005 4.00002Z"
        fill={fill}
        stroke={fill}
        strokeLinecap="round"
      />
      <circle cx="3" cy="22" r="2" fill={fill} />
    </svg>
  );
};
