import { FC, useEffect, useRef, useState } from "react";
import {subMonths, addYears, format} from 'date-fns';
import styles from './MobileFilterPanel.module.scss';
import { CloseIcon } from "../../../assets/icons";
import SelectMultipleCustom from "../../SelectMultipleCustom";
import Calendar from "../../Calendar";
import Button from "../../Button";
import { useClickOutside } from "../../../utils/functions";
import { ButtonType, OptionsListType } from "../../../utils/@globalTypes";
import { SelectState } from "../../../redux/types/bookingTypes";
import { statusListOption } from "../../Booking/functions";

type MyProps = {
  close:React.Dispatch<React.SetStateAction<boolean>>;
  startCalendar:Date|undefined;
  endCalendar:Date|undefined;
  objectList: OptionsListType | undefined;
  setSelectObject:React.Dispatch<React.SetStateAction<SelectState[]>>
  selectObject:SelectState[];
  statusNew:boolean;
  setStatusNew:React.Dispatch<React.SetStateAction<boolean>>;
  statusConfirm:boolean;
  setStatusConfirm:React.Dispatch<React.SetStateAction<boolean>>;
  statusAwait:boolean;
  setStatusAwait:React.Dispatch<React.SetStateAction<boolean>>;
  statusLine:boolean;
  setStatusLine:React.Dispatch<React.SetStateAction<boolean>>;
  statusCanceled:boolean;
  setStatusCanceled:React.Dispatch<React.SetStateAction<boolean>>;
  setRangeCalendar:(dates: any) => void
  setStartDate:React.Dispatch<React.SetStateAction<Date | undefined>>
  setEndDate:React.Dispatch<React.SetStateAction<Date | undefined>>
}

const MobileFilterPanel:FC<MyProps> =({
  close,
  startCalendar,
  endCalendar,
  objectList,
  setSelectObject,
  selectObject,
  statusNew,
  statusConfirm,
  statusAwait,
  statusLine,
  statusCanceled,
  setStatusNew,
  setStatusConfirm,
  setStatusAwait,
  setStatusLine,
  setStatusCanceled,
  setRangeCalendar,
  setStartDate,
  setEndDate
}) =>{
  const searchRef = useRef(null);
  const btnSearchRef = useRef(null);

  useClickOutside(searchRef, btnSearchRef, () => close(false));

  const today = new Date();
  const minDate = subMonths(today, 6);
  const maxDate = addYears(today, 1);
  // const beginStorage = sessionStorage.getItem("startDate")
  // const endStorage = sessionStorage.getItem("endDate")
  // const beginstartDate = beginStorage? new Date(beginStorage):undefined
  // const beginendDate = endStorage?new Date(endStorage):undefined
  // const [startDate, setStartDate] = useState(beginstartDate);
  // const [endDate, setEndDate] = useState(beginendDate);
  // const [dateRange, setDateRange] = useState([undefined, undefined]);
  // const [startDate, endDate] = dateRange;
  const calendarPlaceholder = `${format(minDate, 'dd.MM.yyyy')} - ${format(maxDate, 'dd.MM.yyyy')}`

  const clearCalendar =(e:React.MouseEvent<HTMLDivElement, MouseEvent>)=>{
    e.stopPropagation();
    sessionStorage.removeItem("startDate")
    sessionStorage.removeItem("endDate")
    setStartDate(undefined)
    setEndDate(undefined) 
  }

  const [select, setSelect] = useState<SelectState[]>([])   //список объектов для селекта и признак фильтрации
  const [selectStatus, setSelectStatus] = useState<SelectState[]>([]) //фильтрация по статусам в мобилке

  useEffect(()=> {
    selectObject&&setSelect(selectObject)
  },[selectObject]) 

  const handlerSelect = (id:string) => {   //   обработка чекбоксов в списке объектов
    const current=select.findIndex(item=> item.id===id)
    
      if(current>-1){
       setSelect(prevState=> {
        const newSelect = [...prevState]    
        newSelect[current].checked=!newSelect[current].checked     
        return(
          newSelect
        )
       })
      }   
  }  
  const initialSelect = ()=> {   // начальный стейт для списка объектов
    const start:SelectState[] = []
    objectList&&objectList.forEach((item)=>{
      start.push({id:item.value, checked:false})            
    })
    setSelect(start)
  }
  
  const initialStatusSelect = ()=> {   // начальный стейт для списка статусов в мобилке
    const start:SelectState[] = []
    statusListOption&&statusListOption.forEach((item)=>{
      start.push({id:item.value, checked:false})            
    })
    setSelectStatus(start)
  }

  useEffect(()=> {
    const startSelect:SelectState[] =[]
    if (statusNew){
      startSelect.push({id:"new", checked:true})
    } else startSelect.push({id:"new", checked:false})
    if (statusConfirm){
      startSelect.push({id:"cfm", checked:true})
    } else startSelect.push({id:"cfm", checked:false})
    if (statusAwait){
      startSelect.push({id:"awt", checked:true})
    } else startSelect.push({id:"awt", checked:false})
    if (statusLine){
      startSelect.push({id:"que", checked:true})
    } else startSelect.push({id:"que", checked:false})
    if (statusCanceled){
      startSelect.push({id:"cld", checked:true})
    } else startSelect.push({id:"cld", checked:false})
    setSelectStatus(startSelect)
  },[statusNew, statusConfirm, statusAwait, statusLine, statusCanceled, setStatusLine]) 

  const handlerStatusSelect = (id:string) => {   //   обработка чекбоксов в списке статусов
    const current=selectStatus.findIndex(item=> item.id===id)
    
      if(current>-1){
       setSelectStatus(prevState=> {
        const newSelect = [...prevState]    
        newSelect[current].checked=!newSelect[current].checked     
        return(
          newSelect
        )
       })
      }   
  }  
  // useEffect(()=> {
  //   setDateRange([startCalendar, endCalendar])
  // },[])

  const resetAll = () => {
    sessionStorage.clear();
    setStartDate(undefined)
    setEndDate(undefined)
    initialSelect();
    initialStatusSelect();
  }
  const updateStorageWithObjects =() => {
    select?.forEach(item=> {
      const index = objectList?.findIndex(obj=> obj.value===item.id)
      if(index&&index>-1&& item.checked) {
        sessionStorage.setItem(`${item.id}`, "true") 
      }else {sessionStorage.removeItem(`${item.id}`)} 
    })
  }

  const handleSubmit = () => {  
    close(false);      
    const currentNew = selectStatus.find(item=> item.id==="new");
    currentNew&&setStatusNew(currentNew.checked);
    const currentAwait = selectStatus.find(item=> item.id==="awt");
    currentAwait&&setStatusAwait(currentAwait.checked);
    const currentLine = selectStatus.find(item=> item.id==="que");
    currentLine&&setStatusLine(currentLine.checked);
    const currentConfirm = selectStatus.find(item=> item.id==="cfm");
    currentConfirm&&setStatusConfirm(currentConfirm.checked);
    const currentCanceled = selectStatus.find(item=> item.id==="cld");
    currentCanceled&&setStatusCanceled(currentCanceled.checked);
    setSelectObject(select);
    updateStorageWithObjects();
    // setRangeCalendar(startDate, endDate);
    setStartDate(startCalendar)
    setEndDate(endCalendar)
    
  }
  return(
    <div className={styles.filterWindow} ref={searchRef}>
      <div className={styles.filterHeader}>
        <div className={styles.filterHeader_Title}>
          Выберите параметры поиска
        </div>
        <div onClick={()=>close(false)} ref={btnSearchRef}>
          <CloseIcon width='24' height='24' fill='#0A3A86'/>
        </div>
      </div>
      <div className={styles.mobileSelect}>
        <div className={styles.objectSelect}>
          <h5 className={styles.selectHeader}>
            Объект
          </h5>
          <SelectMultipleCustom
            optionList={objectList}
            select={select}
            handler={handlerSelect}
            deleteAll={initialSelect}
          />
        </div>
        <div className={styles.dateSelect}>
          <h5 className={styles.selectHeader}>
            Период брони
          </h5>                
          <Calendar
            selectedDate={today}
            maxDate={maxDate}
            minDate={minDate}
            selectsRange={true}
            startDate={startCalendar}
            endDate={endCalendar}
            onChange={setRangeCalendar}
            inputClassname='bookingList'
            placeholderText={calendarPlaceholder}     
            clearCalendar={clearCalendar}         
          />                
        </div>
        <div className={styles.statusSelect}>
          <h5 className={styles.selectHeader}>
            Статус
          </h5>
          <SelectMultipleCustom
            optionList={statusListOption}
            select={selectStatus}
            handler={handlerStatusSelect}
            deleteAll={initialStatusSelect}
          />
        </div>
      </div>
      <div className={styles.mobileFilterbtn}>
        <Button
          title="Сбросить"
          type={ButtonType.SECONDARY_SMALL}
          className="mobileButton" 
          onClick={resetAll}
        />
        <Button
          title="Применить"
          type={ButtonType.PRIMARY_SMALL}
          className="mobileButton" 
          onClick={handleSubmit}
        />
      </div>   
    </div>
  )   
}

export default MobileFilterPanel;