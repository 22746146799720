import React from "react";

export const Belarus = ({ width = "16", height = "12"}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="flag-icon-css-by" viewBox="0 0 640 480" 
      width={width} height={height}>
      <defs>
        <clipPath id="by-a">
          <path fillOpacity=".7" d="M0 0h682.6v512H0z"/>
        </clipPath>
      </defs>
      <g fillRule="evenodd" clipPath="url(#by-a)" transform="scale(.9376 .9375)">
        <path fill="#b20000" d="M0 0h1024v340.1H0z"/>
        <path fill="#429f00" d="M0 340.1h1024V512H0z"/>
        <path fill="#fff" d="M0 0h113.4v512H0z"/>
        <g fill="#b20000" strokeWidth="1pt">
          <path d="M5.4 8.7h5.4v8.7H5.4zm16.2 0h16.2v8.7H21.6zM27 0h5.4v8.7H27zm27 0h5.4v8.7H54zm21.6 8.7h16.2v8.7H75.6zM81 0h5.4v8.7H81zM16.2 17.4H27V26H16.2zm16.2 0h10.8V26H32.4zm37.8 0H81V26H70.2zm16.2 0h10.8V26H86.4zM10.8 26h10.8v8.7H10.8zm27 0h10.8v8.7H37.8zm27 0h10.8v8.7H64.8zm27 0h10.8v8.7H91.8zm10.8-17.3h5.4v8.7h-5.4zm-97.2 26h10.8v8.7H5.4zm37.8 0H54v8.7H43.2zm54 0H108v8.7H97.2zm-86.4 26h10.8v8.7H10.8zm5.4 8.7H27v8.7H16.2zm21.6-8.6h10.8v8.6H37.8zm-5.4 8.6h10.8v8.7H32.4zm-10.8 8.7h16.2v8.7H21.6z"/>
          <path d="M27 86.8h5.4v8.7H27zm37.8-26h10.8v8.6H64.8zm5.4 8.6H81v8.7H70.2zm21.6-8.6h10.8v8.6H91.8zm-5.4 8.6h10.8v8.7H86.4zm-10.8 8.7h16.2v8.7H75.6zm5.4 8.7h5.4v8.7H81zM54 78h5.4v8.7H54zM0 78h5.4v8.7H0zm108 0h5.4v8.7H108zm-81 43.4h5.4v8.7H27zm-5.4 8.8h16.2v8.6H21.6zm-5.4 8.6h27v8.7h-27zM81 121.5h5.4v8.7H81zm-5.4 8.7h16.2v8.6H75.6zm-5.4 8.6h27v8.7h-27zm5.4 43.4h27v8.7h-27zm-10.8-34.7h37.8v8.7H64.8zm-54 0h37.8v8.7H10.8zm5.4 43.4h16.2v8.7H16.2zm-5.4-8.7h27v8.7h-27zM86.4 165h27v8.7h-27zM81 191h16.2v8.7H81z"/>
          <path d="M21.6 199.6H27v8.7h-5.4zm64.8 0h5.4v8.7h-5.4zM0 164.9h27v8.7H0zm48.6 17.3h16.2v8.7H48.6zM54 191h5.4v8.7H54zm-16.2-26h37.8v8.7H37.8zM59.4 34.7h10.8v8.7H59.4zm-10.8 8.7h16.2v8.7H48.6zm-5.4 8.7H54v8.6H43.2zm16.2 0h10.8v8.6H59.4zM0 43.4h10.8v8.7H0zm5.4 8.7h10.8v8.6H5.4zm97.2-8.7h10.8V52h-10.8zM97.2 52H108v8.6H97.2zM0 130h5.4v8.7H0zm108 0h5.4v8.7H108zm-59.4 69.5h16.2v8.7H48.6zm-5.4 8.7H54v8.6H43.2zm16.2 0h10.8v8.6H59.4zm-21.6 8.6h10.8v8.7H37.8zm27 0h10.8v8.7H64.8zm-32.4 8.7h10.8v8.7H32.4zm37.8 0H81v8.7H70.2zM27 234.3h10.8v8.7H27zm48.6 0h10.8v8.7H75.6zM16.2 243h16.2v8.7H16.2zm64.8 0h16.2v8.7H81zm10.8-8.7h10.8v8.7H91.8zm5.4-8.7H108v8.7H97.2zm5.4-8.7h10.8v8.7h-10.8zm-91.8 17.4h10.8v8.7H10.8zm-5.4-8.7h10.8v8.7H5.4zM0 217h10.8v8.7H0zm21.6 34.8H27v8.6h-5.4zm64.8 0h5.4v8.6h-5.4zm-32.4-26h5.4v8.6H54zm-16.2 26h5.4v8.6h-5.4zm32.4 0h5.4v8.6h-5.4zm-16.2 0h5.4v8.6H54zm-54 0h5.4v8.6H0zm108 0h5.4v8.6H108zM54 130.2h5.4v8.6H54zM27 43.4h5.4v8.7H27zm54 0h5.4v8.7H81zm-32.4 60.7h16.2v8.7H48.6zm-10.8 8.7H54v8.7H37.8zm5.4 8.7h5.4v8.7h-5.4zm-10.8-17.4h10.8v8.7H32.4zm5.4-8.6H54v8.6H37.8zm5.4-8.7h5.4v8.7h-5.4zm16.2 8.7h16.2v8.6H59.4zm5.4-8.7h5.4v8.7h-5.4zm5.4 17.3H81v8.7H70.2zm-10.8 8.7h16.2v8.7H59.4zm5.4 8.7h5.4v8.7h-5.4zm21.6-17.4h10.8v8.7H86.4zm5.4-8.6H108v8.6H91.8zm10.8 8.6h10.8v8.7h-10.8zm-10.8 8.7H108v8.7H91.8zm5.4 8.7h5.4v8.7h-5.4zm0-34.7h5.4v8.7h-5.4zM0 104h10.8v8.7H0zm5.4-8.6h16.2v8.6H5.4zm10.8 8.6H27v8.7H16.2zm-10.8 8.7h16.2v8.7H5.4z"/>
          <path d="M10.8 121.5h5.4v8.7h-5.4zm0-34.7h5.4v8.7h-5.4zm-5.4 69.4H27v8.7H5.4zm27 0H54v8.7H32.4zm27 0H81v8.7H59.4zm27 0H108v8.7H86.4zm-43.2 17.4h27v8.6h-27zm-37.8 0h27v8.6h-27zm75.6 0h27v8.6H81zm27 34.7h5.4v8.6H108zm-108 0h5.4v8.6H0zm5.4 295h5.4v-8.6H5.4zm16.2 0h16.2v-8.6H21.6zM27 512h5.4v-8.7H27zm27 0h5.4v-8.7H54zm21.6-8.7h16.2v-8.7H75.6zM81 512h5.4v-8.7H81zm-64.8-17.4H27V486H16.2zm16.2 0h10.8V486H32.4zm37.8 0H81V486H70.2zm16.2 0h10.8V486H86.4zM37.8 486h10.8v-8.7H37.8zm27 0h10.8v-8.7H64.8zm27 0h10.8v-8.7H91.8zm10.8 17.3h5.4v-8.7h-5.4zm-97.2-26h10.8v-8.7H5.4zm37.8 0H54v-8.7H43.2zm54 0H108v-8.7H97.2zm-86.4-26h10.8v-8.7H10.8zm5.4-8.7H27v-8.7H16.2zm21.6 8.7h10.8v-8.7H37.8zm-5.4-8.7h10.8v-8.7H32.4zm-10.8-8.7h16.2v-8.7H21.6zm5.4-8.7h5.4v-8.6H27zm37.8 26h10.8v-8.6H64.8zm5.4-8.6H81v-8.7H70.2zm21.6 8.7h10.8v-8.7H91.8zm-5.4-8.7h10.8v-8.7H86.4zm-10.8-8.7h16.2v-8.7H75.6zm5.4-8.7h5.4v-8.6H81zM54 434h5.4v-8.7H54zm-54 0h5.4v-8.7H0zm108 0h5.4v-8.7H108zm-81-43.4h5.4v-8.7H27zm-5.4-8.8h16.2v-8.6H21.6zm-5.4-8.6h27v-8.7h-27zM81 390.5h5.4v-8.7H81z"/>
          <path d="M75.6 381.8h16.2v-8.6H75.6zm-5.4-8.6h27v-8.7h-27zm5.4-43.4h27V321h-27zm-10.8 34.7h37.8v-8.7H64.8zm-54 0h37.8v-8.7H10.8zm5.4-43.4h16.2v-8.7H16.2zm-5.4 8.7h27V321h-27zM86.4 347h27v-8.7h-27zM81 321h16.2v-8.7H81zM0 347h27v-8.7H0zm48.6-17.3h16.2V321H48.6zM54 321h5.4v-8.7H54zm-16.2 26h37.8v-8.7H37.8zm21.6 130.2h10.8v-8.7H59.4zm-10.8-8.7h16.2V460H48.6zm-5.4-8.7H54v-8.7H43.2zm16.2 0h10.8v-8.7H59.4zM0 468.6h10.8V460H0zm5.4-8.7h10.8v-8.6H5.4zm97.2 8.7h10.8V460h-10.8zm-5.4-8.7H108v-8.6H97.2zM0 382h5.4v-8.7H0zm108 0h5.4v-8.7H108zm-64.8-78.2H54v-8.6H43.2zm16.2 0h10.8v-8.6H59.4zm-21.6-8.6h10.8v-8.7H37.8zm27 0h10.8v-8.7H64.8zm-32.4-8.7h10.8v-8.7H32.4zm37.8 0H81v-8.7H70.2zM27 277.7h10.8V269H27zm48.6 0h10.8V269H75.6zM16.2 269h16.2v-8.7H16.2zm64.8 0h16.2v-8.7H81zm10.8 8.7h10.8V269H91.8zm5.4 8.7H108v-8.7H97.2zm5.4 8.7h10.8v-8.7h-10.8zm-91.8-17.4h10.8V269H10.8zm-5.4 8.7h10.8v-8.7H5.4zM0 295h10.8v-8.7H0zm21.6-34.8H27v-8.6h-5.4zm32.4 26h5.4v-8.6H54zm0 95.5h5.4v-8.6H54zm-27 86.8h5.4V460H27zm54 0h5.4V460H81zM48.6 408h16.2v-8.7H48.6zm-10.8-8.7H54v-8.7H37.8zm5.4-8.7h5.4v-8.7h-5.4zM32.4 408h10.8v-8.7H32.4zm5.4 8.5H54V408H37.8zm5.4 8.7h5.4v-8.6h-5.4zm16.2-8.7h16.2V408H59.4zm5.4 8.7h5.4v-8.6h-5.4zm5.4-17.3H81v-8.7H70.2zm-10.8-8.7h16.2v-8.7H59.4zm5.4-8.7h5.4v-8.7h-5.4zM86.4 408h10.8v-8.7H86.4zm5.4 8.5H108V408H91.8zm10.8-8.6h10.8v-8.7h-10.8zm-10.8-8.7H108v-8.7H91.8zm5.4-8.7h5.4v-8.7h-5.4zm0 34.7h5.4v-8.6h-5.4zM0 408h10.8v-8.7H0zm5.4 8.5h16.2V408H5.4z"/>
          <path d="M16.2 407.9H27v-8.7H16.2zm-10.8-8.7h16.2v-8.7H5.4zm5.4-8.7h5.4v-8.7h-5.4zm0 34.7h5.4v-8.6h-5.4zm-5.4-69.4H27v-8.7H5.4zm27 0H54v-8.7H32.4zm27 0H81v-8.7H59.4zm27 0H108v-8.7H86.4zm-43.2-17.4h27v-8.6h-27zm-37.8 0h27v-8.6h-27zm75.6 0h27v-8.6H81zm27-34.6h5.4V295H108zm-108 0h5.4V295H0zm48.6-43.5H54v8.7h-5.4zm10.8 0h5.4v8.7h-5.4zm0-17.3h5.4v8.7h-5.4zm-10.8 0H54v8.7h-5.4zM10.8 477.3h10.8v8.7H10.8zm75.6-164.9h5.4v-8.7h-5.4zm-64.8 0H27v-8.7h-5.4zm27-8.7h16.2v8.7H48.6z"/>
        </g>
      </g>
    </svg>
  );
};
