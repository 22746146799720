import React, { useEffect, useState } from 'react';
import { tariffs } from './tarifDatas';

import styles from "./PriceCard.module.scss";
import { tarifCardType } from '../../redux/types/@types';

type priceCardProps = {
  tarif:tarifCardType,
}

const PriceCard = ({
  tarif
}:priceCardProps) => {

  return (
    <div className={styles.container}>
      <div className={styles.firstLine}>
        <header className={styles.header}>
          {tarif.header}
        </header>
        <div className={styles.status}>
          
        </div>
      </div>
    </div>
  )
}

export default PriceCard;