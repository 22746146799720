import { TabListType } from "../../components/Tabs/@types";
import { TabsNames } from "../../utils/@globalTypes";

export const tabsList: TabListType = [
  {
    title: "Личные данные",
    path: TabsNames.PERSON_SETTINGS,
  },
  {
    title: "Данные об организации",
    path: TabsNames.ORGANIZATION_SETTINGS,
  },
];
