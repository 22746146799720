import React, { FC, ReactNode } from "react";
import classNames from "classnames";
import styles from "./EmptyState.module.scss";

type EmptyStateProps = {
  icon: ReactNode;
  text: string;
  className?: string;
};

const EmptyState: FC<EmptyStateProps> = ({ icon, text, className }) => {
  return (
    <div className={classNames(styles.wrapper, className, className && styles[className])}>
      {icon}
      <p className={styles.descr}>{text}</p>
    </div>
  );
};

export default EmptyState;
