import React, { FC } from "react";
import Lottie from "lottie-react";
import styles from "./Loader.module.scss";
import loader from "../../assets/loader.json";

type LoaderProps = {
  size?: number;
};

const Loader: FC<LoaderProps> = ({ size = 70 }) => {
  return (
    <div className={styles.loaderWrapper}>
      <Lottie style={{ width: size, height: size }} animationData={loader} loop={true} />
    </div>
  );
};

export default Loader;
