import React, { useEffect, useMemo, useState } from "react";
import ModalWindow from "../ModalWindow";

import { useDispatch, useSelector } from "react-redux";
import { banDoubleSpace, getErrorText, setFieldErrorText } from "../../../utils/functions";
import {
  FieldTypes,
  InputType,
  LoaderTypes,
  ModalWindowTypes,
  TabsNames,
} from "../../../utils/@globalTypes";
import Input from "../../Input/Input";
import SelectComponent from "../../SelectComponent/SelectComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { PageSelectors, setModalWindowData } from "../../../redux/reducers/pageSlice";
import { MainObjectSelectors, createMainObject } from "../../../redux/reducers/mainObjectSlice";
import { RoutesList } from "../../../App";
import { OBJECT_NAME_ERROR, OBJECT_NAME_REQ, REQUIRED_FIELD_ERROR } from "../../../utils/constants";
import { AuthSelectors, setErrorResponeData } from "../../../redux/reducers/authSlice";

const CreatingMainObjectWindow = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const errorResponseData = useSelector(AuthSelectors.getErrorResponseData);
  const objectTypes = useSelector(MainObjectSelectors.getMainObjectTypesData);
  const isLoading = useSelector(PageSelectors.getLoadersData(LoaderTypes.CREATE_OBJECT_POPUP));
  const countriesList = useSelector(MainObjectSelectors.getCountriesOptions);

  const [country, setCountry] = useState("");
  const [objectName, setObjectName] = useState("");
  const [objectType, setObjectType] = useState("");

  const [countryError, setCountryError] = useState("");
  const [objectNameError, setObjectNameError] = useState("");
  const [objectTypeError, setObjectTypeError] = useState("");

  const [countryTouched, setCountryTouched] = useState(false);
  const [objectNameTouched, setObjectNameTouched] = useState(false);
  const [objectTypeTouched, setObjectTypeTouched] = useState(false);

  const onSaveBtnClick = () => {
    setCountryTouched(true);
    setObjectNameTouched(true);
    setObjectTypeTouched(true);

    if (!objectNameError && !objectTypeError && !countryError) {
      dispatch(
        createMainObject({
          data: {
            name: objectName.trim(),
            object_type: +objectType,
            country: country,
          },
          callback: (id: number) => {
            navigate(
              `${RoutesList.objectManagement.objectEditingChild.fullPath}/${id}/${TabsNames.BASIC_SETTINGS}`
            );
          },
        })
      );
    }
  };

  useEffect(() => {
    if (errorResponseData) {
      errorResponseData.name &&
        setObjectNameError(
          getErrorText(errorResponseData.name[0], OBJECT_NAME_ERROR, FieldTypes.OBJECT_NAME)
        );

      errorResponseData.country &&
        setCountryError(
          getErrorText(errorResponseData.country[0], REQUIRED_FIELD_ERROR, FieldTypes.OBJECT_NAME)
        );

      errorResponseData.object_type &&
        setObjectTypeError(
          getErrorText(
            errorResponseData.object_type[0],
            REQUIRED_FIELD_ERROR,
            FieldTypes.OBJECT_NAME
          )
        );

      dispatch(setErrorResponeData(null));
    }
  }, [errorResponseData]);

  // Object name

  useEffect(() => {
    setFieldErrorText(
      objectNameTouched,
      objectName,
      setObjectNameError,
      FieldTypes.OBJECT_NAME,
      true
    );
  }, [objectNameTouched, objectName]);

  // Country

  useEffect(() => {
    setFieldErrorText(countryTouched, country, setCountryError, FieldTypes.DEFAULT, true);
  }, [countryTouched, country]);

  // Object type

  useEffect(() => {
    setFieldErrorText(objectTypeTouched, objectType, setObjectTypeError, FieldTypes.DEFAULT, true);
  }, [objectTypeTouched, objectType]);

  const isValid = useMemo(() => {
    return (
      !objectNameError &&
      !objectTypeError &&
      !countryError &&
      objectName.length > 0 &&
      objectType.length > 0 &&
      country.length > 0
    );
  }, [objectNameError, objectTypeError, countryError, objectName, objectType, country]);

  useEffect(() => {
    if (location.pathname !== RoutesList.objectManagement.fullPath) {
      dispatch(setModalWindowData({ type: ModalWindowTypes.CLOSE }));
    }
  }, [location]);

  return (
    <ModalWindow
      title="Объект"
      onSubmit={onSaveBtnClick}
      isValid={!isValid}
      isLoading={isLoading}
      hideCross
    >
      <Input
        value={objectName}
        title="Название объекта*"
        placeholder="Введите название объекта"
        type={InputType.TEXT}
        onChange={setObjectName}
        errText={objectNameError}
        onBlur={setObjectNameTouched}
        requirementsText={OBJECT_NAME_REQ}
        customBanSymbols={banDoubleSpace}
      />
      <SelectComponent
        title="Страна*"
        optionsList={countriesList}
        currentValue={country}
        setSelecValue={setCountry}
        errText={countryError}
        isLoading={!countriesList}
        onBlur={setCountryTouched}
        isSearchable
      />
      <SelectComponent
        title="Тип объекта размещения*"
        optionsList={objectTypes.optionsList}
        currentValue={objectType}
        setSelecValue={setObjectType}
        errText={objectTypeError}
        isLoading={objectTypes.optionsList.length === 0}
        onBlur={setObjectTypeTouched}
      />
    </ModalWindow>
  );
};

export default CreatingMainObjectWindow;
