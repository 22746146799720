import React from "react";

export const WashingMachineIcon = ({ width = "24", height = "24", fill = "#0F2664" }) => {
  return (
    <div className="feature">
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.7912 0H4.03955C3.19067 0 2.5 0.690674 2.5 1.53955V15.6006C2.5 15.7949 2.65747 15.9521 2.85156 15.9521C3.04584 15.9521 3.20312 15.7949 3.20312 15.6006V5.08887H20.6276V20.1691C20.6276 20.6303 20.2524 21.0055 19.7912 21.0055H4.03955C3.57831 21.0055 3.20312 20.6303 3.20312 20.1691V17.0067C3.20312 16.8124 3.04584 16.6551 2.85156 16.6551C2.65729 16.6551 2.5 16.8124 2.5 17.0067V20.1691C2.5 20.9412 3.07129 21.5823 3.81342 21.692V23.3672C3.81342 23.7162 4.09741 24 4.44623 24H5.68182C5.94403 24 6.18207 23.8352 6.27454 23.5895L6.46753 23.0748H17.2938L17.4868 23.5898C17.5791 23.8352 17.8173 24 18.0793 24H19.3843C19.7333 24 20.0172 23.7162 20.0172 23.3672V21.692C20.7593 21.5823 21.3307 20.9412 21.3307 20.1691V1.53955C21.3307 0.690674 20.6401 0 19.7912 0ZM18.1281 23.2969L17.9118 22.7201C17.8331 22.5115 17.6302 22.3715 17.4072 22.3715H6.35364C6.13098 22.3715 5.92847 22.5115 5.84918 22.721L5.6333 23.2969H4.51654V21.7086H19.314V23.2969H18.1281ZM3.20312 4.38574V1.53955C3.20312 1.07831 3.57831 0.703125 4.03955 0.703125H19.7912C20.2524 0.703125 20.6276 1.07831 20.6276 1.53955V4.38574H3.20312Z"
          fill={fill}
        />
        <path
          d="M19.5729 9.54645C19.767 9.54645 19.9245 9.38916 19.9245 9.19489V6.14453C19.9245 5.95044 19.767 5.79297 19.5729 5.79297H4.25781C4.06372 5.79297 3.90625 5.95044 3.90625 6.14453V19.9518C3.90625 20.1461 4.06372 20.3033 4.25781 20.3033H19.5729C19.767 20.3033 19.9245 20.1461 19.9245 19.9518V10.6011C19.9245 10.407 19.767 10.2496 19.5729 10.2496C19.3787 10.2496 19.2214 10.407 19.2214 10.6011V19.6002H4.60938V6.49609H19.2214V9.19489C19.2214 9.38916 19.3787 9.54645 19.5729 9.54645Z"
          fill={fill}
        />
        <rect x="17.9219" y="2" width="1" height="1" rx="0.5" fill={fill} />
        <rect x="13.9219" y="2" width="1" height="1" rx="0.5" fill={fill} />
        <rect x="15.9219" y="2" width="1" height="1" rx="0.5" fill={fill} />
        <path
          d="M11.9219 18C14.6833 18 16.9219 15.7614 16.9219 13C16.9219 10.2386 14.6833 8 11.9219 8C9.16045 8 6.92188 10.2386 6.92188 13C6.92188 15.7614 9.16045 18 11.9219 18Z"
          stroke={fill}
          strokeWidth="0.7"
        />
        <path
          d="M12.908 16.4427C12.9525 16.6183 12.8464 16.7981 12.6679 16.8281C12.1594 16.9136 11.6382 16.9001 11.1331 16.7873C10.521 16.6507 9.94964 16.372 9.46512 15.9738C8.9806 15.5757 8.59649 15.0691 8.3438 14.4952C8.13524 14.0214 8.02103 13.5127 8.00634 12.9974C8.00119 12.8164 8.15703 12.6775 8.33786 12.6871C8.51869 12.6968 8.65574 12.8515 8.66381 13.0324C8.6822 13.4449 8.77686 13.8513 8.94398 14.2309C9.15524 14.7108 9.47638 15.1343 9.88147 15.4672C10.2866 15.8001 10.7642 16.033 11.276 16.1473C11.6807 16.2377 12.0979 16.2518 12.5061 16.1899C12.6851 16.1627 12.8634 16.2672 12.908 16.4427Z"
          fill={fill}
        />
      </svg>
      <div>Стиральная машина</div>
    </div>
  );
};
