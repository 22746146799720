import React, { FC, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import PagesContainer from "./PagesContainer";
import { useDispatch, useSelector } from "react-redux";
import { AuthSelectors, getCurrentUserInfo } from "../redux/reducers/authSlice";
import { PageSelectors } from "../redux/reducers/pageSlice";
import Message from "../components/Message";
import ModalWindowProvider from "./ModalWindowProvider";
import { RoutesList } from "../App";

type AuthProviderProps = {
  isFormPages: boolean;
};

const AuthProvider: FC<AuthProviderProps> = ({ isFormPages }) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(AuthSelectors.getLoggedIn);
  const message = useSelector(PageSelectors.getMessage);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getCurrentUserInfo());
    }
  }, [isLoggedIn]);

  return (
    <>
      {isLoggedIn && isFormPages ? (
        <Navigate to={RoutesList.checkerboard.fullPath} />
      ) : isLoggedIn && !isFormPages ? (
        <PagesContainer>
          <Outlet />
        </PagesContainer>
      ) : !isLoggedIn && isFormPages ? (
        <Outlet />
      ) : !isLoggedIn && !isFormPages ? (
        <Navigate to={RoutesList.signIn.fullPath} replace />
      ) : (
        <Navigate to={RoutesList.notFound.fullPath} replace />
      )}
      {message && message.code !== 400 && (
        <Message status={message.status} code={message.code} message={message.message} />
      )}
      <ModalWindowProvider />
    </>
  );
};

export default AuthProvider;
