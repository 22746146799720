import React from "react";

export const TarifIcon = ({ width = "40", height = "40", fill="#8692A6" }) => {
  return (
    <svg 
      width={width}
      height={height} 
      viewBox="0 0 40 40" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg">
      <rect x="7" y="6" width="26" height="26" rx="13" stroke={fill} strokeWidth="2"/>
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M21 10.0001C21 9.44784 20.5523 9.00012 20 9.00012C19.4477 9.00012 19 9.44784 19 10.0001V10.5593C17.8299 10.6995 16.7534 11.0852 15.8961 11.6773C14.7955 12.4376 14 13.5904 14 15.0001C14 16.2741 14.4917 17.4572 15.5778 18.2927C16.4344 18.9516 17.5876 19.3386 19 19.4591V24.422C18.2161 24.2964 17.5405 24.028 17.0328 23.6773C16.3238 23.1875 16 22.5904 16 22.0001C16 21.4478 15.5523 21.0001 15 21.0001C14.4477 21.0001 14 21.4478 14 22.0001C14 23.4098 14.7955 24.5627 15.8961 25.3229C16.7534 25.915 17.8299 26.3007 19 26.4409V27.0001C19 27.5524 19.4477 28.0001 20 28.0001C20.5523 28.0001 21 27.5524 21 27.0001V26.4436C22.1622 26.3102 23.2376 25.9426 24.0974 25.3586C25.2122 24.6014 26 23.4437 26 22.0001C26 20.6935 25.5144 19.5023 24.4158 18.6713C23.557 18.0217 22.4039 17.6535 21 17.5391V12.5783C21.7839 12.7038 22.4596 12.9723 22.9672 13.3229C23.6762 13.8127 24 14.4099 24 15.0001C24 15.5524 24.4477 16.0001 25 16.0001C25.5523 16.0001 26 15.5524 26 15.0001C26 13.5904 25.2045 12.4376 24.1039 11.6773C23.2467 11.0852 22.1701 10.6995 21 10.5593V10.0001ZM19 12.5783C18.2161 12.7038 17.5405 12.9723 17.0328 13.3229C16.3238 13.8127 16 14.4099 16 15.0001C16 15.7261 16.2583 16.293 16.7972 16.7075C17.243 17.0504 17.9469 17.3385 19 17.4503V12.5783ZM21 19.5471V24.4264C21.7911 24.3065 22.4682 24.0475 22.9737 23.7041C23.6685 23.2322 24 22.6399 24 22.0001C24 21.2233 23.7356 20.6645 23.2093 20.2664C22.7658 19.931 22.0616 19.6538 21 19.5471Z" 
        fill={fill}         
      />
    </svg>
  );
};