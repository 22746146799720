import React from "react";

export const TerraceIcon = ({ width = "24", height = "24", fill = "#0F2664" }) => {
  return (
    <div className="feature">
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.6992 20.0366V10.1816"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.16699 8.73039C3.16699 6.12079 6.98917 4 11.6986 4C16.4081 4 20.2303 6.12079 20.2303 8.73039"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.2303 8.73047C20.2303 9.53463 16.4081 10.1811 11.6986 10.1811C6.98917 10.1811 3.16699 9.53463 3.16699 8.73047"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.81836 7.84638C7.23641 7.32603 7.91894 6.64013 8.78917 6.34054C9.1475 6.22228 9.46317 6.18286 9.73619 6.19863"
          stroke={fill}
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M6.85254 16.5547H7.82515"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.62211 16.5547L4.29297 20.3171"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.90234 18.2949L9.11384 20.2572"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.68262 13.4922L4.06295 18.2955"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.0625 18.2949H9.36066"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.5625 16.5547H15.5898"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.792 16.5547L19.1211 20.3171"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.5123 18.2949L14.3008 20.2572"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.7319 13.4922L19.3516 18.2955"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.3519 18.2949H14.0537"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <div>Терраса</div>
    </div>
  );
};
