import React from "react";

export const EyeIcon = ({ width = "25", height = "25", fill = "#696F79" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7932 12.5553C15.7932 14.3741 14.3182 15.848 12.4995 15.848C10.6807 15.848 9.20679 14.3741 9.20679 12.5553C9.20679 10.7355 10.6807 9.26157 12.4995 9.26157C14.3182 9.26157 15.7932 10.7355 15.7932 12.5553Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4978 20.1614C16.4645 20.1614 20.0926 17.3093 22.1353 12.5551C20.0926 7.80094 16.4645 4.94885 12.4978 4.94885H12.502C8.53533 4.94885 4.90721 7.80094 2.8645 12.5551C4.90721 17.3093 8.53533 20.1614 12.502 20.1614H12.4978Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
