import React, { FC, useEffect, useState } from "react";
import styles from "./Message.module.scss";
import { useDispatch } from "react-redux";
import { setMessage } from "../../redux/reducers/pageSlice";
import { MessageTypes } from "../../utils/@globalTypes";
import { BigCheckmarkIcon, CloseIcon, WarningIcon } from "../../assets/icons";
import classNames from "classnames";

type MessageProps = {
  status: MessageTypes;
  code: number;
  message: string;
};

const Message: FC<MessageProps> = ({ status, message, code }) => {
  const dispatch = useDispatch();

  const [visibility, setVisibility] = useState(false);

  const isError = status === MessageTypes.ERROR;
  const isPositive = status === MessageTypes.POSITIVE;
  const isWarning = status === MessageTypes.WARNING;

  const getStatusError = () => {
    if (code > 400 && code < 500) {
      return "Клиентская ошибка";
    } else if (code >= 500) {
      return "Серверная ошибка";
    } else {
      return;
    }
  };

  const getStatusIcon = () => {
    switch (status) {
      case MessageTypes.POSITIVE:
        return <BigCheckmarkIcon />;
      case MessageTypes.ERROR:
        return <CloseIcon fill="#ffffff" />;
      case MessageTypes.WARNING:
        return <WarningIcon />;
      default:
        return;
    }
  };

  useEffect(() => {
    setVisibility(true);
    setTimeout(() => {
      setVisibility(false);
    }, 2700);
    setTimeout(() => {
      dispatch(setMessage(null));
    }, 3000);
  }, []);

  return (
    <div
      className={classNames(styles.message, {
        [styles.errorBorder]: isError || isWarning,
        [styles.positiveBorder]: isPositive,
        [styles.showMessage]: visibility,
      })}
    >
      <div
        className={classNames(styles.iconWrapper, {
          [styles.positive]: isPositive,
          [styles.error]: isError || isWarning,
        })}
      >
        {getStatusIcon()}
      </div>
      <div className={styles.textWrapper}>
        <h4 className={styles.statusTitle}>{isError ? getStatusError() : message}</h4>
        {isError && <p className={styles.description}>{message}</p>}
      </div>
    </div>
  );
};

export default Message;
