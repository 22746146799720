import React from "react";

export const MicrowaveIcon = ({ width = "24", height = "24", fill = "#0F2664" }) => {
  return (
    <div className="feature">
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.3485 4.13867H1.65152C0.740701 4.13867 0 4.88262 0 5.79691V18.2047C0 19.119 0.740701 19.863 1.65152 19.863H22.3485C23.2593 19.863 24 19.119 24 18.2047V5.79691C24 4.88262 23.2593 4.13867 22.3485 4.13867ZM23.1724 18.2047C23.1724 18.6628 22.8026 19.0353 22.3485 19.0353H1.65152C1.19728 19.0353 0.827605 18.6628 0.827605 18.2047V5.79691C0.827605 5.33887 1.1974 4.96628 1.65152 4.96628H22.3485C22.8027 4.96628 23.1724 5.33887 23.1724 5.79691V18.2047Z"
          fill={fill}
        />
        <path
          d="M16.9656 5.79297H2.06908C1.84039 5.79297 1.65527 5.97831 1.65527 6.20677V17.793C1.65527 18.0215 1.84039 18.2068 2.06908 18.2068H16.9656C17.1943 18.2068 17.3794 18.0215 17.3794 17.793V6.20688C17.3793 5.9782 17.1943 5.79297 16.9656 5.79297ZM2.48277 17.3791V6.62069H16.5517V17.3792H2.48277V17.3791Z"
          fill={fill}
        />
        <path
          d="M19.862 13.6562C18.7213 13.6562 17.793 14.5844 17.793 15.7253C17.793 16.8661 18.7211 17.7943 19.862 17.7943C21.0027 17.7943 21.931 16.8661 21.931 15.7253C21.931 14.5844 21.0027 13.6562 19.862 13.6562ZM18.6206 15.7253C18.6206 15.0407 19.1774 14.4839 19.862 14.4839C20.053 14.4839 20.232 14.5308 20.3938 14.6082L18.745 16.2571C18.6676 16.0953 18.6206 15.9162 18.6206 15.7253ZM19.862 16.9668C19.6709 16.9668 19.492 16.9199 19.3301 16.8425L20.979 15.1935C21.0564 15.3554 21.1034 15.5344 21.1034 15.7254C21.1034 16.4097 20.5465 16.9668 19.862 16.9668Z"
          fill={fill}
        />
        <path
          d="M21.2746 10.3449L20.6895 9.75977L18.6414 11.8078L19.2265 12.3929L21.2746 10.3449Z"
          fill={fill}
        />
        <path
          d="M21.2746 7.4484L20.6895 6.86328L18.6414 8.91132L19.2265 9.49644L21.2746 7.4484Z"
          fill={fill}
        />
      </svg>
      <div>Микроволновая печь</div>
    </div>
  );
};
