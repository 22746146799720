import React, { FC, ReactNode, useEffect, useRef } from "react";
import ImageUploading, { ErrorsType, ImageListType } from "react-images-uploading";
import Button from "../Button";
import { ButtonType } from "../../utils/@globalTypes";
import classNames from "classnames";
import styles from "./ImageComponent.module.scss";
import Tooltip from "../Tooltip";
import { IMAGE_ERROR } from "../../utils/constants";

type ImageComponentProps = {
  image: string | null;
  newImage: ImageListType;
  setNewImage: (value: React.SetStateAction<ImageListType>) => void;
  setImage: (value: React.SetStateAction<string | null>) => void;
  setError: (value: React.SetStateAction<string>) => void;
  errText?: string;
  noImageIcon: ReactNode;
  newImageBtnTitle?: string;
  maxNumber?: number;
  maxFileSize?: number;
  acceptType?: string[];
  resolutionType?: "less" | "more" | "absolute" | "ratio";
  resolutionWidth?: number;
  resolutionHeight?: number;
  allowNonImageType?: boolean;
  orientation?: "vertical" | "horizontal";
  alt?: string;
  requirementsText: string | string[];
  classNameImgWrapper?: string;
  classNameContainer?: string;
};

const ImageComponent: FC<ImageComponentProps> = ({
  image,
  newImage,
  setNewImage,
  setImage,
  setError,
  errText,
  noImageIcon,
  newImageBtnTitle,
  maxNumber,
  maxFileSize,
  acceptType,
  resolutionType,
  resolutionWidth,
  resolutionHeight,
  allowNonImageType,
  orientation,
  alt,
  requirementsText,
  classNameImgWrapper,
  classNameContainer,
}) => {
  const isImage = newImage.length > 0 || image;

  const imgRef = useRef<HTMLImageElement>(null);

  const onError = (errors: ErrorsType, files?: ImageListType) => {
    if (files) {
      setNewImage([files[0]]);
    }

    if (errors?.resolution) {
      setError("Длина или высота изображения превышает допустимую. См. рекомендации");
    } else if (errors?.maxFileSize) {
      setError("Размер изображения превышает допустимый. См. рекомендации");
    } else if (errors?.acceptType) {
      setError("Недопустимый формат изображения. См. рекомендации");
    }
  };

  useEffect(() => {
    if (imgRef.current && orientation) {
      const img = new Image();
      img.src = imgRef.current.src;

      const setOrientationErrorHandler = () => {
        switch (orientation) {
          case "vertical":
            img.width > img.height &&
              setError("Недопустимая ореинтация изображения. См. рекомендации");
            break;
          case "horizontal":
            img.width < img.height &&
              setError("Недопустимая ореинтация изображения. См. рекомендации");
            break;
          default:
            break;
        }
      };

      img.addEventListener("load", setOrientationErrorHandler);

      return () => img.removeEventListener("load", setOrientationErrorHandler);
    }
  }, [newImage, orientation]);

  const onChangeImage = (value: ImageListType) => {
    setError("");
    setNewImage(value);
  };

  return (
    <div className={classNameContainer}>
      <ImageUploading
        multiple={false}
        value={newImage}
        onChange={onChangeImage}
        dataURLKey="data_url"
        maxNumber={maxNumber}
        resolutionWidth={resolutionWidth}
        resolutionHeight={resolutionHeight}
        resolutionType={resolutionType}
        maxFileSize={maxFileSize}
        acceptType={acceptType}
        allowNonImageType={allowNonImageType}
        onError={onError}
      >
        {({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps }) => {
          return (
            <>
              <div>
                <div
                  className={classNames(styles.imgWrapper, classNameImgWrapper, {
                    [styles.transparentImgWrapper]: image || newImage.length > 0,
                  })}
                >
                  <div
                    className={classNames(styles.dragOverlay, {
                      [styles.isDragging]: isDragging,
                    })}
                    {...dragProps}
                  >
                    Загрузить изображение
                  </div>
                  {imageList.length > 0 ? (
                    <img ref={imgRef} src={imageList[0]["data_url"]} alt={alt} />
                  ) : image ? (
                    <img src={image} alt={alt} />
                  ) : (
                    noImageIcon
                  )}
                </div>
                <div className={styles.btnsImagesListWrapper}>
                  <div className={styles.btnsWithTooltipWrapper}>
                    <Button
                      title={isImage ? "Изменить" : newImageBtnTitle || "Добавить фото"}
                      type={ButtonType.LINK}
                      onClick={newImage.length > 0 ? () => onImageUpdate(0) : onImageUpload}
                    />
                    <Tooltip requirementsText={requirementsText} error={!!errText} />
                  </div>
                  {newImage.length > 0 && (
                    <Button
                      title="Удалить"
                      type={ButtonType.LINK}
                      onClick={newImage.length > 0 ? () => onImageRemove(0) : () => setImage(null)}
                      className={styles.removeImageBtn}
                    />
                  )}
                </div>
              </div>
              {errText && <p className={styles.errorText}>{errText}</p>}
            </>
          );
        }}
      </ImageUploading>
    </div>
  );
};

export default ImageComponent;
