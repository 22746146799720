import React from "react";

export const FridgeIcon = ({ width = "24", height = "24", fill = "#0F2664" }) => {
  return (
    <div className="feature">
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.8532 0H8.14653C6.75398 0 5.62109 1.13293 5.62109 2.52549V21.4745C5.62109 22.8671 6.75403 24 8.14653 24H15.8531C17.2457 24 18.3786 22.8671 18.3786 21.4745V2.52549C18.3786 1.13293 17.2457 0 15.8532 0ZM17.6129 21.4745C17.613 22.4449 16.8235 23.2344 15.8532 23.2344H8.14653C7.17615 23.2344 6.38668 22.4449 6.38668 21.4745V9.75956H17.6129V21.4745ZM17.613 8.99397H6.38673V2.52549C6.38673 1.55511 7.1762 0.765634 8.14653 0.765634H15.8531C16.8235 0.765634 17.613 1.55511 17.613 2.52549V8.99397Z"
          fill={fill}
        />
        <path d="M8.26075 10.875H7.49512V13.4982H8.26075V10.875Z" fill={fill} />
        <path d="M8.26075 5.25391H7.49512V7.87714H8.26075V5.25391Z" fill={fill} />
      </svg>
      <div>Холодильник</div>
    </div>
  );
};
