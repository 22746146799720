import React from "react";
import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  CountryList,
  CreateObjectPayloadData,
  GetMainObjectsPayload,
  MainObject,
  MainObjectList,
  ObjectTypesData,
  ObjectTypesPayload,
  UpdateObjectPayload,
} from "../types/mainObjectTypes";
import { PayloadWithCallback } from "../types/@types";

type MainObjectState = {
  mainObjectTypes: ObjectTypesData;
  mainObjectList: MainObjectList | null;
  countriesList: CountryList;
  currentMainObject: MainObject | null;
};

const initialState: MainObjectState = {
  mainObjectTypes: {
    fullData: [],
    optionsList: [],
  },
  mainObjectList: null,
  countriesList: [
    { countryCode: "by", countryLabel: "Беларусь" },
    { countryCode: "ru", countryLabel: "Россия" },
    { countryCode: "ua", countryLabel: "Украина" },
    { countryCode: "kz", countryLabel: "Казахстан" },
    { countryCode: "am", countryLabel: "Армения" },
    { countryCode: "az", countryLabel: "Азербайджан" },
    { countryCode: "ge", countryLabel: "Грузия" },
    { countryCode: "kg", countryLabel: "Кыргызстан" },
    { countryCode: "md", countryLabel: "Молдова" },
    { countryCode: "tj", countryLabel: "Таджикистан" },
    { countryCode: "uz", countryLabel: "Узбекистан" },
    { countryCode: "ee", countryLabel: "Эстония" },
    { countryCode: "lv", countryLabel: "Латвия" },
    { countryCode: "lt", countryLabel: "Литва" },
    { countryCode: "pl", countryLabel: "Польша" },
  ],
  currentMainObject: null,
};

const MainObjectSlice = createSlice({
  name: "mainObject",
  initialState,
  reducers: {
    createMainObject(_, __: PayloadAction<CreateObjectPayloadData>) {},
    addMainObjectInList(state, action: PayloadAction<MainObject>) {
      if (state.mainObjectList) {
        state.mainObjectList = [...state.mainObjectList, action.payload];
      }
    },
    getMainObjectTypes(_, __: PayloadAction<ObjectTypesPayload>) {},
    setMainObjectTypes(state, action: PayloadAction<ObjectTypesData>) {
      state.mainObjectTypes = action.payload;
    },
    getMainObjectList(_, __: PayloadAction<GetMainObjectsPayload>) {}, // GetMainObjectsPayloadData
    setMainObjectList(state, action: PayloadAction<MainObjectList>) {
      state.mainObjectList = action.payload;
    },
    getMainObject(_, __: PayloadAction<PayloadWithCallback<string>>) {},
    getMainObjectTabsData(_, __: PayloadAction<PayloadWithCallback<string>>) {},
    setCurrentObject(state, action: PayloadAction<MainObject | null>) {
      state.currentMainObject = action.payload;
    },
    removeMainObject(_, __: PayloadAction<string>) {},
    setIsLoadingMainObjectCard(state, action: PayloadAction<{ id: string; value: boolean }>) {
      const { id, value } = action.payload;

      if (state.mainObjectList) {
        state.mainObjectList = state.mainObjectList.map((object) => {
          return object.id === id ? { ...object, isLoading: value } : object;
        });
      }
    },
    removeMainObjectFromList(state, action: PayloadAction<string>) {
      if (state.mainObjectList) {
        state.mainObjectList = state.mainObjectList.filter(
          (object) => object.id !== action.payload
        );
      }
    },
    updateMainObject(_, __: PayloadAction<UpdateObjectPayload>) {},
    updateMainObjectInList(state, action: PayloadAction<MainObject>) {
      if (state.mainObjectList) {
        state.mainObjectList = state.mainObjectList.map((object) => {
          return object.id === action.payload.id ? action.payload : object;
        });
      }
    },
  },
});

export const {
  createMainObject,
  addMainObjectInList,
  getMainObjectTypes,
  setMainObjectTypes,
  getMainObjectList,
  setMainObjectList,
  getMainObject,
  setCurrentObject,
  updateMainObject,
  removeMainObject,
  getMainObjectTabsData,
  setIsLoadingMainObjectCard,
  removeMainObjectFromList,
  updateMainObjectInList,
} = MainObjectSlice.actions;

export default MainObjectSlice.reducer;

export const mainObjectName = MainObjectSlice.name;

const getCountriesList = (state: RootState) => state.mainObject.countriesList;

const getSortedCountriesList = createSelector([getCountriesList], (list) => {
  const listCopy = [...list];
  return listCopy.sort((a, b: any) => a.countryLabel.localeCompare(b.countryLabel));
});

const getCountriesOptions = createSelector([getCountriesList], (list) => {
  return list.map((country) => ({ value: country.countryCode, label: country.countryLabel }));
});

export const MainObjectSelectors = {
  getMainObjectTypesData: (state: RootState) => state.mainObject.mainObjectTypes,
  getMainObjectList: (state: RootState) => state.mainObject.mainObjectList,
  getCurrentObject: (state: RootState) => state.mainObject.currentMainObject,
  getCountriesList,
  getSortedCountriesList,
  getCountriesOptions,
};
