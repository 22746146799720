

import classNames from "classnames";
import { CURRENCY } from "../../../utils/constants";
import styles from "./ColorBannerPaid.module.scss";
import moment from "moment";

type BannerProps = {
  total:string,
  paid:string,
  status:string
  check_out_date:string  
}

const ColorBannerPaid = ({
  total, 
  paid, 
  status,
  check_out_date
}:BannerProps) => {

  const today = new Date()
  const bookingClassname = (moment(check_out_date).startOf('day').unix()<moment(new Date()).startOf('day').unix()) ? "last"
  : status==="awt"? "await"
  : status==="new"? "new"
  : status==="que"? "que"
  : status==="cld"? "last"
  : +total===+paid? "full_paid" 
  : +paid>0? "part_paid"
  : "not_paid";

  const rest = ((+total) - (+paid)).toFixed(2)
  const dispTotal = Number(+total).toFixed(2);

  const textTitle = status==="new"? "Новая бронь"
  : status==="que"? "В очереди"
  : status==="cld"? "Отменена"
  : status==="awt"? "Ожидает подтверждения"
  : "Подтверждена" 
  

  return(
    <div className={classNames(styles.container, styles[bookingClassname])}>
      <div className={styles.text}>{textTitle}</div>
      <div >
        <div ><span className={styles.money}>Всего:</span>{` ${dispTotal} ${CURRENCY} `}</div>
        <div><span className={styles.money}>Долг:</span>{`  ${rest} ${CURRENCY}`}</div>
      </div>
    </div>
  )

}

export default ColorBannerPaid;