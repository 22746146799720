import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./FeatureCard.module.scss";
import { ButtonType, LocalFeatureType, FieldTypes, InputType } from "../../utils/@globalTypes";
import SelectComponent from "../SelectComponent";
import Input from "../Input";
import Button from "../Button";
import { DeleteIcon } from "../../assets/icons";
import { FEATURES_OPTIONS, FEATURE_NAME_REQ } from "../../utils/constants";
import { setFieldErrorText } from "../../utils/functions";

type FeatureCardType = {
  setFeatureData: React.Dispatch<React.SetStateAction<LocalFeatureType[]>>;
  featureIndex: number;
  deleteHandler: (value: number) => () => void;
  isAllTouched: boolean;
  setIsAllTouched: React.Dispatch<React.SetStateAction<boolean>>;
  name: string;
  logo: string;
};

const FeatureCard: FC<FeatureCardType> = ({
  featureIndex,
  setFeatureData,
  deleteHandler,
  isAllTouched,
  setIsAllTouched,
  name,
  logo,
}) => {
  const [featureName, setFeatureName] = useState(name);
  const [featureLogo, setFeatureLogo] = useState("");

  const [featureNameError, setFeatureNameError] = useState("");

  const [featureNameTouched, setFeatureNameTouched] = useState(false);

  const firstUpdate = useRef(false);

  useEffect(() => {
    if (firstUpdate.current) {
      setFeatureData((prevState) => {
        return prevState.map((item, index) =>
          index === featureIndex
            ? {
                ...item,
                isValid: !featureNameError,
                feature: { ...item.feature, name: featureName, logo: featureLogo },
              }
            : item
        );
      });
    } else {
      firstUpdate.current = true;
    }
  }, [featureName, featureLogo, featureIndex, setFeatureData, featureNameError]);

  useEffect(() => {
    setFeatureLogo(logo || "wifi");
  }, [logo]);

  useEffect(() => {
    if (featureLogo === "star" || featureLogo === "diamond") {
      setFieldErrorText(
        featureNameTouched,
        featureName,
        setFeatureNameError,
        FieldTypes.FEATURE_NAME,
        true
      );
    }
  }, [featureNameTouched, featureName, featureLogo]);

  useEffect(() => {
    if (isAllTouched) {
      setFeatureNameTouched(true);
      setIsAllTouched(false);
    }
  }, [isAllTouched]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.iconWrapper}>
        <SelectComponent
          title="Иконка*"
          placeholder="—"
          optionsList={FEATURES_OPTIONS}
          currentValue={featureLogo}
          setSelecValue={setFeatureLogo}
          maxMenuHeight={200}
        />
      </div>
      {(featureLogo === "star" || featureLogo === "diamond") && (
        <Input
          value={featureName}
          title="Наименование удобства*"
          placeholder="Введите наименование"
          type={InputType.TEXT}
          onChange={setFeatureName}
          errText={featureNameError}
          onBlur={setFeatureNameTouched}
          requirementsText={FEATURE_NAME_REQ}
          containerClassName={styles.input}
        />
      )}
      <div className={styles.btnWrapper}>
        <Button
          title={<DeleteIcon />}
          type={ButtonType.SMALL}
          onClick={deleteHandler(featureIndex)}
        />
      </div>
    </div>
  );
};

export default FeatureCard;
