import React from "react";

export const CloseSquareIcon = ({ width = "17", height = "17", fill = "#B91212" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3334 6.16681L5.66675 11.8335"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3334 11.8335L5.66675 6.16681"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.57 2.44806H5.42946C3.28958 2.44806 1.948 3.96318 1.948 6.10731V11.893C1.948 14.0371 3.28321 15.5522 5.42946 15.5522H11.5693C13.7162 15.5522 15.0522 14.0371 15.0522 11.893V6.10731C15.0522 3.96318 13.7162 2.44806 11.57 2.44806Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
