import React, { useEffect, useMemo, useState } from "react";
import styles from "./SignIn.module.scss";
import FormPage from "../FormPage";
import Input from "../../../components/Input";
import {
  ButtonType,
  FieldTypes,
  InputModeType,
  InputType,
  LoaderTypes,
} from "../../../utils/@globalTypes";
import Button from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  AuthSelectors,
  setErrorResponeDetailsData,
  signInUser,
} from "../../../redux/reducers/authSlice";
import { getErrorText, setTimer } from "../../../utils/functions";
import { PageSelectors } from "../../../redux/reducers/pageSlice";
import { RoutesList } from "../../../App";
import { CREDENTIALS_ERROR } from "../../../utils/constants";

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const errorResponseSignInData = useSelector(AuthSelectors.getErrorResponseDetailsData);
  const isLoading = useSelector(PageSelectors.getLoadersData(LoaderTypes.SIGN_IN_PAGE));

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [passwordError, setPasswordError] = useState("");

  const [blockTime, setBlockTime] = useState("");
  const [isStartBlockTimer, setIsStartBlockTimer] = useState(false);

  // const onForgotPassClick = () => {
  //   navigate(RoutesList.EmailInput);
  // };

  const onSignInBtnClick = () => {
    dispatch(
      signInUser({
        data: {
          email,
          password,
        },
        callback(length, blockTime) {
          if (length && blockTime) {
            setTimer(length, setIsStartBlockTimer);
            setBlockTime(blockTime);
          } else {
            navigate(RoutesList.checkerboard.fullPath);
          }
        },
      })
    );
  };

  // const handleKeyDown = (event: KeyboardEvent) => {
  //   if (event.key === "Enter" && isValid) {
  //     onSignInBtnClick();
  //   }
  // };

  useEffect(() => {
    if (errorResponseSignInData) {
      setPasswordError(
        getErrorText(errorResponseSignInData, CREDENTIALS_ERROR, FieldTypes.DEFAULT)
      );
      dispatch(setErrorResponeDetailsData(""));
    }
  }, [errorResponseSignInData]);

  useEffect(() => {
    setPasswordError("");
  }, [password, email]);

  const isValid = useMemo(() => {
    return (
      passwordError.length === 0 && email.length > 0 && password.length > 0 && !isStartBlockTimer
    );
  }, [passwordError, email, password, isStartBlockTimer]);

  // useEffect(() => {
  //   if (isValid) {
  //     document.addEventListener("keydown", handleKeyDown);
  //     return () => {
  //       document.removeEventListener("keydown", handleKeyDown);
  //     };
  //   }
  // }, [isValid, email, password]);

  return (
    <FormPage
      pageTitle="Войти в систему"
      description="Пожалуйста, введите свой логин и пароль:"
      isLoading={isLoading}
    >
      <div className={styles.inputsWrapper}>
        <Input
          title="Электронная почта*"
          value={email}
          placeholder="Введите электронную почту"
          type={InputType.TEXT}
          inputMode={InputModeType.EMAIL}
          onChange={setEmail}
          banSymbols={/\s/g}
        />
        <Input
          title="Пароль*"
          value={password}
          placeholder="Введите пароль"
          type={InputType.PASSWORD}
          onChange={setPassword}
          errText={passwordError}
        />
      </div>
      <div className={styles.btnsWrapper}>
        <Button
          title="Войти"
          type={ButtonType.PRIMARY}
          onClick={onSignInBtnClick}
          promptText={
            isStartBlockTimer
              ? `Количество допустимых попыток авторизации превышено. Попробуйте снова через ${blockTime}`
              : undefined
          }
          // onKeyDown={{ key: "Enter", func: onSignInBtnClick }}
          disabled={!isValid}
        />
        <span>или</span>
        <Button title="Регистрация" type={ButtonType.SECONDARY} path={RoutesList.signUp.fullPath} />
        {/* <Button title="Забыли пароль?" type={ButtonType.LINK} onClick={() => {}} /> */}
      </div>
    </FormPage>
  );
};

export default SignIn;
