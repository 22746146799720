import React from "react";

export const StarIcon = ({ width = "24", height = "24", fill = "#0F2664" }) => {
  return (
    <div className="feature">
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.63622 8.19482C4.42222 8.69612 3.31521 8.94632 3.05151 9.79322C2.78871 10.6392 3.54291 11.5221 5.05222 13.287L5.44282 13.7433C5.87122 14.2446 6.08632 14.4957 6.18262 14.8053C6.27892 15.1158 6.24652 15.4506 6.18172 16.1193L6.12232 16.7286C5.89462 19.0839 5.78032 20.2612 6.46972 20.7841C7.15912 21.3079 8.19593 20.83 10.2677 19.876L10.805 19.6293C11.3936 19.3575 11.6879 19.2225 12.0002 19.2225C12.3125 19.2225 12.6068 19.3575 13.1963 19.6293L13.7318 19.876C15.8045 20.83 16.8414 21.307 17.5299 20.785C18.2202 20.2612 18.1059 19.0839 17.8782 16.7286M18.9483 13.287C20.4576 11.523 21.2118 10.6401 20.949 9.79322C20.6853 8.94632 19.5783 8.69522 17.3643 8.19482L16.7919 8.06522C16.1628 7.92301 15.8486 7.85191 15.5957 7.66021C15.3437 7.46851 15.1817 7.17781 14.8577 6.59641L14.5625 6.06721C13.4222 4.0224 12.8525 3 12.0002 3C11.1479 3 10.5782 4.0224 9.43793 6.06721"
          stroke={fill}
          strokeWidth="0.900003"
          strokeLinecap="round"
        />
      </svg>
      <div>Свой вариант</div>
    </div>
  );
};
