import React from "react";

export const CheckmarkIcon = ({ width = "12", height = "10", fill = "#ffffff" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.619 0.638504C12.0258 1.0453 12.0258 1.70485 11.619 2.11164L5.15324 8.88247C4.74644 9.28927 4.08689 9.28927 3.6801 8.88247L0.555097 5.75748C0.148301 5.35068 0.148301 4.69114 0.555097 4.28434C0.961893 3.87754 1.62144 3.87754 2.02824 4.28434L4.41667 6.67277L10.1458 0.638504C10.5526 0.231708 11.2122 0.231708 11.619 0.638504Z"
        fill={fill}
      />
    </svg>
  );
};
