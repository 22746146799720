import React from "react";

export const TVIcon = ({ width = "24", height = "24", fill = "#0F2664" }) => {
  return (
    <div className="feature">
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_5382_44228)">
          <path
            d="M18.9351 3.70703H5.06411C3.74669 3.70703 2.67871 4.81874 2.67871 6.19009V17.8101C2.67871 19.1815 3.74669 20.2932 5.06411 20.2932H18.9351C20.2525 20.2932 21.3205 19.1815 21.3205 17.8101V6.19009C21.3205 4.81874 20.2525 3.70703 18.9351 3.70703Z"
            stroke={fill}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.0468 7.84599C12.9932 7.67426 12.8178 7.57966 12.655 7.63471C12.4923 7.68975 12.4038 7.87359 12.4574 8.04532L15.1961 16.8191C15.2497 16.9909 15.4251 17.0855 15.5878 17.0304C15.7506 16.9754 15.839 16.7915 15.7854 16.6198L13.0468 7.84599Z"
            fill={fill}
            stroke={fill}
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M18.5236 8.0474C18.5772 7.87566 18.4887 7.69182 18.3259 7.63678C18.1632 7.58173 17.9878 7.67633 17.9342 7.84806L15.1955 16.6219C15.1419 16.7936 15.2304 16.9774 15.3932 17.0325C15.5559 17.0875 15.7313 16.9929 15.7849 16.8212L18.5236 8.0474Z"
            fill={fill}
            stroke={fill}
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M8.49372 7.89531C8.49372 7.71474 8.35499 7.56836 8.18387 7.56836C8.01275 7.56836 7.87402 7.71474 7.87402 7.89531V16.723C7.87402 16.9036 8.01275 17.0499 8.18387 17.0499C8.35499 17.0499 8.49372 16.9036 8.49372 16.723V7.89531Z"
            fill={fill}
            stroke={fill}
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M5.5221 7.49751C5.34863 7.49751 5.20801 7.64192 5.20801 7.82005C5.20801 7.99818 5.34863 8.14258 5.5221 8.14258H10.8362C11.0097 8.14258 11.1503 7.99818 11.1503 7.82005C11.1503 7.64192 11.0097 7.49751 10.8362 7.49751H5.5221Z"
            fill={fill}
            stroke={fill}
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
        </g>
        <defs>
          <clipPath id="clip0_5382_44228">
            <rect width="20" height="18" fill="white" transform="translate(2 3)" />
          </clipPath>
        </defs>
      </svg>
      <div>Телевизор</div>
    </div>
  );
};
