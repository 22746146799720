import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  InputType,
  InputModeType,
  FieldTypes,
  ButtonType,
  TelephoneType,
} from "../../../utils/@globalTypes";
import Input from "../../../components/Input";
import { ProfileIcon } from "../../../assets/icons";
import { getErrorText, setFieldErrorText } from "../../../utils/functions";
import {
  FIRST_NAME_REQ,
  LAST_NAME_REQ,
  AVATAR_REQ,
  URL_REQ,
  IMAGE_ERROR,
  FIRST_NAME_ERROR,
  LAST_NAME_ERROR,
  URL_ERROR,
  TELEPHONE_ERROR,
} from "../../../utils/constants";
import { ImageListType } from "react-images-uploading";

import styles from "./OwnerSettings.module.scss";
import IntlTelInput from "../../../components/IntlTelInput";
import Button from "../../../components/Button";
import { UserSelectors, updateOwnerInfo } from "../../../redux/reducers/userSlice";
import { RoutesList } from "../../../App";
import ImageComponent from "../../../components/ImageComponent";
import { AuthSelectors, setErrorResponeData } from "../../../redux/reducers/authSlice";

const OwnerSettings = () => {
  const owner = useSelector(UserSelectors.getOwnerInfo);
  const errorResponseData = useSelector(AuthSelectors.getErrorResponseData);

  const [firstName, setFirstName] = useState("");
  const [surName, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");

  const [firstNameError, setFirstNameError] = useState("");
  const [surnameError, setSurnameError] = useState("");
  const [telephoneError, setTelephoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [websiteError, setWebsiteError] = useState("");

  const [firstNameTouched, setFirstNameTouched] = useState(false);
  const [surnameTouched, setSurnameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [telephoneTouched, setTelephoneTouched] = useState(false);
  const [siteTouched, setSiteTouched] = useState(false);

  const [telephone, setTelephone] = useState<TelephoneType>({
    country_code: "",
    phone_code: "",
    phone_number: "",
  });

  const [isTelephoneValid, setIsTelephoneValid] = useState(false);

  const [image, setImage] = useState<string | null>("");
  const [newImage, setNewImage] = useState<ImageListType>([]);
  const [newImageError, setNewImageError] = useState("");

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (owner) {
      const {
        first_name,
        last_name,
        image: imageRes,
        user: { email: emailRes },
        website: websiteRes,
      } = owner;

      setFirstName(first_name);
      setSurname(last_name);
      setImage(imageRes);
      setNewImage([]);
      setEmail(emailRes);
      websiteRes && setWebsite(websiteRes);
    }
  }, [owner]);

  useEffect(() => {
    if (errorResponseData) {
      errorResponseData.photo &&
        setNewImageError(
          getErrorText(errorResponseData.photo[0], IMAGE_ERROR, FieldTypes.PROFILE_PHOTO)
        );

      errorResponseData.first_name &&
        setFirstNameError(
          getErrorText(errorResponseData.first_name[0], FIRST_NAME_ERROR, FieldTypes.FIRST_NAME)
        );

      errorResponseData.last_name &&
        setSurnameError(
          getErrorText(errorResponseData.last_name[0], LAST_NAME_ERROR, FieldTypes.LAST_NAME)
        );

      errorResponseData.website &&
        setWebsiteError(getErrorText(errorResponseData.website[0], URL_ERROR, FieldTypes.URL));

      errorResponseData.phone_number &&
        setTelephoneError(
          getErrorText(errorResponseData.phone_number[0], TELEPHONE_ERROR, FieldTypes.TELEPHONE)
        );

      dispatch(setErrorResponeData(null));
    }
  }, [errorResponseData]);

  // Name

  useEffect(() => {
    setFieldErrorText(firstNameTouched, firstName, setFirstNameError, FieldTypes.FIRST_NAME, true);
  }, [firstNameTouched, firstName]);

  // Surname

  useEffect(() => {
    setFieldErrorText(surnameTouched, surName, setSurnameError, FieldTypes.LAST_NAME, true);
  }, [surnameTouched, surName]);

  // Email

  useEffect(() => {
    setFieldErrorText(emailTouched, email, setEmailError, FieldTypes.EMAIL, true);
  }, [emailTouched, email]);

  // Telephone

  useEffect(() => {
    setFieldErrorText(
      telephoneTouched,
      telephone.phone_number,
      setTelephoneError,
      FieldTypes.TELEPHONE,
      true,
      isTelephoneValid
    );
  }, [telephoneTouched, telephone.phone_number, isTelephoneValid]);

  // Website

  useEffect(() => {
    setFieldErrorText(siteTouched, website, setWebsiteError, FieldTypes.URL, false);
  }, [siteTouched, website]);

  const onConfirmBtnClick = () => {
    setFirstNameTouched(true);
    setSurnameTouched(true);
    setTelephoneTouched(true);
    setEmailTouched(true);
    setSiteTouched(true);

    if (
      !firstNameError &&
      !surnameError &&
      !telephoneError &&
      !emailError &&
      !websiteError &&
      firstName.length > 0 &&
      surName.length > 0 &&
      email.length > 0 &&
      owner
    ) {
      const formdata = new FormData();

      const { first_name, last_name, website: websiteRes, phone } = owner;

      newImage.length > 0 && formdata.append("image", newImage[0].file as Blob);
      first_name !== firstName && formdata.append("first_name", firstName);
      last_name !== surName && formdata.append("last_name", surName);
      websiteRes !== website && formdata.append("website", website);

      if (
        (phone &&
          (phone.country_code !== telephone.country_code ||
            phone.phone_code !== telephone.phone_code ||
            phone.phone_number !== telephone.phone_number)) ||
        (!phone && telephone.phone_number.length > 0)
      ) {
        formdata.append(
          "phone",
          telephone.phone_number.length > 0 ? JSON.stringify(telephone) : "null"
        );
      }

      dispatch(updateOwnerInfo({ id: owner.id, data: formdata }));
    }
  };

  const isValid = useMemo(() => {
    return (
      !newImageError &&
      !firstNameError &&
      !surnameError &&
      !emailError &&
      !websiteError &&
      !telephoneError
    );
  }, [newImageError, firstNameError, surnameError, emailError, websiteError, telephoneError]);

  const isFieldsChanged = useMemo(() => {
    if (!!owner) {
      const {
        first_name,
        last_name,
        user: { email: emailRes },
        website: websiteRes,
        phone,
      } = owner;
      return (
        first_name !== firstName ||
        last_name !== surName ||
        emailRes !== email ||
        websiteRes !== website ||
        newImage.length > 0 ||
        (phone &&
          (phone.country_code !== telephone.country_code ||
            phone.phone_code !== telephone.phone_code ||
            phone.phone_number !== telephone.phone_number)) ||
        (!phone && telephone.phone_number.length > 0)
      );
    }
  }, [firstName, surName, email, website, telephone, newImage, owner]);

  return (
    <>
      <div className={styles.form}>
        <div className={styles.imageContainer}>
          <h3 className={styles.title}>Фото</h3>
          <ImageComponent
            newImage={newImage}
            image={image}
            setNewImage={setNewImage}
            setImage={setImage}
            setError={setNewImageError}
            errText={newImageError}
            noImageIcon={<ProfileIcon width="40" height="40" />}
            maxFileSize={1000000}
            acceptType={["jpg", "jpeg", "png"]}
            requirementsText={AVATAR_REQ}
            classNameImgWrapper={styles.imgWrapper}
          />
        </div>
        <div className={styles.leftColumn}>
          <h3 className={styles.title}>Личные данные</h3>
          <div className={styles.column}>
            <Input
              id="firstName"
              value={firstName}
              title="Имя*"
              name="firstName"
              placeholder="Введите имя"
              type={InputType.TEXT}
              inputMode={InputModeType.TEXT}
              errText={firstNameError}
              onChange={setFirstName}
              autoComplete="given-name"
              onBlur={setFirstNameTouched}
              requirementsText={FIRST_NAME_REQ}
              banSymbols={/\s/g}
            />
            <Input
              id="lastName"
              value={surName}
              title="Фамилия*"
              name="lastName"
              placeholder="Введите фамилию"
              type={InputType.TEXT}
              inputMode={InputModeType.TEXT}
              errText={surnameError}
              onChange={setSurname}
              onBlur={setSurnameTouched}
              requirementsText={LAST_NAME_REQ}
              banSymbols={/\s/g}
            />
            <Input
              id="email"
              value={email}
              title="Электронная почта*"
              name="email"
              placeholder="Введите электронную почту"
              type={InputType.EMAIL}
              inputMode={InputModeType.EMAIL}
              errText={emailError}
              onChange={setEmail}
              onBlur={setEmailTouched}
              banSymbols={/\s/g}
              disabled={true}
            />
            <Input
              id="site"
              value={website}
              title="Адрес сайта"
              name="webSite"
              placeholder="https://example.com"
              type={InputType.TEXT}
              inputMode={InputModeType.URL}
              errText={websiteError}
              onBlur={setSiteTouched}
              onChange={setWebsite}
              requirementsText={URL_REQ}
              banSymbols={/\s/g}
            />
            <IntlTelInput
              id="MobilePhone"
              title="Мобильный телефон"
              value={telephone}
              placeholder="Введите номер телефона"
              onChange={setTelephone}
              errText={telephoneError}
              setError={setTelephoneError}
              onBlur={setTelephoneTouched}
              setIsValidate={setIsTelephoneValid}
              preloadedData={owner?.phone}
            />
          </div>
        </div>
      </div>
      <div className={styles.btnFormWrap}>
        <Button
          title="Сохранить"
          type={ButtonType.PRIMARY_SMALL}
          onClick={onConfirmBtnClick}
          className={styles.btnConfirm}
          disabled={!isValid || !isFieldsChanged}
        />
        <Button
          title="Отмена"
          type={ButtonType.SECONDARY_SMALL}
          path={RoutesList.checkerboard.fullPath}
          className={styles.btnCancel}
        />
      </div>
    </>
  );
};

export default OwnerSettings;
