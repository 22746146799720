import React from "react";

export const ArrowDouble = ({ width = "30", height = "30" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23 6.75L14.25 15.5L23 24.25" stroke="#0A3A86" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.625 6.75L7.875 15.5L16.625 24.25" stroke="#0A3A86" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
};
