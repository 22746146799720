import { all, put, takeLatest, takeEvery } from "redux-saga/effects";
import {
  createBooking,
  getBookingList,
  getChessObjects,
  removeBooking,
  removeBookingFromList,
  setBookingList,
  setChessObjectsList,
  updateCurrentBooking
} from "./../reducers/bookingSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiResponse } from "apisauce";
import API from "../api";
import {   
  BookingType,  
  ChessObjectsResponse,
  UpdateBookingType,
  UpdateBookingPayloadData
} from "../types/bookingTypes";
import callCheckingAuth from "./callCheckingAuth";
import { setLoadersData, setMessage, setModalWindowData } from "../reducers/pageSlice";
import { LoaderTypes, MessageTypes, ModalWindowTypes } from "../../utils/@globalTypes";
import { ErrorResponseData } from "../types/@types";

function* getChessObjectListWorker() {
  const { ok, data, status }: ApiResponse<ChessObjectsResponse> = yield callCheckingAuth(
    API.getCheckerboardList,
    
  );

  if (data && ok) {
    
    yield put(setChessObjectsList(data.results));
  } else {
    if (status) {
      yield put(
        setMessage({
          status: MessageTypes.ERROR,
          message: "Ошибка получения данных об объектах",
          code: status,
        })
      );
    }
  }
}
function* getBookingListWorker() {
  yield put(setLoadersData({ type: LoaderTypes.BOOKING_PAGE, value: true }));
  const { ok, data, status }: ApiResponse<BookingType[]> = yield callCheckingAuth(
    API.getBookingList,
    // action.payload
  );

  if (data && ok) {
    
    yield put(setBookingList(data));
  } else {
    if (status) {
      yield put(
        setMessage({
          status: MessageTypes.ERROR,
          message: "Ошибка получения списка броней",
          code: status,
        })
      );
    }
  }
  yield put(setLoadersData({ type: LoaderTypes.BOOKING_PAGE, value: false }));
}


function* createBookingWorker(action: PayloadAction<UpdateBookingType>) {
  console.log("работает сага create")
  yield put(setLoadersData({ type: LoaderTypes.BOOKING_PAGE, value: true }));
   
    const {
      ok,
      data: responseData,
      status      
    }: ApiResponse<BookingType, ErrorResponseData> = yield callCheckingAuth(
      API.createBookingRequest,
      action.payload
    );
    
   if (ok && status ) {
    yield put(setModalWindowData({ type: ModalWindowTypes.CLOSE }));
    const { ok:listOk, data:listResponse, status:listStatus }: ApiResponse<BookingType[]> = yield callCheckingAuth(
      API.getBookingList    
    );
  
    if (listResponse && listOk) {      
      yield put(setBookingList(listResponse));
    } 
    yield put(
      setMessage({
        status: MessageTypes.POSITIVE,
        message: "Бронь сохранена",
        code: status,
      })
    );
   
  } else {
    console.log(responseData)
    if (status) {
      if (responseData && !ok && status === 400) {
        
        const text ="Объект недоступен в указанные даты"
        yield put(setMessage({
          status: MessageTypes.WARNING,
          message: text,
          code: 401,
        }));
      } else {
        yield put(
          setMessage({
            status: MessageTypes.ERROR,
            message: "Ошибка сохранения",
            code: status,
          })
        );
      }
    }
    
}  
    yield put(setLoadersData({ type: LoaderTypes.BOOKING_PAGE, value: false })); 
    return status
}

function* removeBookingWorker(action: PayloadAction<number>) {
  yield put(setLoadersData({ type: LoaderTypes.BOOKING_PAGE, value: true }));
    const { ok, status }: ApiResponse<undefined> = yield callCheckingAuth(
    API.removeBookingRequest,
    action.payload
  );

  if (status&&ok) {
    yield put(removeBookingFromList(action.payload));    
    yield put(
      setMessage({
        status: MessageTypes.POSITIVE,
        message: "Бронь удалена",
        code: 200,
      })
    );
    yield put(setModalWindowData({ type: ModalWindowTypes.CLOSE }))
    const { ok:listOk, data:listResponse, status:listStatus }: ApiResponse<BookingType[]> = yield callCheckingAuth(
      API.getBookingList    
    );
  
    if (listResponse && listOk) {
      
      yield put(setBookingList(listResponse));
    } 
  } else {
    
    if (status&& !ok) {
      yield put(
        setMessage({
          status: MessageTypes.ERROR,
          message: "Не удалось удалить бронь",
          code: status,
        })
      )
    } else {
      yield put(
        setMessage({
          status: MessageTypes.ERROR,
          message: "Ошибка удаления ",
          code: 500,
        })
      )
    }   
  }
  yield put(setLoadersData({ type: LoaderTypes.BOOKING_PAGE, value: false }));
}

function* updateBookingWorker(action: PayloadAction<UpdateBookingPayloadData>) {
  yield put(setLoadersData({ type: LoaderTypes.CREATE_RENTAL_OBJECT_POPUP, value: true }));
  const {id, data} = action.payload;

  const {
    ok,
    data: responseData,
    status,
  }: ApiResponse<BookingType, ErrorResponseData> = yield callCheckingAuth(
    API.updateBookingRequest,
    id,
    data
  );

  if (ok && status ) {
    yield put(setModalWindowData({ type: ModalWindowTypes.CLOSE }));
    yield put(
      setMessage({
        status: MessageTypes.POSITIVE,
        message: "Изменения сохранены",
        code: status,
      })
    );
    const { ok:listOk, data:listResponse, status:listStatus }: ApiResponse<BookingType[]> = yield callCheckingAuth(
      API.getBookingList    
    );
  
    if (listResponse && listOk) {
      
      yield put(setBookingList(listResponse));
    } else {
      if (listStatus) {
        yield put(
          setMessage({
            status: MessageTypes.ERROR,
            message: "Ошибка получения списка броней",
            code: listStatus,
          })
        );
      }
    }
  } else {
    
    if (status) {
      if (responseData && !ok && status === 400) {
        
        const text ="Объект недоступен в указанные даты"
        yield put(setMessage({
          status: MessageTypes.WARNING,
          message: text,
          code: 401,
        }));
      } else {
        yield put(
          setMessage({
            status: MessageTypes.ERROR,
            message: "Ошибка сохранения",
            code: status,
          })
        );
      }
    }
  }
  
  yield put(setLoadersData({ type: LoaderTypes.CREATE_RENTAL_OBJECT_POPUP, value: false }));
}

export default function* bookingSaga() {
  yield all([
    takeLatest(getChessObjects, getChessObjectListWorker),
    takeEvery(getBookingList, getBookingListWorker),
    takeLatest(createBooking, createBookingWorker),
    takeLatest(removeBooking, removeBookingWorker),
    takeLatest(updateCurrentBooking, updateBookingWorker),
    
  ]);
}


