import React, { useEffect, useMemo, useState } from "react";
import ModalWindow from "../ModalWindow";

import { useDispatch, useSelector } from "react-redux";
import { banDoubleSpace, getErrorText, setFieldErrorText } from "../../../utils/functions";
import {
  FieldTypes,
  InputType,
  LoaderTypes,
  ModalWindowTypes,
  TabsNames,
} from "../../../utils/@globalTypes";
import Input from "../../Input/Input";
import SelectComponent from "../../SelectComponent/SelectComponent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PageSelectors, setModalWindowData } from "../../../redux/reducers/pageSlice";
import { MainObjectSelectors, createMainObject } from "../../../redux/reducers/mainObjectSlice";
import { RoutesList } from "../../../App";
import { OBJECT_NAME_ERROR, OBJECT_NAME_REQ, REQUIRED_FIELD_ERROR } from "../../../utils/constants";
import { AuthSelectors, setErrorResponeData } from "../../../redux/reducers/authSlice";
import {
  RentalObjectSelectors,
  createRentalObject,
} from "../../../redux/reducers/rentalObjectSlice";

const CreatingRentalObjectWindow = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const errorResponseData = useSelector(AuthSelectors.getErrorResponseData);
  const objectTypes = useSelector(RentalObjectSelectors.getRentalObjectTypes);
  const isLoading = useSelector(
    PageSelectors.getLoadersData(LoaderTypes.CREATE_RENTAL_OBJECT_POPUP)
  );
  const currentObject = useSelector(MainObjectSelectors.getCurrentObject);

  const [objectName, setObjectName] = useState("");
  const [objectType, setObjectType] = useState("");

  const [objectNameError, setObjectNameError] = useState("");
  const [objectTypeError, setObjectTypeError] = useState("");

  const [objectNameTouched, setObjectNameTouched] = useState(false);
  const [objectTypeTouched, setObjectTypeTouched] = useState(false);

  const onSaveBtnClick = () => {
    setObjectNameTouched(true);
    setObjectTypeTouched(true);

    if (!objectNameError && !objectTypeError && currentObject) {
      dispatch(
        createRentalObject({
          data: {
            name: objectName.trim(),
            object_type: +objectType,
            main_object: currentObject.id,
          },
          callback: (rentalId: number) => {
            navigate(
              `${RoutesList.objectManagement.fullPath}/${currentObject.id}/${RoutesList.objectManagement.rentalObjectEditingChild.part}/${rentalId}/${TabsNames.RENTAL_BASIC_SETTINGS}`
            );
          },
        })
      );
    }
  };
  useEffect(() => {
    if (errorResponseData) {
      errorResponseData.name &&
        setObjectNameError(
          getErrorText(errorResponseData.name[0], OBJECT_NAME_ERROR, FieldTypes.OBJECT_NAME)
        );

      errorResponseData.object_type &&
        setObjectTypeError(
          getErrorText(
            errorResponseData.object_type[0],
            REQUIRED_FIELD_ERROR,
            FieldTypes.OBJECT_NAME
          )
        );

      dispatch(setErrorResponeData(null));
    }
  }, [errorResponseData]);

  // Object name

  useEffect(() => {
    setFieldErrorText(
      objectNameTouched,
      objectName,
      setObjectNameError,
      FieldTypes.OBJECT_NAME,
      true
    );
  }, [objectNameTouched, objectName]);

  // Object type

  useEffect(() => {
    setFieldErrorText(objectTypeTouched, objectType, setObjectTypeError, FieldTypes.DEFAULT, true);
  }, [objectTypeTouched, objectType]);

  const isValid = useMemo(() => {
    return !objectNameError && !objectTypeError && objectName.length > 0 && objectType.length > 0;
  }, [objectNameError, objectTypeError, objectName, objectType]);

  useEffect(() => {
    const tabName = location.pathname.split("/").at(-1);
    if (tabName !== TabsNames.OBJECT_EDITING) {
      dispatch(setModalWindowData({ type: ModalWindowTypes.CLOSE }));
    }
  }, [location]);

  return (
    <ModalWindow
      title="Объект"
      onSubmit={onSaveBtnClick}
      isValid={!isValid}
      isLoading={isLoading}
      hideCross
    >
      <Input
        value={objectName}
        title="Наименование сдаваемого объекта*"
        placeholder="Введите наименование объекта"
        type={InputType.TEXT}
        onChange={setObjectName}
        errText={objectNameError}
        onBlur={setObjectNameTouched}
        requirementsText={OBJECT_NAME_REQ}
        customBanSymbols={banDoubleSpace}
      />
      <SelectComponent
        title="Вид сдаваемого объекта*"
        optionsList={objectTypes}
        currentValue={objectType}
        setSelecValue={setObjectType}
        errText={objectTypeError}
        isLoading={objectTypes.length === 0}
        onBlur={setObjectTypeTouched}
      />
    </ModalWindow>
  );
};

export default CreatingRentalObjectWindow;
