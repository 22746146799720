import React from "react";

export const HomeIcon = ({ width = "24", height = "24", fill = "#8692A6" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.339 7.65929C21.009 8.17829 21.42 8.94929 21.49 9.78829L21.5 9.98929V18.0983C21.5 20.1883 19.849 21.8883 17.78 21.9983H15.79C14.839 21.9793 14.07 21.2393 14 20.3093L13.99 20.1683V17.3093C13.99 16.9983 13.759 16.7393 13.45 16.6883L13.36 16.6783H10.689C10.37 16.6883 10.11 16.9183 10.07 17.2183L10.06 17.3093V20.1593C10.06 20.2183 10.049 20.2883 10.04 20.3383L10.03 20.3593L10.019 20.4283C9.9 21.2793 9.2 21.9283 8.33 21.9893L8.2 21.9983H6.41C4.32 21.9983 2.61 20.3593 2.5 18.2983V9.98929C2.509 9.13829 2.88 8.34829 3.5 7.79829L9.73 2.78829C11 1.77929 12.78 1.73929 14.089 2.66829L14.25 2.78829L20.339 7.65929ZM20.1006 18.2583L20.1116 18.0983V9.99829C20.1006 9.56929 19.9216 9.16829 19.6116 8.86929L19.4816 8.75829L13.3816 3.87829C12.6216 3.26829 11.5416 3.23929 10.7416 3.76829L10.5906 3.87829L4.51062 8.77929C4.16163 9.03829 3.95162 9.42829 3.90163 9.83829L3.89062 9.99829V18.0983C3.89062 19.4283 4.93062 20.5183 6.25163 20.5983H8.20162C8.42162 20.5983 8.61163 20.4493 8.64062 20.2393L8.66162 20.0593L8.67163 20.0083V17.3093C8.67163 16.2393 9.49162 15.3693 10.5416 15.2883H13.3616C14.4306 15.2883 15.3006 16.1093 15.3816 17.1593V20.1683C15.3816 20.3783 15.5316 20.5593 15.7316 20.5983H17.5906C18.9306 20.5983 20.0206 19.5693 20.1006 18.2583Z"
        fill={fill}
      />
    </svg>
  );
};
