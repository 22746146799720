import React from "react";

export const FolderIcon = ({ width = "28", height = "25", fill = "#ffffff" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.1493 16.4145C24.1493 20.1417 21.7748 22.3381 17.7454 22.3381H8.97717C4.93743 22.3381 2.56293 20.1417 2.56293 16.4145V8.29437C2.56293 4.56712 4.04314 2.3707 8.0726 2.3707H10.3238C11.1326 2.3707 11.8943 2.72297 12.3796 3.32153L13.4075 4.58614C13.8939 5.18352 14.6549 5.53548 15.4634 5.53697H18.6499C22.6897 5.53697 24.1802 7.43863 24.1802 11.2419L24.1493 16.4145Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.94922 15.0928H18.7527"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
