import React, { FC, useState } from "react";
import styles from "./SelectTime.module.scss";
import DatePicker from "react-datepicker";
import Tooltip from "../Tooltip";
import classNames from "classnames";

type SelectTimeProps = {
  title: string;
  value: Date | null;
  onChange: React.Dispatch<React.SetStateAction<Date | null>>;
  placeholder: string;
  requirementsText?: string | string[];
  timeCaption?: string;
  timeIntervals?: number;
  minTime?: Date;
  maxTime?: Date;
  excludeTimes?: Date[];
  errText?: string;
  containerClassName?: string;
};

const SelectTime: FC<SelectTimeProps> = ({
  title,
  onChange,
  value,
  placeholder,
  timeCaption,
  timeIntervals,
  minTime,
  maxTime,
  excludeTimes,
  requirementsText,
  errText,
  containerClassName,
}) => {
  return (
    <div className={containerClassName}>
      {title && (
        <div className={styles.titleWrapper}>
          <p className={styles.title}>{title}</p>
          {requirementsText && <Tooltip requirementsText={requirementsText} error={!!errText} />}
        </div>
      )}
      <DatePicker
        selected={value}
        onChange={onChange}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={timeIntervals ?? 30}
        dateFormat="HH:mm"
        timeFormat="HH:mm"
        timeCaption={timeCaption ?? "Время"}
        minTime={minTime}
        maxTime={maxTime}
        excludeTimes={excludeTimes}
        className={classNames(styles.input, styles.btn, {
          [styles.activeInp]: value,
          [styles.errorInput]: errText,
        })}
        popperClassName={styles.dropDown}
        wrapperClassName={styles.wrapper}
        customInput={
          <button>
            {value
              ? value.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
              : placeholder}
          </button>
        }
      />
      {errText && <p className={styles.errorText}>{errText}</p>}
    </div>
  );
};

export default SelectTime;
