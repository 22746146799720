import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { Arrow2Icon, DeleteIcon } from '../../assets/icons';
import { OptionType } from '../../utils/@globalTypes';

import Checkbox from '../Checkbox';
import { SelectState } from '../../redux/types/bookingTypes';

import styles from './SelectMultiple.module.scss';
import { useClickOutside } from '../../utils/functions';

type SelectProps = {
  optionList?:OptionType[];
  select:SelectState[];
  handler:(id: string) => void;
  deleteAll:(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const SelectMultipleCustom = ({
    optionList, 
    select, 
    handler, 
    deleteAll
  }:SelectProps) => {
  
  const placeholder ="Выберите из списка";

  const [text, setText]=useState<string>(placeholder);
  const [icon, setIcon]=useState<JSX.Element>(<Arrow2Icon/>);
  const [isOpen, setIsopen] = useState<boolean>(false);  
  
  const handlerIsOpen =() =>{
    if(!isOpen){
      setIsopen(true);        
      
    } else {
      setIsopen(false);
    }
  }
  const isActive= select.find(el=> el.checked);
  const menuRef = useRef(null);
  const btnRef = useRef(null);

  useClickOutside(menuRef, btnRef, () => setIsopen(false));  
  const handlerDelete = (e:React.MouseEvent<HTMLDivElement, MouseEvent>)=> {
    e.stopPropagation();
    deleteAll(e);
  }

  useEffect(()=> {
    if(isActive){
      const current = select.filter(el=> el.checked)
      const count = current.length
      const selectName = optionList?.find(item=>item.value=== current[0].id)
      if(count>1){
        const text = `${selectName?.label} и ещё ${count-1}`
        setText(text)
      }
      if(count===1){
        const text = `${selectName?.label}`
        setText(text)
      }
    } else {setText(placeholder)}
  },[select, isActive])

  useEffect(()=> {

    if(isActive&&!isOpen){
      setIcon(
        <div           
          onClick={handlerDelete}
        >
          <DeleteIcon width='20px' height='20px'/>
        </div>
      )
    } else setIcon(<Arrow2Icon/>)
  },[isActive, isOpen])

  const options = optionList&&optionList.length>0 ?
  optionList?.map(item=>{ 
    const selectValue = select.find(slct=>slct.id=== item.value)?.checked 

  return(
    <div className={styles.optionLine} key={item.value}>
      <Checkbox
        checked={!!selectValue}
        onClick={handler}
        label={item.label}
        name={item.value}
        className="multipleselect"
      />
    </div>
    )}) : "Нет вариантов"

  return(
    <div className={classNames(
      styles.wrap, 
      isOpen&&styles.open,
      isActive&&styles.active
    )}      
      >
      <div className={classNames(
        styles.input
        
        )}
        onClick={handlerIsOpen}
        ref={btnRef}
      >
        <span className={styles.text}>
          {text}
        </span>
        <span className={styles.icon} >
          {icon}
        </span>
      </div>
      {isOpen&&
        <div ref={menuRef}>
          <div className={styles.divider}></div>
          <div className={styles.optionsList}>
            {options}
          </div>
        </div>
      }
    </div>
  )
}

export default SelectMultipleCustom;