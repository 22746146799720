import React from "react";

export const CanceledBookingIcon = ({ width = "26", height = "26", stroke = "#42AC4D"}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.9498 12.9998C1.9498 10.0692 3.114 7.25855 5.18627 5.18627C7.25855 3.114 10.0692 1.9498 12.9998 1.9498C15.9304 1.9498 18.7411 3.114 20.8133 5.18627C22.8856 7.25855 24.0498 10.0692 24.0498 12.9998C24.0498 15.9304 22.8856 18.7411 20.8133 20.8133C18.7411 22.8856 15.9304 24.0498 12.9998 24.0498C10.0692 24.0498 7.25855 22.8856 5.18627 20.8133C3.114 18.7411 1.9498 15.9304 1.9498 12.9998Z" stroke="#42AC4D" strokeWidth="1.3"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.9208 9.43619C18.1854 9.172 18.1855 8.74326 17.9211 8.47886C17.6567 8.21445 17.228 8.21462 16.9638 8.47924L13.2 12.249L9.43619 8.47924C9.172 8.21462 8.74326 8.21445 8.47886 8.47886C8.21445 8.74326 8.21462 9.172 8.47924 9.4362L12.249 13.2L8.47924 16.9638C8.21462 17.228 8.21445 17.6567 8.47886 17.9211C8.74326 18.1855 9.172 18.1854 9.4362 17.9208L13.2 14.151L16.9638 17.9208C17.228 18.1854 17.6567 18.1855 17.9211 17.9211C18.1855 17.6567 18.1854 17.228 17.9208 16.9638L14.151 13.2L17.9208 9.43619Z" fill="#42AC4D"/>
    </svg>
  );
};
