import { all, put, takeEvery, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiResponse } from "apisauce";
import callCheckingAuth from "./callCheckingAuth";
import API from "../api";
import { MessageTypes } from "../../utils/@globalTypes";
import { setMessage } from "../reducers/pageSlice";
import { setPrices } from "../reducers/pricesSlice";
import { ResponsePriceType, UpdatePricePayload } from "../types/pricesTypes";
import {
  getBookingSettings,
  setBookingSettings,
  updateBookingSettings,
} from "../reducers/bookingSettingsSlice";
import { BookingSettingsType, UpdateBookingSettingsPayload } from "../types/bookingSettingsTypes";

export function* getBookingSettingsWorker(action: PayloadAction<number>) {
  const { ok, data, status }: ApiResponse<BookingSettingsType> = yield callCheckingAuth(
    API.getBookingSettingsRequest,
    action.payload
  );

  if (data && ok) {
    yield put(setBookingSettings(data));
  } else {
    if (status) {
      yield put(
        setMessage({
          status: MessageTypes.ERROR,
          message: "Ошибка получения настроек брони",
          code: status,
        })
      );
    }
  }
}

function* updateBookingSettingsWorker(action: PayloadAction<UpdateBookingSettingsPayload>) {
  const { id, data } = action.payload;

  const {
    ok,
    data: responseData,
    status,
  }: ApiResponse<BookingSettingsType> = yield callCheckingAuth(
    API.updateBookingSettingsRequest,
    id,
    data
  );

  if (responseData && ok) {
    yield put(setBookingSettings(responseData));

    yield put(
      setMessage({
        status: MessageTypes.POSITIVE,
        message: "Изменения сохранены",
        code: 200,
      })
    );
  } else {
    if (status) {
      yield put(
        setMessage({
          status: MessageTypes.ERROR,
          message: "Ошибка обновления настроек брони",
          code: status,
        })
      );
    }
  }
}

export default function* bookingSettingsSaga() {
  yield all([takeLatest(updateBookingSettings, updateBookingSettingsWorker)]);
}
