import { useState } from "react";
import classNames from "classnames";
import { 
  NewBookingIcon,
  AwaitBookingIcon, 
  ConfirmBookingIcon,
  LineBookingIcon, 
  CanceledBookingIcon 
} from "../../../assets/icons";

import styles from "./ListHeader.module.scss";

type HeaderProps ={
  handlerFilterNew:() => void; 
  handlerFilterConfirm:() => void; 
  handlerFilterAwait:() => void; 
  handlerFilterLine:() => void; 
  handlerFilterCanceled:() => void; 
  isFilterNew:boolean,
  isFilterConfirm:boolean,
  isFilterAwait:boolean,
  isFilterLine:boolean,
  isFilterCanceled:boolean,
}

const ListHeader = ({
  handlerFilterNew,
  handlerFilterConfirm,
  handlerFilterAwait,
  handlerFilterLine,
  handlerFilterCanceled,
  isFilterNew,
  isFilterConfirm,
  isFilterAwait,
  isFilterLine,
  isFilterCanceled,
}:HeaderProps) => {
  

  return(
    <div className={styles.container}>
      <button className={classNames(styles.btn, isFilterNew&&styles.activeBtn)}
        onClick={handlerFilterNew}
      >
        <NewBookingIcon/>
        <div className={styles.title}>Новая бронь</div>
      </button>
      <button className={classNames(styles.btn, isFilterConfirm&&styles.activeBtn)}
        onClick={handlerFilterConfirm}
      >
        <ConfirmBookingIcon/>
        <div className={styles.title}>Бронь подтверждена</div>
      </button>
      <button className={classNames(styles.btn, isFilterAwait&&styles.activeBtn)}
       onClick={handlerFilterAwait}
      >
        <AwaitBookingIcon/>
        <div className={styles.title}>Ожидает подтверждения</div>
      </button>
      <button className={classNames(styles.btn, isFilterLine&&styles.activeBtn)}
        onClick={handlerFilterLine}
      >
        <LineBookingIcon/>
        <div className={styles.title}>Бронь в очереди</div>
      </button>
      <button className={classNames(styles.btn, isFilterCanceled&&styles.activeBtn)}
        onClick={handlerFilterCanceled}
      >
        <CanceledBookingIcon/>
        <div className={styles.title}>Бронь отменена</div>
      </button>
    </div>
  )
}

export default ListHeader;