import React, { FC } from "react";
import styles from "./RentalObjects.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ButtonType, ModalWindowTypes } from "../../../../utils/@globalTypes";
import {
  RentalObjectSelectors,
  removeRentalObject,
} from "../../../../redux/reducers/rentalObjectSlice";
import RentalObjectCard from "../../../../components/RentalObjectCard";
import Button from "../../../../components/Button";
import { AddObjectIcon } from "../../../../assets/icons";
import { setModalWindowData } from "../../../../redux/reducers/pageSlice";
import EmptyState from "../../../../components/EmptyState";
import { RentalObject } from "../../../../redux/types/rentalObjectTypes";

type RentalObjectsProps = {
  pageId: string;
};

const RentalObjects: FC<RentalObjectsProps> = ({ pageId }) => {
  const dispatch = useDispatch();

  const rentalObjectList = useSelector(RentalObjectSelectors.getRentalObjectList);

  const onDeleteBtnClick = (rentalId: number) => () => {
    dispatch(removeRentalObject({ main_object: pageId, rental_object: rentalId }));
  };

  const onNewObjectBtnClick = () => {
    dispatch(setModalWindowData({ type: ModalWindowTypes.CREATE_RENTAL_OBJECT, data: pageId }));
  };

  return (
    <div className={styles.objectsEditingWrapper}>
      <div className={styles.rentalObjectListWrapper}>
        {rentalObjectList.length > 0 ? (
          rentalObjectList.map((object) => {
            const {
              id,
              name,
              description,
              status,
              isLoading,
              image,
            } = object as RentalObject & {
              isLoading: boolean;
            };
            return (
              <RentalObjectCard
                key={id}
                id={id}
                mainObjectId={pageId}
                photo={image}
                onDeleteBtnClick={onDeleteBtnClick(id)}
                name={name}
                status={status}
                description={description ?? ""}
                isLoading={isLoading}
              />
            );
          })
        ) : (
          <EmptyState icon={<AddObjectIcon />} text="Объекты пока не добавлены" />
        )}
      </div>
      <div className={styles.newObjectBtnWrapper}>
        <Button
          title="Новый объект"
          type={ButtonType.PRIMARY_SMALL}
          onClick={onNewObjectBtnClick}
        />
      </div>
    </div>
  );
};

export default RentalObjects;
