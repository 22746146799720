import React, { useEffect } from "react";

export const CategoryIcon = ({ width = "24", height = "24", fill = "#ffffff" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2855 2.5H19.5521C20.9036 2.5 22 3.6059 22 4.97018V8.2641C22 9.62735 20.9036 10.7343 19.5521 10.7343H16.2855C14.933 10.7343 13.8366 9.62735 13.8366 8.2641V4.97018C13.8366 3.6059 14.933 2.5 16.2855 2.5Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.44892 2.5H7.71449C9.06703 2.5 10.1634 3.6059 10.1634 4.97018V8.2641C10.1634 9.62735 9.06703 10.7343 7.71449 10.7343H4.44892C3.09638 10.7343 2 9.62735 2 8.2641V4.97018C2 3.6059 3.09638 2.5 4.44892 2.5Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.44892 14.2657H7.71449C9.06703 14.2657 10.1634 15.3716 10.1634 16.7369V20.0298C10.1634 21.3941 9.06703 22.5 7.71449 22.5H4.44892C3.09638 22.5 2 21.3941 2 20.0298V16.7369C2 15.3716 3.09638 14.2657 4.44892 14.2657Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2855 14.2657H19.5521C20.9036 14.2657 22 15.3716 22 16.7369V20.0298C22 21.3941 20.9036 22.5 19.5521 22.5H16.2855C14.933 22.5 13.8366 21.3941 13.8366 20.0298V16.7369C13.8366 15.3716 14.933 14.2657 16.2855 14.2657Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
