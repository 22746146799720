import React, { FC } from "react";
import classNames from "classnames";
import styles from "./BannerInner.module.scss";
import { getCountryFlag } from "../../../utils/getCountryFlag";
import { 
  ConfirmBookingIcon, 
  AwaitBookingIcon,  
  TooltipIcon
} from "../../../assets/icons";
import { BookingFormType, BookingStatus, BookingType } from "../../../redux/types/bookingTypes";
import { setModalWindowData } from "../../../redux/reducers/pageSlice";
import { ModalWindowTypes } from "../../../utils/@globalTypes";
import { useDispatch } from "react-redux";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { setChessModalWindow } from "../../../redux/reducers/bookingSlice";

type BannerInnerProps = {
    booking:BookingType,
    bookingClassname:string,
    bannerWidth:number,
    left:number,
    isTooltip:boolean
}

const BannerInner: FC<BannerInnerProps> = ({ booking, bookingClassname, bannerWidth, left, isTooltip }) => {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
    const flag = getCountryFlag(booking.nationality)
    const roundSumSign:number = 2

    const handlerViewBooking= () =>{  
      dispatch(setModalWindowData({type: ModalWindowTypes.SHOW_RESERVATION, data:{booking:booking, type:BookingFormType.view}}))        
    }
    const openModalWindow = () =>{
      dispatch(setChessModalWindow({booking:booking, bookingClassName:bookingClassname}))
    } 

    const top_lettering = "Всего: "+ Number(booking.total).toFixed(roundSumSign) +" BYN";
  
    const bottom_lettering = "Долг: "+((+booking.total) - (+booking.paid)).toFixed(roundSumSign) +" BYN";

    return (
      <div className={classNames(styles.container, styles[bookingClassname], isTooltip&&styles.tooltip)}
        style={{width:bannerWidth, left:left}}
        onClick={ width>600 ? handlerViewBooking : openModalWindow}
      >
        <div className={styles.left}>
          <div className={styles.statusIcon}>         
            {booking.status==="awt" && <AwaitBookingIcon/>}
            {booking.status==="cfm" && <ConfirmBookingIcon/>}
          </div> 
          <div className={styles.name}>            
            <div>{booking.last_name}</div>
            <div>{booking.first_name}</div>
          </div> 
          <div className={styles.flag}>
            {flag({})}
          </div>
        </div>
        {booking.status==="cfm" &&
          <div className={styles.rigth}>
            <div className={styles.top}>{top_lettering}</div>
            <div className={styles.bottom}>{bottom_lettering}</div>
          </div>
        } 
        <div className={styles.tt_icon}>
          <TooltipIcon />
        </div>
      </div>
    );
  };
  
  export default BannerInner;



  