import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';

const ScrollToError = () => {
  const { errors, isSubmitting, isValidating, isValid, dirty } = useFormikContext();

  useEffect(() => {
    
    if (isSubmitting && !isValidating&& !isValid&& dirty) {     
      // console.log(errors) 
      let keys = Object.keys(errors);  
      let val = Object.values(errors)
      
      let keyIndex:number
      if (keys.length > 1 && keys[0]==="phone" ) {
       
        keyIndex=1
      } else keyIndex=0  
              
        const selector = `[id=${keys[keyIndex]}]`;
        if (selector){
          const errorElement = document.querySelector(selector) as HTMLElement;
        
        if (errorElement && (JSON.stringify(val[0])!=='{"phone_number":""}')) { // костыль для ошибки с номером телефона
            
          errorElement.scrollIntoView({
            block:"center",
            behavior:"smooth"  
        });
        }
      }
    }
  }, [errors, isSubmitting, isValidating, isValid]);

  return null;
};

export default ScrollToError;