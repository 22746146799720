import React, { FC, ReactElement, useLayoutEffect } from "react";
import { TabsNames } from "../utils/@globalTypes";

type ActiveTabProps = {
  children: ReactElement;
  setActiveTab: (value: TabsNames) => void;
  path: TabsNames;
};

const ActiveTab: FC<ActiveTabProps> = ({ children, setActiveTab, path }) => {
  useLayoutEffect(() => {
    setActiveTab(path);
  }, [setActiveTab, path]);

  return children;
};
export { ActiveTab };
