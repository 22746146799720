import React from "react";

export const PatioIcon = ({ width = "24", height = "24", fill = "#0F2664" }) => {
  return (
    <div className="feature">
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.2745 18.4015C12.7189 18.3248 12.5641 17.7398 12.7371 17.4329C12.9284 17.1069 13.5477 17.6056 13.83 17.3946C14.085 17.1932 14.0759 16.1191 14.422 16.1191C14.7954 16.1191 14.8227 17.3754 15.1779 17.4042C15.4967 17.4329 16.198 17.1836 16.2526 17.4042C16.3528 17.7782 16.2526 18.1905 15.8064 18.4111"
          stroke={fill}
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M10.0963 13.6445H5.55171C5.29016 13.6445 5.07812 13.8678 5.07812 14.1432V16.7612C5.07812 17.0366 5.29016 17.2599 5.55171 17.2599H10.0963C10.3579 17.2599 10.5699 17.0366 10.5699 16.7612V14.1432C10.5699 13.8678 10.3579 13.6445 10.0963 13.6445Z"
          stroke={fill}
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M19.4408 3H4.5592C3.14579 3 2 4.20647 2 5.69473V18.3053C2 19.7935 3.14579 21 4.5592 21H19.4408C20.8542 21 22 19.7935 22 18.3053V5.69473C22 4.20647 20.8542 3 19.4408 3Z"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="9.59961" cy="17.6152" r="0.5" fill={fill} />
        <path
          d="M15.6064 16.8198V11.5742"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.2988 16.6758C16.5538 16.4744 16.5447 15.4004 16.8908 15.4004C17.2642 15.4004 17.2915 16.6567 17.6467 16.6854C17.9655 16.7142 18.6668 16.4649 18.7214 16.6854C18.8216 17.0594 18.7214 17.4718 18.2751 17.6923"
          stroke={fill}
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M12.5009 8.09317C12.5009 8.09317 11.572 9.15763 12.2641 10.4618C12.9563 11.7661 14.8051 11.1139 14.8051 11.1139L15.7978 10.7687C15.7978 10.7687 17.1002 11.7085 18.4845 10.3659C19.4317 9.45492 19.368 8.40004 19.368 8.40004C19.368 8.40004 19.5046 6.52045 17.7195 6.1944C16.9545 6.05055 16.1986 6.26152 16.0528 6.05055C15.7705 5.65737 15.2605 5.18747 14.796 5.09157C13.9763 4.90937 13.3388 5.26419 12.7559 6.0985C12.1184 7.01912 12.5009 8.09317 12.5009 8.09317Z"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.07812 16.2729V11.0273"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="5.59961" cy="17.6152" r="0.5" fill={fill} />
      </svg>
      <div>Патио</div>
    </div>
  );
};
