import { TelephoneType } from "../../utils/@globalTypes";
import { PayloadDataWithId } from "./@types";

export type BookingType = {
  id: number;
  children?: number[];
  phone: TelephoneType;
  check_in_date: string;
  check_out_date: string;
  check_in_time: string;
  check_out_time: string;
  adult: number;
  total: string;
  paid: string;
  has_animals: boolean;
  animals_info?: string;
  status: BookingStatus;
  first_name: string;
  second_name?: string;
  last_name: string;
  birth_day?: string;
  sex?: string;
  nationality: string;
  email: string;
  telegram?: string;
  comment?: string;
  rental_object: number;
  created_at: string;
};

export enum Gender {
  fem,
  mal,
}

export type BookingStatus = "new" | "que" | "awt" | "cfm" | "cld";

export enum BookingFormType {
  view,
  edit,
  new,
}

export type RentalObjectShort = {
  id: number;
  name: string;
};
export type MainObjectShort = {
  id: number;
  name: string;
  rental_objects: RentalObjectShort[] | [];
};
export type ChessObjectsResponse = {
  count: number;
  next: number | null;
  previous: number | null;
  results: MainObjectShort[];
};
export type BookingListResponse = {
  count: number;
  next: number | null;
  previous: number | null;
  results: BookingType[];
};

export type UpdateBookingType = {
  children?: number[];
  phone?: TelephoneType;
  check_in_date?: string;
  check_out_date?: string;
  check_in_time?: string;
  check_out_time?: string;
  adult?: number;
  total?: string;
  paid?: string;
  has_animals?: boolean;
  animals_info?: string;
  status?: BookingStatus;
  first_name?: string;
  second_name?: string;
  last_name?: string;
  birth_day?: string;
  sex?: string;
  nationality?: string;
  email?: string;
  telegram?: string;
  comment?: string;
  rental_object?: number;
};

export type UpdateBookingPayloadData = PayloadDataWithId<UpdateBookingType>;

export type FormBookingType = {  
  phone:TelephoneType,
  check_in_date:Date,
  check_out_date:Date,
  check_in_time:string,
  check_out_time: string,
  adult:number,
  children?:number[]
  total?:string,
  paid?:string,
  rest?:string
  has_animals:boolean,
  animals_info:string,
  status:BookingStatus,
  first_name:string,
  second_name?:string,
  last_name:string,
  birth_day?:Date,
  sex?:string,
  nationality:string,
  email:string,
  telegram:string,
  comment:string,
  rental_object:number|undefined 
}
export type SelectState = {
  id: string;
  checked: boolean;
};
