import { JsxElement } from "typescript";
import { 
  NewBookingIcon,
  AwaitBookingIcon, 
  ConfirmBookingIcon,
  LineBookingIcon, 
  CanceledBookingIcon 
} from "./../../assets/icons";
import { OptionType } from "../../utils/@globalTypes";


export const getBookingIcon =(status:string)=>{
  switch (status){    
    case "new":
      return NewBookingIcon({});
      break;
    case "awt":
      return AwaitBookingIcon({});
      break;
    case "que":
      return LineBookingIcon({});
      break;
    case "cld":
      return CanceledBookingIcon({});
      break;
    case "cfm":
      return ConfirmBookingIcon({});
      break;
    default:
      return NewBookingIcon({})          
  }
}


