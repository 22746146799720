import { all, put, takeLatest } from "redux-saga/effects";
import {
  getOwnerInfo,
  setIndividualInfo,
  setOwnerInfo,
  updateOwnerInfo,
  getIndividualInfo,
  setEntityInfo,
  updateIndividualInfo,
  getEntityInfo,
  updateEntityInfo,
  getSettingsData,
} from "./../reducers/userSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiResponse } from "apisauce";
import API from "../api";
import {
  GetIndividualInfoResponseData,
  GetEntityInfoResponseData,
  UpdateOwnerPayloadData,
  UpdateIndividualPayloadData,
  UpdateEntityPayloadData,
  GetSettingsDataPayload,
} from "../types/userTypes";
import callCheckingAuth from "./callCheckingAuth";
import { GetOwnerInfoResponseData } from "../types/authTypes";
import { setLoadersData, setMessage } from "../reducers/pageSlice";
import { LoaderTypes, MessageTypes } from "../../utils/@globalTypes";
import { setErrorResponeData } from "../reducers/authSlice";
import { ErrorResponseData } from "../types/@types";

function* getOwnerInfoWorker(action: PayloadAction<number>) {
  const { ok, data, status }: ApiResponse<GetOwnerInfoResponseData> = yield callCheckingAuth(
    API.getUserSettingsRequest,
    action.payload
  );

  if (data && ok) {
    yield put(setOwnerInfo(data));
  } else {
    if (status) {
      yield put(
        setMessage({
          status: MessageTypes.ERROR,
          message: "Ошибка получения данных пользователя",
          code: status,
        })
      );
    }
  }
}

function* getSettingsDataWorker(action: PayloadAction<GetSettingsDataPayload>) {
  yield put(setLoadersData({ type: LoaderTypes.SETTINGS_PAGE, value: true }));

  const { entity_id, individual_id, owner_id } = action.payload;

  yield all([
    getOwnerInfoWorker(getOwnerInfo(owner_id)),
    getIndividualInfoWorker(getIndividualInfo(individual_id)),
    getEntityInfoWorker(getEntityInfo(entity_id)),
  ]);

  yield put(setLoadersData({ type: LoaderTypes.SETTINGS_PAGE, value: false }));
}

function* getIndividualInfoWorker(action: PayloadAction<number>) {
  const { ok, data, status }: ApiResponse<GetIndividualInfoResponseData> = yield callCheckingAuth(
    API.getIndividualSettingsRequest,
    action.payload
  );

  if (data && ok) {
    yield put(setIndividualInfo(data));
  } else {
    if (status) {
      yield put(
        setMessage({
          status: MessageTypes.ERROR,
          message: "Ошибка получения данных о физическом лице",
          code: status,
        })
      );
    }
  }
}

function* getEntityInfoWorker(action: PayloadAction<number>) {
  const { ok, data, status }: ApiResponse<GetEntityInfoResponseData> = yield callCheckingAuth(
    API.getEntitySettingsRequest,
    action.payload
  );

  if (data && ok) {
    yield put(setEntityInfo(data));
  } else {
    if (status) {
      yield put(
        setMessage({
          status: MessageTypes.ERROR,
          message: "Ошибка получения данных о юридическом лице",
          code: status,
        })
      );
    }
  }
}

function* updateOwnerSettingsWorker(action: PayloadAction<UpdateOwnerPayloadData>) {
  const { id, data } = action.payload;

  const {
    ok,
    data: responseData,
    status,
  }: ApiResponse<GetOwnerInfoResponseData, ErrorResponseData> = yield callCheckingAuth(
    API.updateUserSettingsRequest,
    id,
    data
  );

  if (ok && responseData) {
    yield put(setOwnerInfo(responseData));
    yield put(
      setMessage({
        status: MessageTypes.POSITIVE,
        message: "Изменения сохранены",
        code: 200,
      })
    );
  } else {
    if (status) {
      if (responseData && !ok && status === 400) {
        yield put(setErrorResponeData(responseData));
      } else {
        yield put(
          setMessage({
            status: MessageTypes.ERROR,
            message: "Ошибка сохранения",
            code: status,
          })
        );
      }
    }
  }
}

function* updateIndividualSettingsWorker(action: PayloadAction<UpdateIndividualPayloadData>) {
  const { id, data } = action.payload;

  const {
    ok,
    data: responseData,
    status,
  }: ApiResponse<GetIndividualInfoResponseData, ErrorResponseData> = yield callCheckingAuth(
    API.updateIndividualSettingsRequest,
    id,
    data
  );

  if (ok && responseData) {
    yield put(setIndividualInfo(responseData));
    yield put(
      setMessage({
        status: MessageTypes.POSITIVE,
        message: "Изменения сохранены",
        code: 200,
      })
    );
  } else {
    if (status) {
      if (responseData && !ok && status === 400) {
        yield put(setErrorResponeData(responseData));
      } else {
        yield put(
          setMessage({
            status: MessageTypes.ERROR,
            message: "Ошибка сохранения",
            code: status,
          })
        );
      }
    }
  }
}

function* updateEntitySettingsWorker(action: PayloadAction<UpdateEntityPayloadData>) {
  const { id, data } = action.payload;
  const {
    ok,
    data: responseData,
    status,
  }: ApiResponse<GetEntityInfoResponseData, ErrorResponseData> = yield callCheckingAuth(
    API.updateEntitySettingsRequest,
    id,
    data
  );

  if (ok && responseData) {
    yield put(setEntityInfo(responseData));
    yield put(
      setMessage({
        status: MessageTypes.POSITIVE,
        message: "Изменения сохранены",
        code: 200,
      })
    );
  } else {
    if (status) {
      if (responseData && !ok && status === 400) {
        yield put(setErrorResponeData(responseData));
      } else {
        yield put(
          setMessage({
            status: MessageTypes.ERROR,
            message: "Ошибка сохранения",
            code: status,
          })
        );
      }
    }
  }
}
export default function* userSaga() {
  yield all([
    takeLatest(getSettingsData, getSettingsDataWorker),
    takeLatest(getOwnerInfo, getOwnerInfoWorker),
    takeLatest(getIndividualInfo, getIndividualInfoWorker),
    takeLatest(getEntityInfo, getEntityInfoWorker),
    takeLatest(updateOwnerInfo, updateOwnerSettingsWorker),
    takeLatest(updateIndividualInfo, updateIndividualSettingsWorker),
    takeLatest(updateEntityInfo, updateEntitySettingsWorker),
  ]);
}
