import React, { useEffect, useMemo, useState } from "react";
import styles from "./ActivateAccountWindow.module.scss";
import ModalWindow from "../ModalWindow";
import {
  ButtonType,
  FieldTypes,
  InputType,
  LoaderTypes,
  ModalWindowTypes,
} from "../../../utils/@globalTypes";
import Input from "../../Input";
import Button from "../../Button";
import { useDispatch, useSelector } from "react-redux";
import {
  AuthSelectors,
  confirmActivateCode,
  sendActivateCode,
  setErrorResponeData,
} from "../../../redux/reducers/authSlice";
import { getErrorText, setTimer } from "../../../utils/functions";
import { useNavigate } from "react-router-dom";
import { INVALID_CODE_ERROR, TIME_CODE_CONFIRMATION_SENDING } from "../../../utils/constants";
import { RoutesList } from "../../../App";
import { PageSelectors, setModalWindowData } from "../../../redux/reducers/pageSlice";

const ActivateAccountWindow = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const errorResponseData = useSelector(AuthSelectors.getErrorResponseData);
  const isLoadingMain = useSelector(
    PageSelectors.getLoadersData(LoaderTypes.ACTIVATE_ACCOUNT_POPUP_1)
  );
  const isLoadingSecondary = useSelector(
    PageSelectors.getLoadersData(LoaderTypes.ACTIVATE_ACCOUNT_POPUP_2)
  );

  const modalWindowData: { email: string; password: string } | null = useSelector(
    PageSelectors.getModalWindowData("data")
  );

  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");

  const [isStartTimer, setIsStartTimer] = useState(false);
  const [blockTime, setBlockTime] = useState("");
  const [isStartBlockTimer, setIsStartBlockTimer] = useState(false);

  const onActivateBtnClick = () => {
    if (!codeError && code.length > 0 && modalWindowData) {
      dispatch(
        confirmActivateCode({
          callback(length, blockTime) {
            if (length && blockTime) {
              setTimer(length, setIsStartBlockTimer);
              setBlockTime(blockTime);
            } else {
              navigate(RoutesList.checkerboard.fullPath);
            }
          },
          data: {
            user: {
              email: modalWindowData.email,
              password: modalWindowData.password,
            },
            code,
          },
        })
      );
    }
  };

  const onGetCodeBtnClick = () => {
    if (modalWindowData) {
      dispatch(
        sendActivateCode({
          data: modalWindowData.email,
          callback: () => {
            setTimer(TIME_CODE_CONFIRMATION_SENDING, setIsStartTimer);
          },
        })
      );
    }
  };

  useEffect(() => {
    setCodeError("");
  }, [code]);

  useEffect(() => {
    if (errorResponseData) {
      errorResponseData.code &&
        setCodeError(getErrorText(errorResponseData.code[0], INVALID_CODE_ERROR, FieldTypes.CODE));
      dispatch(setErrorResponeData(null));
    }
  }, [errorResponseData]);

  useEffect(() => {
    if (modalWindowData) {
      dispatch(
        sendActivateCode({
          data: modalWindowData.email,
          callback: (length) => {
            setTimer(length, setIsStartTimer);
          },
        })
      );
    }
  }, [modalWindowData]);

  useEffect(() => {
    !modalWindowData && dispatch(setModalWindowData({ type: ModalWindowTypes.CLOSE }));
  }, []);

  const isValid = useMemo(() => {
    return !codeError && code.length === 6 && !isStartBlockTimer;
  }, [codeError, code, isStartBlockTimer]);

  return (
    <ModalWindow
      title="Активация аккаунта"
      customBtns
      windowClassname={styles.window}
      isLoading={isLoadingMain}
    >
      <p className={styles.text}>
        Пожалуйста, введите код, который мы отправили на указанную Вами почту:
      </p>
      <Input
        value={code}
        title="Код*"
        placeholder="Введите код"
        type={InputType.TEXT}
        onChange={setCode}
        errText={codeError}
        banSymbols={/\D/g}
      />
      <div className={styles.btnsWrapper}>
        <Button
          title="Активировать"
          type={ButtonType.PRIMARY}
          onClick={onActivateBtnClick}
          promptText={
            isStartBlockTimer
              ? `Количество допустимых попыток активации аккаунта превышено. Попробуйте снова через ${blockTime}`
              : undefined
          }
          disabled={!isValid}
        />
        <span>или</span>
        <Button
          title="Получить код еще раз"
          type={ButtonType.SECONDARY}
          onClick={onGetCodeBtnClick}
          promptText={isStartTimer ? "Код активации отправлен вам на почту" : undefined}
          isResponseText
          disabled={isLoadingSecondary || isStartTimer}
        />
      </div>
    </ModalWindow>
  );
};

export default ActivateAccountWindow;
