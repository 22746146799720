import React, { useEffect, useState } from "react";
import styles from "./RentalObjectSettings.module.scss";
import { Navigate, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { LoaderTypes, TabsNames } from "../../../utils/@globalTypes";
import { TabListType } from "../../../components/Tabs/@types";
import { useDispatch, useSelector } from "react-redux";
import { PageSelectors } from "../../../redux/reducers/pageSlice";
import Loader from "../../../components/Loader";
import Tabs from "../../../components/Tabs";
import { ActiveTab } from "../../../hoc/ActiveTab";
import RentalBasicSettings from "./RentalBasicSettings";
import PlacementSettings from "./PlacementSettings";
import Prices from "./Prices";
import BookingSettings from "./BookingSettings";
import { RoutesList } from "../../../App";
import {
  getRentalObjectTabsData,
  setCurrentRentalImages,
  setCurrentRentalObject,
  setRentalObjectFeatures,
} from "../../../redux/reducers/rentalObjectSlice";

const TABS_LIST: TabListType = [
  {
    title: "Базовые настройки",
    path: TabsNames.RENTAL_BASIC_SETTINGS,
  },
  {
    title: "Настройки размещения",
    path: TabsNames.PLACEMENT_SETTINGS,
  },
  {
    title: "Цены / Сезон",
    path: TabsNames.PRICES,
  },
  {
    title: "Настройки брони",
    path: TabsNames.BOOKING_SETTINGS,
  },
];

const RentalObjectSettings = () => {
  const { mainId, rentalId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoading = useSelector(
    PageSelectors.getLoadersData(LoaderTypes.RENTAL_OBJECT_EDITING_PAGE)
  );

  const [activeTab, setActiveTab] = useState(TabsNames.RENTAL_BASIC_SETTINGS);

  useEffect(() => {
    if (mainId && rentalId) {
      dispatch(getRentalObjectTabsData(+rentalId));
    }

    return () => {
      dispatch(setCurrentRentalObject(null));
      dispatch(setRentalObjectFeatures([]));
      dispatch(setCurrentRentalImages([]));
    };
  }, [mainId, rentalId]);

  return !isLoading ? (
    <>
      <h2 className={styles.objectName}>Настройки сдаваемого объекта</h2>
      <div className={styles.wrapper}>
        <Tabs tabsList={TABS_LIST} activeTab={activeTab}>
          <Routes>
            <Route index element={<Navigate to={TabsNames.RENTAL_BASIC_SETTINGS} replace />} />
            <Route
              path={TabsNames.RENTAL_BASIC_SETTINGS}
              element={
                <ActiveTab setActiveTab={setActiveTab} path={TabsNames.RENTAL_BASIC_SETTINGS}>
                  <RentalBasicSettings />
                </ActiveTab>
              }
            />
            <Route
              path={TabsNames.PLACEMENT_SETTINGS}
              element={
                <ActiveTab setActiveTab={setActiveTab} path={TabsNames.PLACEMENT_SETTINGS}>
                  <PlacementSettings />
                </ActiveTab>
              }
            />
            <Route
              path={TabsNames.PRICES}
              element={
                <ActiveTab setActiveTab={setActiveTab} path={TabsNames.PRICES}>
                  <Prices />
                </ActiveTab>
              }
            />
            <Route
              path={TabsNames.BOOKING_SETTINGS}
              element={
                <ActiveTab setActiveTab={setActiveTab} path={TabsNames.BOOKING_SETTINGS}>
                  <BookingSettings />
                </ActiveTab>
              }
            />
            <Route
              path={RoutesList.any.path}
              element={<Navigate to={RoutesList.notFound.fullPath} replace />}
            />
          </Routes>
        </Tabs>
      </div>
    </>
  ) : (
    <Loader />
  );
};

export default RentalObjectSettings;
