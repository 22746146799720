import React from "react";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ACCESS_TOKEN_KEY } from "../../utils/constants";
import { RootState } from "../store";
import { ErrorResponseData } from "../types/@types";
import {
  ConfirmActivateCodePayloadData,
  CreateOwnerPayloadData,
  SendActivateCodePayloadData,
  SignInUserPayloadData,
  UserInfo,
} from "../types/authTypes";

type AuthState = {
  isLoggedIn: boolean;
  errorResponseData: ErrorResponseData | null;
  errorResponseDetailsData: string;
  userInfo: UserInfo | null;
};

const initialState: AuthState = {
  isLoggedIn: !!localStorage.getItem(ACCESS_TOKEN_KEY),
  errorResponseData: null,
  errorResponseDetailsData: "",
  userInfo: null,
};

const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    createOwner(_, __: PayloadAction<CreateOwnerPayloadData>) {},
    confirmActivateCode(_, __: PayloadAction<ConfirmActivateCodePayloadData>) {},
    sendActivateCode(_, __: PayloadAction<SendActivateCodePayloadData>) {},
    signInUser(_, __: PayloadAction<SignInUserPayloadData>) {},
    setLoggedIn(state, action: PayloadAction<boolean>) {
      state.isLoggedIn = action.payload;
    },
    setErrorResponeData(state, action: PayloadAction<ErrorResponseData | null>) {
      state.errorResponseData = action.payload;
    },
    setErrorResponeDetailsData(state, action: PayloadAction<string>) {
      state.errorResponseDetailsData = action.payload;
    },
    logoutUser(_, __: PayloadAction<undefined>) {},
    getCurrentUserInfo(_, __: PayloadAction<undefined>) {},
    setUserInfo(state, action: PayloadAction<UserInfo | null>) {
      state.userInfo = action.payload;
    },
  },
});

export const {
  createOwner,
  signInUser,
  setLoggedIn,
  setErrorResponeData,
  setErrorResponeDetailsData,
  logoutUser,
  getCurrentUserInfo,
  setUserInfo,
  confirmActivateCode,
  sendActivateCode,
} = AuthSlice.actions;

export default AuthSlice.reducer;

export const authName = AuthSlice.name;

export const AuthSelectors = {
  getLoggedIn: (state: RootState) => state.auth.isLoggedIn,
  getErrorResponseData: (state: RootState) => state.auth.errorResponseData,
  getErrorResponseDetailsData: (state: RootState) => state.auth.errorResponseDetailsData,
  getUserInfo: (state: RootState) => state.auth.userInfo,
};
