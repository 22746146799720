import React from "react";

export const FurnitureForInfantsIcon = ({ width = "24", height = "24", fill = "#0F2664" }) => {
  return (
    <div className="feature">
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1.5 16.5H22.5" stroke={fill} strokeLinecap="round" />
        <path
          d="M1.5 10.0005C4.16667 9.66721 8.5 10.0007 8 16.5005"
          stroke={fill}
          strokeLinecap="round"
        />
        <path d="M1.5 7V22.5" stroke={fill} strokeLinecap="round" />
        <path d="M1.5 20H22.5" stroke={fill} strokeLinecap="round" />
        <path
          d="M10.5 16.5V10.6C10.5 10.5448 10.5448 10.5 10.6 10.5H19.5C20.5 10.5 22.5 11 22.5 13C22.5 15 22.5 20.1667 22.5 22.5"
          stroke={fill}
          strokeLinecap="round"
        />
        <path
          d="M9.63329 5.744C9.19596 5.744 8.80129 5.632 8.44929 5.408C8.10262 5.184 7.82796 4.85867 7.62529 4.432C7.42262 4.00533 7.32129 3.48533 7.32129 2.872C7.32129 2.25867 7.42262 1.73867 7.62529 1.312C7.82796 0.885334 8.10262 0.56 8.44929 0.336C8.80129 0.112 9.19596 0 9.63329 0C10.0706 0 10.4626 0.112 10.8093 0.336C11.1613 0.56 11.4386 0.885334 11.6413 1.312C11.844 1.73867 11.9453 2.25867 11.9453 2.872C11.9453 3.48533 11.844 4.00533 11.6413 4.432C11.4386 4.85867 11.1613 5.184 10.8093 5.408C10.4626 5.632 10.0706 5.744 9.63329 5.744ZM9.63329 4.928C9.91062 4.928 10.1533 4.85333 10.3613 4.704C10.5693 4.55467 10.732 4.328 10.8493 4.024C10.9666 3.72 11.0253 3.336 11.0253 2.872C11.0253 2.408 10.9666 2.024 10.8493 1.72C10.732 1.416 10.5693 1.18933 10.3613 1.04C10.1533 0.890667 9.91062 0.816 9.63329 0.816C9.36129 0.816 9.11862 0.890667 8.90529 1.04C8.69729 1.18933 8.53462 1.416 8.41729 1.72C8.29996 2.024 8.24129 2.408 8.24129 2.872C8.24129 3.336 8.29996 3.72 8.41729 4.024C8.53462 4.328 8.69729 4.55467 8.90529 4.704C9.11862 4.85333 9.36129 4.928 9.63329 4.928Z"
          fill={fill}
        />
        <path
          d="M14.3195 4.648V1.096H15.0875V4.648H14.3195ZM12.8875 3.232V2.512H16.5195V3.232H12.8875Z"
          fill={fill}
        />
      </svg>
      <div>Мебель для грудных детей</div>
    </div>
  );
};
