import React from "react";

export const NewBookingIcon = ({ width = "26", height = "26", stroke = "#42AC4D"}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.9498 12.9998C1.9498 10.0692 3.114 7.25855 5.18627 5.18627C7.25855 3.114 10.0692 1.9498 12.9998 1.9498C15.9304 1.9498 18.7411 3.114 20.8133 5.18627C22.8856 7.25855 24.0498 10.0692 24.0498 12.9998C24.0498 15.9304 22.8856 18.7411 20.8133 20.8133C18.7411 22.8856 15.9304 24.0498 12.9998 24.0498C10.0692 24.0498 7.25855 22.8856 5.18627 20.8133C3.114 18.7411 1.9498 15.9304 1.9498 12.9998Z" stroke="#42AC4D" strokeWidth="1.3"/>
      <path d="M12.4629 7.16977C12.6331 6.78798 12.7182 6.59709 12.8367 6.53831C12.9396 6.48723 13.0604 6.48723 13.1633 6.53831C13.2818 6.59709 13.367 6.78798 13.5371 7.16977L14.8926 10.2107C14.943 10.3236 14.9681 10.38 15.0071 10.4233C15.0415 10.4615 15.0836 10.4921 15.1306 10.513C15.1837 10.5367 15.2452 10.5432 15.3681 10.5561L18.6791 10.9056C19.0948 10.9495 19.3026 10.9714 19.3951 11.0659C19.4755 11.148 19.5128 11.263 19.4961 11.3767C19.4768 11.5075 19.3216 11.6474 19.0111 11.9272L16.5377 14.1562C16.446 14.2389 16.4 14.2802 16.371 14.3306C16.3453 14.3752 16.3292 14.4247 16.3238 14.4758C16.3177 14.5337 16.3305 14.5941 16.3562 14.715L17.047 17.972C17.1337 18.3809 17.1771 18.5853 17.1158 18.7025C17.0625 18.8044 16.9647 18.8754 16.8515 18.8946C16.7211 18.9166 16.54 18.8122 16.1779 18.6034L13.2938 16.9399C13.1868 16.8782 13.1333 16.8474 13.0764 16.8352C13.026 16.8246 12.974 16.8246 12.9236 16.8352C12.8667 16.8474 12.8132 16.8782 12.7062 16.9399L9.82212 18.6034C9.46003 18.8122 9.27899 18.9166 9.14858 18.8946C9.0353 18.8754 8.9375 18.8044 8.88425 18.7025C8.82295 18.5853 8.86632 18.3809 8.95305 17.972L9.64385 14.715C9.66949 14.5941 9.68231 14.5337 9.67622 14.4758C9.67083 14.4247 9.65476 14.3752 9.62903 14.3306C9.59996 14.2802 9.55406 14.2389 9.46227 14.1562L6.989 11.9272C6.6785 11.6474 6.52324 11.5075 6.50394 11.3767C6.48718 11.263 6.52453 11.148 6.6049 11.0659C6.69743 10.9714 6.90527 10.9495 7.32096 10.9056L10.632 10.5561C10.7549 10.5432 10.8163 10.5367 10.8694 10.513C10.9164 10.4921 10.9585 10.4615 10.993 10.4233C11.0319 10.38 11.0571 10.3236 11.1074 10.2107L12.4629 7.16977Z" stroke="#42AC4D" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>    
  );
};
