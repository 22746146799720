import React from "react";

export const DiamondIcon = ({ width = "24", height = "24", fill = "#0F2664" }) => {
  return (
    <div className="feature">
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22 8L18.25 3H5.76125L2 8M22 8L12 20.5M22 8H2M12 20.5L2 8M12 20.5L8.25 8M12 20.5L15.75 8"
          stroke={fill}
          strokeWidth="0.875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.76172 3L8.25047 8L12.0005 3L15.7505 8L18.2505 3"
          stroke={fill}
          strokeWidth="0.875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <div>Свой вариант</div>
    </div>
  );
};
