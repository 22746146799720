import React, {useState, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setModalWindowData } from "../../../redux/reducers/pageSlice";
import moment from "moment";
import 'moment/locale/ru';
import Calendar from "../../../components/Calendar";
import Button from "../../../components/Button";
import { ButtonType, ModalWindowTypes } from "../../../utils/@globalTypes";
import CheckerboardTable from "../../../components/CheckerboardTable";
import { 
  ArrowSingle, 
  ArrowDouble  
} from "../../../assets/icons";
import styles from "./Checkerboard.module.scss";
import { BookingSelectors } from "../../../redux/reducers/bookingSlice";
import HelpPanel from "../HelpPanel";

const Checkerboard = () => {
  const dispatch = useDispatch();
  moment.locale('ru');
  
  const handlerNewBooking=()=>{
    dispatch(setModalWindowData({type: ModalWindowTypes.CREATE_RESERVATION}))    
    
  }
  const buttonRef = useRef<HTMLDivElement | null>(null);

  const handleClick = (e:any) => {
    if(buttonRef.current){
    const buttonRect = buttonRef.current!.getBoundingClientRect();
    const isInsideButton =
      e.clientY >= buttonRect.top &&
      e.clientY <= buttonRect.bottom &&
      e.clientX >= buttonRect.left &&
      e.clientX <= buttonRect.right;
      
    // Если клик был вне кнопки, то игнорируем его
    if (!isInsideButton) {      
      return;
    }}
    dispatch(setModalWindowData({type: ModalWindowTypes.CREATE_RESERVATION}))    
  };

  const objects = useSelector(BookingSelectors.getChessObjects);
  
  const today= moment().startOf('day');
  const startDate=today.clone().subtract(6,'month')
  const endDate=today.clone().add(12,'month')

  const createPeriod =(today:moment.Moment)=> {    
    const period:moment.Moment[]=[]        
    for(let i=startDate; i<=endDate; i=i.clone().add(1,"day")){      
      period.push(i)
    }
    return period
  }
  
  const period= useMemo(()=>createPeriod(today),[today])
   
  const [selectedDate, setSelectedDate] = useState(new Date());  //дата, выбранная на календаре
  const [initialDay, setInitialDay]=useState(today);
  const [isForceUpdateScroll, setIsForceUpdateScroll] = useState<boolean>(false)   //принудительный update скролла, если дата на календаре не менялась и надо к ней вернуться

  const minDate = startDate.toDate();  //ограничительные даты для календаря
  const maxDate = endDate.toDate();
 
  const handlerPrevMonth=()=>{    
    setInitialDay(prevState=> {
      const newDate = prevState.clone().subtract(1, 'month')
      if(newDate>startDate){
        return(
          newDate
        )}else return startDate
      }
    )
    setIsForceUpdateScroll(false)
  }

  const handlerNextMonth=()=>{
    setInitialDay(prevState=> {
      const newDate = prevState.clone().add(1, 'month')
      if(newDate<endDate){
      return(
        newDate
      )}else return endDate
    }
    )
    setIsForceUpdateScroll(false)
  }

  const handlerPrevWeek=()=>{
    setInitialDay(prevState=> {
      const newDate = prevState.clone().subtract(7, 'day')
      if(newDate>startDate){
        return(
          newDate
        )}else return startDate
      }
    )
    setIsForceUpdateScroll(false)
  }

  const handlerNextWeek=()=>{
    setInitialDay(prevState=> {
      const newDate= prevState.clone().add(7, 'day')
      if(newDate<endDate){
        return(
          newDate
        )}else return endDate
      }
  )
  setIsForceUpdateScroll(false)
}

  const handlerToday=()=>{
    setInitialDay(today)
    setIsForceUpdateScroll(true)    
  }
  
  const handlerDatepicker =(date:Date)=> {
    if (date instanceof Date){
      
    setSelectedDate(date);
    setInitialDay(moment(date))

    }else{
      setSelectedDate(new Date());
      setInitialDay(today)
    }
  }
  
  return (
    <section className={styles.container}>
      <div className={styles.helpMob}>
        <HelpPanel/>
      </div>
      <div className={styles.buttonPanel}>
        <div className={styles.leftWrapper}>
          <div className={styles.calendarWrap}>
            <div className={styles.calendarButton}> 
              <div className={styles.prev} onClick={handlerPrevMonth}>
                <ArrowDouble/>
                <div className={styles.help}>Предыдущий месяц</div>
              </div>            
              <div className={styles.prev} onClick={handlerPrevWeek}>
                <ArrowSingle/>
                <div className={styles.help}>Предыдущая неделя</div>              
              </div>
            </div>
            <div className={styles.calendar}>
              <Calendar 
                selectedDate={selectedDate}
                onChange={handlerDatepicker}
                minDate={minDate}
                maxDate={maxDate}
                placeholderText="Введите дату"                
              />
            </div>
            <div className={styles.calendarButton}>
              <div className={styles.next} onClick={handlerNextWeek}>
                <ArrowSingle/>
                <div className={styles.help}>Следующая неделя</div>
              </div>
              <div className={styles.next} onClick={handlerNextMonth}>
                <ArrowDouble/>
                <div className={styles.help}>Следующий месяц</div>
              </div>
            </div>
          </div>
          <div className={styles.todayDesk}>
            <Button
              title="Сегодня"
              onClick={handlerToday}
              type={ButtonType.PRIMARY_SMALL}
              className={styles.todayButton}
            />
          </div>
          <div className={styles.todayMobile}>
            <div className={styles.todayWrap}>
              <div className={styles.calendarButton}> 
                <div className={styles.prev} onClick={handlerPrevMonth}>
                  <ArrowDouble/>
                  <div className={styles.help}>Предыдущий месяц</div>
                </div>            
                <div className={styles.prev} onClick={handlerPrevWeek}>
                  <ArrowSingle/>
                  <div className={styles.help}>Предыдущая неделя</div>              
                </div>
              </div>
              <Button
                title="Сегодня"
                onClick={handlerToday}
                type={ButtonType.SECONDARY_SMALL}
                className={styles.todayButton}
              />
              <div className={styles.calendarButton}> 

                <div className={styles.next} onClick={handlerNextWeek}>
                  <ArrowSingle/>
                  <div className={styles.help}>Следующая неделя</div>
                </div>
                <div className={styles.next} onClick={handlerNextMonth}>
                  <ArrowDouble/>
                  <div className={styles.help}>Следующий месяц</div>
                </div>
              </div>
            </div>
          </div>
        </div>       
        <div className={styles.helpDesc}>
          <HelpPanel/>
        </div>
        <div className={styles.rightWrapper}>
          <div className={styles.desctop}>      
            <Button
              title="Новая бронь"
              onClick={handlerNewBooking}
              type={ButtonType.PRIMARY_SMALL}
              className={styles.bookingButton}
            />
          </div>  
          <div className={styles.mobile}>      
            <Button
              title="+"
              onClick={handlerNewBooking}
              type={ButtonType.PRIMARY_SMALL}
              className={styles.mobile}              
            />
          </div>  
        </div>        
      </div>
      
      {objects&&<CheckerboardTable 
        period={period} 
        today={today} 
        isForceUpdate={isForceUpdateScroll}
        setForceUpdate = {setIsForceUpdateScroll}
        objects= {objects}
        selectedDate = {selectedDate}
        setSelectedDate = {setSelectedDate}
        initialDay = {initialDay}
        handlerToday ={handlerToday}
      />}
      <div className={styles.fixMobile} ref={buttonRef} onClick={handleClick}>      
        <Button
          title="+"          
          type={ButtonType.PRIMARY_SMALL}
          className={styles.fixMobile}                       
        />
      </div>  
    </section>
  );
};

export default Checkerboard;
