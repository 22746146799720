import React from "react";

export const InfoIcon = ({ width = "40", height = "40", fill = "#0F2664" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 9.25C26.2132 9.25 31.25 14.2868 31.25 20.5C31.25 26.7132 26.2132 31.75 20 31.75C13.7868 31.75 8.75 26.7132 8.75 20.5C8.75 14.2868 13.7868 9.25 20 9.25Z" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M20 25.5V19.25" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M20 15.5H20.0125" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};
