import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  confirmActivateCode,
  createOwner,
  getCurrentUserInfo,
  logoutUser,
  sendActivateCode,
  setErrorResponeData,
  setErrorResponeDetailsData,
  setLoggedIn,
  setUserInfo,
  signInUser,
} from "../reducers/authSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiResponse } from "apisauce";
import API from "../api";

import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  TIME_CODE_CONFIRMATION_SENDING,
} from "../../utils/constants";
import {
  ConfirmActivateCodePayloadData,
  CreateOwnerPayloadData,
  ErrorWithTimestampResponseData,
  GetOwnerInfoResponseData,
  SendActivateCodePayloadData,
  SignInUserPayloadData,
  TokensResponseData,
  UserInfo,
} from "../types/authTypes";
import { ErrorResponseData } from "../types/@types";
import { setLoadersData, setMessage, setModalWindowData } from "../reducers/pageSlice";
import { LoaderTypes, MessageTypes, ModalWindowTypes } from "../../utils/@globalTypes";
import { getFormattedTime } from "../../utils/functions";
import callCheckingAuth from "./callCheckingAuth";

function* createOwnerWorker(action: PayloadAction<CreateOwnerPayloadData>) {
  yield put(setLoadersData({ type: LoaderTypes.SIGN_UP_PAGE, value: true }));

  const { data, callback } = action.payload;

  const {
    ok: createOwnerOk,
    data: responseCreateOwnerData,
    status,
  }: ApiResponse<GetOwnerInfoResponseData, ErrorResponseData> = yield call(
    API.createOwnerRequest,
    data
  );

  if (responseCreateOwnerData && createOwnerOk && callback) {
    callback();

    yield put(
      setModalWindowData({
        type: ModalWindowTypes.ACTIVATE_ACCOUNT,
        data: {
          email: data.createOwnerData.email,
          password: data.createOwnerData.password,
        },
      })
    );

    if (status) {
      yield put(
        setMessage({
          status: MessageTypes.POSITIVE,
          message: "Вы успешно зарегистрированы",
          code: status,
        })
      );
    }

    if (data.statistic) {
      const {
        ok: statisticOk,
        data: responseStatisticData,
        status: statisticStatus,
      }: ApiResponse<any> = yield call(API.setStatisticRequest, {
        text: data.statistic,
        user: responseCreateOwnerData.user.id,
      });

      if (responseStatisticData && statisticOk) {
        //
      } else {
        if (statisticStatus) {
          yield put(
            setMessage({
              status: MessageTypes.ERROR,
              message: "Ошибка отправки статистики",
              code: statisticStatus,
            })
          );
        }
      }
    }
  } else {
    if (status) {
      if (responseCreateOwnerData && !createOwnerOk && status === 400) {
        yield put(setErrorResponeData(responseCreateOwnerData));
      } else {
        yield put(
          setMessage({
            status: MessageTypes.ERROR,
            message: "Ошибка регистрации пользователя",
            code: status,
          })
        );
      }
    }
  }

  yield put(setLoadersData({ type: LoaderTypes.SIGN_UP_PAGE, value: false }));
}

function* confirmActivateCodeWorker(action: PayloadAction<ConfirmActivateCodePayloadData>) {
  yield put(setLoadersData({ type: LoaderTypes.ACTIVATE_ACCOUNT_POPUP_1, value: true }));

  const { callback, data } = action.payload;

  const {
    ok,
    data: responseData,
    status,
  }: ApiResponse<any, any> = yield call(API.confirmActivateCodeRequest, {
    user_email: data.user.email,
    code: data.code,
  });

  if (responseData && ok && callback) {
    yield put(setModalWindowData({ type: ModalWindowTypes.CLOSE }));
    yield put(signInUser({ callback, data: data.user }));
  } else {
    if (status) {
      if (responseData && !ok && status === 400 && callback) {
        if (responseData.detail === "You was blocked") {
          const remainingTime = Math.trunc(
            +responseData.unblocked_time + 1 - new Date().getTime() / 1000
          );

          callback(remainingTime, getFormattedTime(remainingTime));
        } else {
          yield put(setErrorResponeData(responseData));
        }
      } else {
        yield put(
          setMessage({
            status: MessageTypes.ERROR,
            message: "Ошибка активации аккаунта",
            code: status,
          })
        );
      }
    }
  }

  yield put(setLoadersData({ type: LoaderTypes.ACTIVATE_ACCOUNT_POPUP_1, value: false }));
}

function* sendActivateCodeWorker(action: PayloadAction<SendActivateCodePayloadData>) {
  yield put(setLoadersData({ type: LoaderTypes.ACTIVATE_ACCOUNT_POPUP_2, value: true }));

  const { data, callback } = action.payload;

  const {
    ok,
    data: responseData,
    status,
  }: ApiResponse<undefined, ErrorWithTimestampResponseData> = yield call(
    API.sendActivateCodeRequest,
    data
  );

  if (ok && callback) {
    callback(TIME_CODE_CONFIRMATION_SENDING); // Сделать в ответе last_attempt_time
  } else {
    if (status) {
      if (
        responseData &&
        !ok &&
        status === 400 &&
        callback &&
        responseData.detail === "Too frequent sending"
      ) {
        const remainingTime =
          TIME_CODE_CONFIRMATION_SENDING +
          2 -
          Math.trunc(new Date().getTime() / 1000 - +responseData.last_attempt_time);

        callback(remainingTime);
      } else {
        yield put(
          setMessage({
            status: MessageTypes.ERROR,
            message: "Ошибка отправки кода активации",
            code: status,
          })
        );
      }
    }
  }

  yield put(setLoadersData({ type: LoaderTypes.ACTIVATE_ACCOUNT_POPUP_2, value: false }));
}

function* signInUserWorker(action: PayloadAction<SignInUserPayloadData>) {
  yield put(setLoadersData({ type: LoaderTypes.SIGN_IN_PAGE, value: true }));

  const { data, callback } = action.payload;

  const {
    ok,
    data: responseData,
    status,
  }: ApiResponse<TokensResponseData, any> = yield call(API.signInRequest, data);

  if (responseData && ok && callback) {
    localStorage.setItem(ACCESS_TOKEN_KEY, responseData.access);
    localStorage.setItem(REFRESH_TOKEN_KEY, responseData.refresh);
    yield put(setLoggedIn(true));
    callback();
  } else {
    if (status) {
      if (responseData && !ok && status === 401) {
        if (responseData.detail === "User is not active.") {
          yield put(
            setModalWindowData({
              type: ModalWindowTypes.ACTIVATE_ACCOUNT,
              data: {
                email: data.email,
                password: data.password,
              },
            })
          );
        } else {
          yield put(setErrorResponeDetailsData(responseData.detail));
        }
      } else if (responseData && !ok && status === 400 && callback) {
        if (responseData.detail === "You was blocked") {
          const remainingTime = Math.trunc(
            +responseData.unblocked_time + 1 - new Date().getTime() / 1000
          );
          callback(remainingTime, getFormattedTime(remainingTime));
        } else {
          yield put(setErrorResponeDetailsData(responseData.detail));
        }
      } else {
        yield put(
          setMessage({
            status: MessageTypes.ERROR,
            message: "Ошибка авторизации пользователя",
            code: status,
          })
        );
      }
    }
  }

  yield put(setLoadersData({ type: LoaderTypes.SIGN_IN_PAGE, value: false }));
}

function* getCurrentUserInfoWorker() {
  const { ok, data, status }: ApiResponse<UserInfo> = yield callCheckingAuth(
    API.getCurrentUserInfoRequest
  );

  if (data && ok) {
    yield put(setUserInfo(data));
  } else {
    if (status) {
      yield put(
        setMessage({
          status: MessageTypes.ERROR,
          message: "Ошибка получения информации о пользователе",
          code: status,
        })
      );
    }
  }
}

function* logoutUserWorker() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  yield put(setLoggedIn(false));
  yield put(setUserInfo(null));
}

export default function* authSaga() {
  yield all([
    takeLatest(createOwner, createOwnerWorker),
    takeLatest(signInUser, signInUserWorker),
    takeLatest(logoutUser, logoutUserWorker),
    takeLatest(getCurrentUserInfo, getCurrentUserInfoWorker),
    takeLatest(confirmActivateCode, confirmActivateCodeWorker),
    takeLatest(sendActivateCode, sendActivateCodeWorker),
  ]);
}
