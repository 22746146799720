import { FC } from "react";
import { 
  NewBookingIcon,
  AwaitBookingIcon, 
  ConfirmBookingIcon,
  LineBookingIcon, 
  CanceledBookingIcon 
} from "../../../assets/icons";
import styles from './StatusInfo.module.scss';

type MyProps = {
  handlerClick:()=>void
}

const StatusInfo:FC<MyProps> =({handlerClick}) =>{

  return(
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.text}>
          Статусы броней
        </div>
        <div className={styles.btnClose} onClick={handlerClick}>
          +
        </div>
      </div>
      <div className={styles.info}>
        <div>
          <ConfirmBookingIcon width="20" height="20"/>
          <div className={styles.title}>Бронь подтверждена</div>
        </div>
        <div>
          <AwaitBookingIcon width="20" height="20"/>
          <div className={styles.title}>Ожидает подтверждения</div>
        </div>
        <div>
          <NewBookingIcon width="20" height="20"/>
          <div className={styles.title}>Новая бронь</div>
        </div>       
        <div>
          <LineBookingIcon width="20" height="20"/>
          <div className={styles.title}>Бронь в очереди</div>
        </div>
        <div>
          <CanceledBookingIcon width="20" height="20"/>
          <div className={styles.title}>Бронь отменена</div>
        </div>
      </div>
    </div>
  )
}

export default StatusInfo;