import React from "react";

export const LineBookingIcon = ({ width = "26", height = "26", stroke = "#42AC4D"}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.9498 12.9998C1.9498 10.0692 3.114 7.25855 5.18627 5.18627C7.25855 3.114 10.0692 1.9498 12.9998 1.9498C15.9304 1.9498 18.7411 3.114 20.8133 5.18627C22.8856 7.25855 24.0498 10.0692 24.0498 12.9998C24.0498 15.9304 22.8856 18.7411 20.8133 20.8133C18.7411 22.8856 15.9304 24.0498 12.9998 24.0498C10.0692 24.0498 7.25855 22.8856 5.18627 20.8133C3.114 18.7411 1.9498 15.9304 1.9498 12.9998Z" stroke={stroke} strokeWidth="1.3"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M10 9.6C10 9.2 10.1333 9 10.4 9H11.6C11.8667 9 12 9.2 12 9.6V16.4C12 16.8 11.8667 17 11.6 17H10.4C10.1333 17 10 16.8 10 16.4V9.6ZM14 9.6C14 9.2 14.1333 9 14.4 9H15.6C15.8667 9 16 9.2 16 9.6V16.4C16 16.8 15.8667 17 15.6 17H14.4C14.1333 17 14 16.8 14 16.4V9.6Z" fill="#42AC4D"/>
    </svg>



  );
};