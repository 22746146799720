import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { setMessage } from "../reducers/pageSlice";
import { MessageTypes } from "../../utils/@globalTypes";
import { ApiResponse } from "apisauce";
import { PayloadAction } from "@reduxjs/toolkit";
import API from "../api";
import {
  addRoomToList,
  createRoom,
  getRentalObjectRoomTypes,
  removeRoom,
  removeRoomFromList,
  saveRooms,
  setCurrentRentalObjectRooms,
  setRentalObjectRoomTypes,
  updateRoom,
  updateRoomInList,
} from "../reducers/roomSlice";
import callCheckingAuth from "./callCheckingAuth";
import {
  CreateRoomPayload,
  RentalObjectRoomsResponseData,
  RentalObjectRoomTypesPayload,
  RentalObjectRoomTypesResponseData,
  ResponseRoomType,
  UpdateRoomPayload,
} from "../types/roomTypes";
import { RoomListWithStatusTypeList } from "../../components/RoomCard/@types";

function* getRentalObjectRoomTypesWorker(action: PayloadAction<RentalObjectRoomTypesPayload>) {
  const { ok, data, status }: ApiResponse<RentalObjectRoomTypesResponseData> = yield call(
    API.getRentalObjectRoomTypesRequest,
    action.payload
  );

  if (data && ok) {
    const optionsList = data.results.map((item) => {
      return { value: item.id.toString(), label: item.title };
    });
    yield put(setRentalObjectRoomTypes(optionsList));
  } else {
    if (status) {
      yield put(
        setMessage({
          status: MessageTypes.ERROR,
          message: "Ошибка получения типов комнат",
          code: status,
        })
      );
    }
  }
}

export function* getCurrentRentalObjectRoomsWorker(action: PayloadAction<number>) {
  const { ok, data, status }: ApiResponse<RentalObjectRoomsResponseData> = yield callCheckingAuth(
    API.getCurrentRentalObjectRoomsRequest,
    action.payload
  );

  if (data && ok) {
    yield put(
      setCurrentRentalObjectRooms(
        data.results.map((item) => ({ ...item, type: item.type.id.toString() }))
      )
    );
  } else {
    if (status) {
      yield put(
        setMessage({
          status: MessageTypes.ERROR,
          message: "Ошибка получения комнат",
          code: status,
        })
      );
    }
  }
}

function* createRoomWorker(action: PayloadAction<CreateRoomPayload>) {
  const { ok, data, status }: ApiResponse<ResponseRoomType> = yield callCheckingAuth(
    API.createRoomRequest,
    action.payload
  );

  if (data && ok) {
    yield put(addRoomToList({ ...data, type: data.type.id.toString() }));
  } else {
    if (status) {
      yield put(
        setMessage({
          status: MessageTypes.ERROR,
          message: "Ошибка создания комнаты",
          code: status,
        })
      );

      return "Create";
    }
  }
}

function* updateRoomWorker(action: PayloadAction<UpdateRoomPayload>) {
  const { id, data } = action.payload;

  const {
    ok,
    data: responseData,
    status,
  }: ApiResponse<ResponseRoomType> = yield callCheckingAuth(API.updateRoomRequest, id, data);

  if (responseData && ok) {
    yield put(updateRoomInList({ ...responseData, type: responseData.type.id.toString() }));
  } else {
    if (status) {
      yield put(
        setMessage({
          status: MessageTypes.ERROR,
          message: "Ошибка редактирования комнаты",
          code: status,
        })
      );

      return "Update";
    }
  }
}

function* removeRoomWorker(action: PayloadAction<number>) {
  const { ok, status }: ApiResponse<undefined> = yield callCheckingAuth(
    API.removeRoomRequest,
    action.payload
  );

  if (ok) {
    yield put(removeRoomFromList(action.payload));
  } else {
    if (status) {
      yield put(
        setMessage({
          status: MessageTypes.ERROR,
          message: "Ошибка удаления комнаты",
          code: status,
        })
      );

      return "Delete";
    }
  }
}

function* saveRoomsWorker(action: PayloadAction<RoomListWithStatusTypeList>) {
  const rooms = action.payload;
  const rejectedList = [];

  for (let i = 0; i < rooms.length; i++) {
    let reject: string | undefined;
    const currentRoom = rooms[i];

    if (currentRoom.isNew && !currentRoom.isDelete) {
      reject = yield createRoomWorker(createRoom(currentRoom.room));
    } else if (!currentRoom.isNew && !currentRoom.isDelete && currentRoom.isUpdate) {
      reject = yield updateRoomWorker(
        updateRoom({
          id: currentRoom.room.id,
          data: {
            type: currentRoom.room.type,
            beds: currentRoom.room.beds,
            name: currentRoom.room.name,
          },
        })
      );
    } else if (!currentRoom.isNew && currentRoom.isDelete) {
      reject = yield !rooms[i].isNew && removeRoomWorker(removeRoom(rooms[i].room.id));
    }

    reject && rejectedList.push(reject);
  }

  if (rejectedList.length === 0) {
    yield put(
      setMessage({
        status: MessageTypes.POSITIVE,
        message: "Изменения сохранены",
        code: 200,
      })
    );
  }
}

export default function* roomSaga() {
  yield all([
    takeLatest(getRentalObjectRoomTypes, getRentalObjectRoomTypesWorker),
    takeLatest(saveRooms, saveRoomsWorker),
    takeEvery(createRoom, createRoomWorker),
    takeEvery(updateRoom, updateRoomWorker),
    takeEvery(removeRoom, removeRoomWorker),
  ]);
}
