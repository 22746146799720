import React from "react";

export const Home2Icon = ({ width = "40", height = "40", fill = "#ffffff" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.3346 28.3334V19.0868C33.3346 18.1968 33.3346 17.7518 33.2263 17.3368C33.1304 16.9699 32.9726 16.6222 32.7596 16.3084C32.518 15.9534 32.1846 15.6584 31.513 15.0734L23.513 8.07345C22.2696 6.98511 21.6463 6.44011 20.9463 6.23345C20.3296 6.05011 19.673 6.05011 19.0546 6.23345C18.3546 6.44011 17.7346 6.98345 16.4913 8.07011L8.48964 15.0734C7.81964 15.6601 7.48464 15.9534 7.24464 16.3068C7.03097 16.6209 6.87258 16.9693 6.7763 17.3368C6.66797 17.7501 6.66797 18.1968 6.66797 19.0868V28.3334C6.66797 29.8868 6.66797 30.6634 6.9213 31.2751C7.08863 31.6797 7.33401 32.0473 7.64343 32.357C7.95285 32.6667 8.32024 32.9124 8.72464 33.0801C9.33797 33.3334 10.1146 33.3334 11.668 33.3334C13.2213 33.3334 13.998 33.3334 14.6113 33.0801C15.0157 32.9124 15.3831 32.6667 15.6925 32.357C16.0019 32.0473 16.2473 31.6797 16.4146 31.2751C16.668 30.6634 16.668 29.8868 16.668 28.3334V26.6668C16.668 25.7827 17.0192 24.9349 17.6443 24.3098C18.2694 23.6846 19.1172 23.3334 20.0013 23.3334C20.8854 23.3334 21.7332 23.6846 22.3583 24.3098C22.9834 24.9349 23.3346 25.7827 23.3346 26.6668V28.3334C23.3346 29.8868 23.3346 30.6634 23.588 31.2751C23.7553 31.6797 24.0007 32.0473 24.3101 32.357C24.6195 32.6667 24.9869 32.9124 25.3913 33.0801C26.0046 33.3334 26.7813 33.3334 28.3346 33.3334C29.888 33.3334 30.6646 33.3334 31.278 33.0801C31.6824 32.9124 32.0498 32.6667 32.3592 32.357C32.6686 32.0473 32.914 31.6797 33.0813 31.2751C33.3346 30.6634 33.3346 29.8868 33.3346 28.3334Z"
        stroke={fill}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
