import React from "react";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { LoaderTypes } from "../../utils/@globalTypes";
import { Message, ModalWindowData, PageData } from "../types/pageTypes";

type PageState = {
  pageData: PageData | null;
  modalWindow: ModalWindowData;
  loaders: {
    [LoaderTypes.MAIN_OBJECTS_PAGE]: boolean;
    [LoaderTypes.OBJECT_EDITING_PAGE]: boolean;
    [LoaderTypes.RENTAL_OBJECT_EDITING_PAGE]: boolean;
    [LoaderTypes.SETTINGS_PAGE]: boolean;
    [LoaderTypes.SIGN_IN_PAGE]: boolean;
    [LoaderTypes.SIGN_UP_PAGE]: boolean;
    [LoaderTypes.SIGN_UP_STAFF_PAGE]: boolean;
    [LoaderTypes.ACTIVATE_ACCOUNT_POPUP_1]: boolean;
    [LoaderTypes.ACTIVATE_ACCOUNT_POPUP_2]: boolean;
    [LoaderTypes.CREATE_OBJECT_POPUP]: boolean;
    [LoaderTypes.CREATE_RENTAL_OBJECT_POPUP]: boolean;
    [LoaderTypes.BOOKING_PAGE]: boolean;
  };

  message: Message | null;
};

const initialState: PageState = {
  pageData: null,
  modalWindow: {
    data: null,
    type: null,
  },
  loaders: {
    [LoaderTypes.MAIN_OBJECTS_PAGE]: true,
    [LoaderTypes.OBJECT_EDITING_PAGE]: true,
    [LoaderTypes.RENTAL_OBJECT_EDITING_PAGE]: true,
    [LoaderTypes.SETTINGS_PAGE]: true,
    [LoaderTypes.SIGN_IN_PAGE]: false,
    [LoaderTypes.SIGN_UP_PAGE]: false,
    [LoaderTypes.SIGN_UP_STAFF_PAGE]: false,
    [LoaderTypes.ACTIVATE_ACCOUNT_POPUP_1]: false,
    [LoaderTypes.ACTIVATE_ACCOUNT_POPUP_2]: false,
    [LoaderTypes.CREATE_OBJECT_POPUP]: false,
    [LoaderTypes.CREATE_RENTAL_OBJECT_POPUP]: false,
    [LoaderTypes.BOOKING_PAGE]: true,
  },
  message: null,
};

const PageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    setPageData(state, action: PayloadAction<PageData | null>) {
      state.pageData = action.payload;
    },
    setModalWindowData(state, action: PayloadAction<ModalWindowData>) {
      const { type, data } = action.payload;
      state.modalWindow = { type, data: data || null };
    },
    setLoadersData(state, action: PayloadAction<{ type: LoaderTypes; value: boolean }>) {
      const { type, value } = action.payload;
      state.loaders[type] = value;
    },
    setMessage(state, action: PayloadAction<Message | null>) {
      state.message = action.payload;
    },
  },
});

export const { setPageData, setModalWindowData, setLoadersData, setMessage } = PageSlice.actions;

export default PageSlice.reducer;

export const pageName = PageSlice.name;

export const PageSelectors = {
  getPageData: (state: RootState) => state.page.pageData,
  getModalWindowData: (key: "type" | "data") => (state: RootState) => state.page.modalWindow[key],
  getLoadersData: (key: LoaderTypes) => (state: RootState) => state.page.loaders[key],
  getMessage: (state: RootState) => state.page.message,
};
