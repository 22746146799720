import { call, put } from "redux-saga/effects";
import { ApiResponse } from "apisauce";
import API from "../api";

import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from "../../utils/constants";
import { logoutUser } from "../reducers/authSlice";

function* callCheckingAuth(apiCall: any, ...params: any) {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

  const response: ApiResponse<any> = yield call(apiCall, accessToken, ...params);

  if (accessToken && refreshToken) {
    if (response.status === 401) {
      const { ok, data }: ApiResponse<{ access: string }> = yield call(
        API.getNewTokensRequest,
        refreshToken
      );

      if (ok && data) {
        localStorage.setItem(ACCESS_TOKEN_KEY, data.access);

        const newResponse: ApiResponse<any> = yield call(apiCall, data.access, ...params);
        return newResponse;
      } else {
        yield put(logoutUser());
        return response;
      }
    } else {
      return response;
    }
  } else {
    yield put(logoutUser());
    return response;
  }
}

export default callCheckingAuth;
