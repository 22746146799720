import React from "react";

export const OwnerIcon = ({ width = "25", height = "25" }) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12.5" cy="12.5" r="12.5" fill="white"/>
      <ellipse cx="12.4" cy="9.94235" rx="2.64124" ry="2.5881" stroke="#1855B7" strokeWidth="0.96" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.21046 16.13C8.20975 15.9481 8.25127 15.7684 8.33189 15.6045C8.58489 15.1087 9.29832 14.8459 9.89033 14.7269C10.3173 14.6376 10.7501 14.578 11.1856 14.5484C11.992 14.479 12.803 14.479 13.6093 14.5484C14.0448 14.5783 14.4776 14.6379 14.9046 14.7269C15.4966 14.8459 16.2101 15.0839 16.4631 15.6045C16.6252 15.9386 16.6252 16.3264 16.4631 16.6605C16.2101 17.1811 15.4966 17.4191 14.9046 17.5331C14.4782 17.6261 14.0452 17.6874 13.6093 17.7166C12.953 17.7711 12.2937 17.781 11.636 17.7463C11.4842 17.7463 11.3374 17.7463 11.1856 17.7166C10.7514 17.6878 10.3201 17.6265 9.89539 17.5331C9.29832 17.4191 8.58995 17.1811 8.33189 16.6605C8.25169 16.4947 8.2102 16.3135 8.21046 16.13Z" stroke="#1855B7" strokeWidth="0.96" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};