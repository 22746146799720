import React from "react";

export const AddObjectIcon = ({ width = "51", height = "58", fill = "#696F79" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 51 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M40.5 6V16" stroke="#696F79" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M35.5 11L45.5 11" stroke="#696F79" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M45.5 49.3562V35.4862C45.5 34.1512 45.5 33.4838 45.3375 32.8613C45.1937 32.311 44.957 31.7893 44.6375 31.3188C44.275 30.7863 43.775 30.3437 42.7675 29.4662L30.7675 18.9662C28.9025 17.3337 27.9675 16.5162 26.9175 16.2062C25.9925 15.9312 25.0075 15.9312 24.08 16.2062C23.03 16.5162 22.1 17.3312 20.235 18.9612L8.2325 29.4662C7.2275 30.3462 6.725 30.7863 6.365 31.3163C6.0445 31.7875 5.80691 32.31 5.6625 32.8613C5.5 33.4813 5.5 34.1512 5.5 35.4862V49.3562C5.5 51.6862 5.5 52.8512 5.88 53.7687C6.13099 54.3756 6.49907 54.927 6.96319 55.3916C7.42732 55.8561 7.97841 56.2247 8.585 56.4763C9.505 56.8563 10.67 56.8563 13 56.8563C15.33 56.8563 16.495 56.8563 17.415 56.4763C18.0216 56.2247 18.5727 55.8561 19.0368 55.3916C19.5009 54.927 19.869 54.3756 20.12 53.7687C20.5 52.8512 20.5 51.6862 20.5 49.3562V46.8562C20.5 45.5302 21.0268 44.2584 21.9645 43.3207C22.9021 42.383 24.1739 41.8562 25.5 41.8562C26.8261 41.8562 28.0979 42.383 29.0355 43.3207C29.9732 44.2584 30.5 45.5302 30.5 46.8562V49.3562C30.5 51.6862 30.5 52.8512 30.88 53.7687C31.131 54.3756 31.4991 54.927 31.9632 55.3916C32.4273 55.8561 32.9784 56.2247 33.585 56.4763C34.505 56.8563 35.67 56.8563 38 56.8563C40.33 56.8563 41.495 56.8563 42.415 56.4763C43.0216 56.2247 43.5727 55.8561 44.0368 55.3916C44.5009 54.927 44.869 54.3756 45.12 53.7687C45.5 52.8512 45.5 51.6862 45.5 49.3562Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
