import React from "react";

export const CartIcon = ({ width = "25", height = "25", fill = "#ffffff" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.73156 20.6461C8.17115 20.6461 8.52844 21.0034 8.52844 21.443C8.52844 21.8826 8.17115 22.2389 7.73156 22.2389C7.29198 22.2389 6.93573 21.8826 6.93573 21.443C6.93573 21.0034 7.29198 20.6461 7.73156 20.6461Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4528 20.6461C19.8924 20.6461 20.2497 21.0034 20.2497 21.443C20.2497 21.8826 19.8924 22.2389 19.4528 22.2389C19.0132 22.2389 18.6559 21.8826 18.6559 21.443C18.6559 21.0034 19.0132 20.6461 19.4528 20.6461Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.8645 3.38541L5.03117 3.76041L6.03429 15.7114C6.11554 16.6854 6.92909 17.4333 7.90617 17.4333H19.2728C20.2062 17.4333 20.9978 16.7479 21.1322 15.8229L22.1208 8.99166C22.2426 8.14895 21.5895 7.39478 20.7385 7.39478H5.37909"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.714 11.2448H17.6025"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
