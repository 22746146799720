import React from "react";

export const AwaitBookingIcon = ({ width = "26", height = "26", stroke = "#42AC4D"}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.9498 12.9998C1.9498 10.0692 3.114 7.25855 5.18627 5.18627C7.25855 3.114 10.0692 1.9498 12.9998 1.9498C15.9304 1.9498 18.7411 3.114 20.8133 5.18627C22.8856 7.25855 24.0498 10.0692 24.0498 12.9998C24.0498 15.9304 22.8856 18.7411 20.8133 20.8133C18.7411 22.8856 15.9304 24.0498 12.9998 24.0498C10.0692 24.0498 7.25855 22.8856 5.18627 20.8133C3.114 18.7411 1.9498 15.9304 1.9498 12.9998Z" stroke="#42AC4D" strokeWidth="1.3"/>
      <path d="M12 6V13.8" stroke="#42AC4D" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17.512 19.3118L12 13.7998" stroke="#42AC4D" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};
