import React from "react";

export const PlateIcon = ({ width = "24", height = "24", fill = "#0F2664" }) => {
  return (
    <div className="feature">
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.9998 8.5H4.99949C4.72337 8.5 4.5 8.72374 4.5 8.9999C4.5 9.27606 4.72337 9.4998 4.99949 9.4998H18.9998C19.2759 9.4998 19.5 9.27606 19.5 8.9999C19.5 8.72374 19.2759 8.5 18.9998 8.5Z"
          fill={fill}
        />
        <path
          d="M21.0002 0.4999H19.5002C19.5002 0.223785 19.2761 0 19 0H13C12.7239 0 12.4998 0.223739 12.4998 0.4999H11.5784C11.5784 0.223785 11.3542 0 11.0781 0H5.07809C4.80197 0 4.57782 0.223739 4.57782 0.4999H3.00022C2.72332 0.4999 2.5 0.724008 2.5 1.00012V22.9995C2.5 23.2764 2.72337 23.5005 3.00022 23.5005H3.49971C3.49971 23.7759 3.72386 24 3.99998 24C4.27609 24 4.5002 23.7759 4.5002 23.5005H19.5002C19.5002 23.7759 19.7236 24 19.9997 24C20.2758 24 20.5 23.7759 20.5 23.5005H21.0002C21.2756 23.5005 21.4997 23.2764 21.4997 22.9995V1.00012C21.4998 0.724008 21.2756 0.4999 21.0002 0.4999ZM3.49971 1.49998H20.5V6.49985H3.49971V1.49998ZM20.5 22.5H3.49971V20.5005H9.49975C9.49975 20.7759 9.72386 21 9.99998 21H13.9997C14.2758 21 14.5 20.7759 14.5 20.5005H20.5V22.5ZM20.5 19.5H3.49971V7.49998H20.5V19.5Z"
          fill={fill}
        />
        <path
          d="M5.99998 2.5C5.17085 2.5 4.5 3.17163 4.5 3.99998C4.5 4.82832 5.17085 5.49995 5.99998 5.49995C6.82758 5.49995 7.49995 4.82832 7.49995 3.99998C7.49995 3.17163 6.82754 2.5 5.99998 2.5ZM5.99993 4.49988C5.72382 4.49988 5.49971 4.2754 5.49971 3.99998C5.49971 3.90877 5.52389 3.82345 5.56706 3.75021C5.65352 3.89928 5.81461 3.99998 5.99993 3.99998C6.18451 3.99998 6.3456 3.89928 6.43207 3.75021C6.47454 3.82345 6.49942 3.90877 6.49942 3.99998C6.49942 4.2754 6.27531 4.49988 5.99993 4.49988Z"
          fill={fill}
        />
        <path
          d="M9.99998 2.5C9.17163 2.5 8.5 3.17163 8.5 3.99998C8.5 4.82832 9.17163 5.49995 9.99998 5.49995C10.8283 5.49995 11.5 4.82832 11.5 3.99998C11.5 3.17163 10.8283 2.5 9.99998 2.5ZM9.99993 4.49988C9.72382 4.49988 9.49971 4.2754 9.49971 3.99998C9.49975 3.90877 9.52463 3.82345 9.56706 3.75021C9.65352 3.89928 9.81466 3.99998 9.99993 3.99998C10.1852 3.99998 10.3456 3.89928 10.4328 3.75021C10.4752 3.82345 10.5002 3.90877 10.5002 3.99998C10.5002 4.2754 10.2753 4.49988 9.99993 4.49988Z"
          fill={fill}
        />
        <path
          d="M14 2.5C13.1716 2.5 12.5 3.17163 12.5 3.99998C12.5 4.82832 13.1716 5.49995 14 5.49995C14.8291 5.49995 15.5 4.82832 15.5 3.99998C15.5 3.17163 14.8291 2.5 14 2.5ZM14 4.49988C13.7246 4.49988 13.5005 4.2754 13.5005 3.99998C13.5004 3.90877 13.5253 3.82345 13.5678 3.75021C13.6542 3.89928 13.8153 3.99998 14 3.99998C14.1845 3.99998 14.3457 3.89928 14.4321 3.75021C14.4753 3.82345 14.5002 3.90877 14.5002 3.99998C14.5002 4.2754 14.2754 4.49988 14 4.49988Z"
          fill={fill}
        />
        <path
          d="M18 2.5C17.1708 2.5 16.5 3.17163 16.5 3.99998C16.5 4.82832 17.1708 5.49995 18 5.49995C18.8276 5.49995 19.5 4.82832 19.5 3.99998C19.5 3.17163 18.8276 2.5 18 2.5ZM17.9999 4.49988C17.7238 4.49988 17.4997 4.2754 17.4997 3.99998C17.4997 3.90877 17.5238 3.82345 17.5671 3.75021C17.6535 3.89928 17.8146 3.99998 17.9999 3.99998C18.1845 3.99998 18.3456 3.89928 18.4321 3.75021C18.4745 3.82345 18.4995 3.90877 18.4995 3.99998C18.4995 4.2754 18.2754 4.49988 17.9999 4.49988Z"
          fill={fill}
        />
      </svg>
      <div>Плита</div>
    </div>
  );
};
