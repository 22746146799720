import React, { FC } from "react";
import styles from "./ControlSettings.module.scss";

export type ControlSettingsProps = {};

const ControlSettings: FC<ControlSettingsProps> = ({}) => {
  return <div className={styles.title}>Страница в разработке</div>;
};

export default ControlSettings;
