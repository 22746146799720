import React from "react";

export const FilterIcon = ({ width = "24", height = "25", fill = "#1855B7" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >      
      <path d="M18.8933 3.63635H5.10542C3.71264 3.63635 3.01625 3.63635 2.58356 4.04231C2.15088 4.44826 2.15088 5.10163 2.15088 6.40837V7.08773C2.15088 8.10985 2.15088 8.62091 2.40655 9.04457C2.66221 9.46823 3.1293 9.73117 4.06347 10.257L6.93236 11.872C7.55914 12.2248 7.87252 12.4013 8.09691 12.5961C8.5642 13.0018 8.85187 13.4784 8.98222 14.0631C9.04482 14.3438 9.04482 14.6724 9.04482 15.3294V17.9584C9.04482 18.8542 9.04482 19.302 9.29292 19.6513C9.54103 20.0004 9.98169 20.1726 10.863 20.5172C12.7132 21.2405 13.6383 21.6022 14.2961 21.1907C14.9539 20.7792 14.9539 19.839 14.9539 17.9584V15.3294C14.9539 14.6724 14.9539 14.3438 15.0165 14.0631C15.1468 13.4784 15.4345 13.0018 15.9018 12.5961C16.1262 12.4013 16.4396 12.2248 17.0664 11.872L19.9353 10.257C20.8694 9.73117 21.3365 9.46823 21.5922 9.04457C21.8478 8.62091 21.8478 8.10985 21.8478 7.08773V6.40837C21.8478 5.10163 21.8478 4.44826 21.4152 4.04231C20.9825 3.63635 20.2861 3.63635 18.8933 3.63635Z" stroke="#1855B7" strokeWidth="1.47727"/>
    </svg>
  );
};
