import React from "react";

export const ParkingIcon = ({ width = "24", height = "24", fill = "#0F2664" }) => {
  return (
    <div className="feature">
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
          stroke={fill}
          strokeMiterlimit="10"
        />
        <path
          d="M10.4517 8.25977H13.5484C13.7045 8.25977 13.8542 8.32176 13.9645 8.4321C14.0748 8.54244 14.1368 8.6921 14.1368 8.84815V11.9449C14.1368 12.101 14.0748 12.2506 13.9645 12.361C13.8542 12.4713 13.7045 12.5333 13.5484 12.5333H9.86328V8.84815C9.86328 8.6921 9.92527 8.54244 10.0356 8.4321C10.146 8.32176 10.2956 8.25977 10.4517 8.25977Z"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.0693 12.349C10.0693 12.1907 9.95741 12.0625 9.81934 12.0625C9.68127 12.0625 9.56934 12.1907 9.56934 12.349V16.0496C9.56934 16.2078 9.68127 16.336 9.81934 16.336C9.95741 16.336 10.0693 16.2078 10.0693 16.0496V12.349Z"
          fill={fill}
          stroke={fill}
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
      </svg>
      <div>Бесплатная парковка</div>
    </div>
  );
};
