import React from "react";

export const СonditionerIcon = ({ width = "24", height = "24", fill = "#0F2664" }) => {
  return (
    <div className="feature">
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.165 16.3965C10.6714 16.8384 11.3201 17.083 11.9921 17.0855C12.6528 17.0813 13.291 16.8457 13.796 16.4197"
          stroke={fill}
          strokeMiterlimit="10"
        />
        <path
          d="M9.10439 14.4699V14.1448C9.0832 13.5563 9.24439 12.9756 9.56591 12.4822C9.88743 11.9889 10.3536 11.6069 10.9005 11.3887V8.33059L9.6773 7.10736C9.51899 6.94409 9.42666 6.72804 9.41808 6.50078C9.4095 6.27353 9.48528 6.05113 9.63084 5.8764C9.7721 5.71243 9.96957 5.60722 10.1845 5.58144C10.3993 5.55565 10.6161 5.61115 10.7921 5.73704L11.0321 5.94607V4.76156C11.029 4.64843 11.0485 4.53582 11.0895 4.43032C11.1304 4.32481 11.1919 4.22851 11.2705 4.14706C11.3491 4.06561 11.4431 4.00064 11.547 3.95594C11.651 3.91124 11.7628 3.88771 11.876 3.88672C12.1211 3.89079 12.3546 3.99153 12.5257 4.16699C12.6968 4.34245 12.7916 4.57842 12.7895 4.82349V5.93059L12.9986 5.76027C13.0819 5.68941 13.1786 5.63594 13.2829 5.60298C13.3872 5.57002 13.497 5.55824 13.6059 5.56834C13.7148 5.57843 13.8206 5.6102 13.9171 5.66178C14.0135 5.71335 14.0987 5.78369 14.1676 5.86865C14.3077 6.03539 14.3806 6.2484 14.3721 6.46603C14.3635 6.68366 14.274 6.89026 14.1212 7.04543L13.0683 8.11382V11.3887C13.6152 11.6069 14.0813 11.9889 14.4029 12.4822C14.7244 12.9756 14.8856 13.5563 14.8644 14.1448C14.8644 14.2609 14.8644 14.3616 14.8644 14.4777"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.9694 14.5635C12.2858 14.5635 12.5423 14.3001 12.5423 13.9751C12.5423 13.6501 12.2858 13.3867 11.9694 13.3867C11.653 13.3867 11.3965 13.6501 11.3965 13.9751C11.3965 14.3001 11.653 14.5635 11.9694 14.5635Z"
          fill={fill}
          stroke={fill}
          strokeMiterlimit="10"
        />
        <path
          d="M14.8878 14.5078L17.4891 16.0175L19.1226 15.522C19.3392 15.4607 19.5711 15.4852 19.7701 15.5904C19.9691 15.6957 20.1199 15.8736 20.191 16.0872C20.2632 16.2948 20.2575 16.5215 20.1749 16.7252C20.0923 16.9289 19.9384 17.0956 19.742 17.1943L19.4478 17.3027L20.462 17.8678C20.6593 17.9845 20.804 18.1727 20.866 18.3933C20.928 18.6139 20.9025 18.85 20.7949 19.0523C20.7367 19.1579 20.6583 19.2508 20.564 19.3259C20.4698 19.401 20.3616 19.4567 20.2458 19.4898C20.1299 19.5229 20.0087 19.5327 19.889 19.5188C19.7693 19.5048 19.6536 19.4673 19.5485 19.4085L18.6039 18.8588L18.6504 19.1375C18.6894 19.3567 18.6419 19.5826 18.5176 19.7675C18.3934 19.9523 18.2023 20.0817 17.9846 20.1285C17.7727 20.1689 17.5534 20.1275 17.3709 20.0125C17.1884 19.8976 17.0563 19.7177 17.0013 19.5091L16.6143 18.0381L13.8271 16.4356"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.10479 14.5078L6.52673 16.0175L4.89318 15.522C4.67659 15.4607 4.44468 15.4852 4.24569 15.5904C4.04671 15.6957 3.89595 15.8736 3.82479 16.0872C3.75257 16.2948 3.7583 16.5215 3.84091 16.7252C3.92353 16.9289 4.07739 17.0956 4.27382 17.1943L4.56802 17.3027L3.55383 17.8678C3.35489 17.9829 3.20924 18.1718 3.1484 18.3934C3.08755 18.6151 3.11639 18.8518 3.22867 19.0523C3.28529 19.1584 3.36261 19.2519 3.45606 19.3275C3.54951 19.4031 3.65719 19.4592 3.7727 19.4924C3.88822 19.5256 4.00922 19.5353 4.12854 19.5209C4.24787 19.5064 4.36309 19.4682 4.46737 19.4085L5.41189 18.8588L5.36544 19.1375C5.32638 19.3567 5.37396 19.5826 5.49816 19.7675C5.62236 19.9523 5.81349 20.0817 6.03124 20.1285C6.2431 20.1689 6.46241 20.1275 6.64491 20.0125C6.8274 19.8976 6.95948 19.7177 7.01447 19.5091L7.40931 18.0381L10.1887 16.4356"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <div>Кондиционер</div>
    </div>
  );
};
