import React from "react";
import styles from "./CreatingReservationWindow.module.scss";
import ModalWindow from "../ModalWindow";
import NewBookingWrap from "../../Booking/NewBookingWrap";

const CreatingReservationWindow = () => {
  
  return (
    <ModalWindow 
      isLoading={false} 
      isFullCustom 
      hideCross 
      windowClassname={styles.window} 
      isCloseOnOverlay={false}>
        <NewBookingWrap/>      
    </ModalWindow>
  );
};

export default CreatingReservationWindow;
