import React from "react";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  CertainDayPriceStatus,
  CertainDayPriceStatusList,
  CreateCertainDayPricePayload,
  ResponseCertainDayPriceType,
  ResponseCertainDayPriceTypeList,
  ResponsePriceType,
  UpdateCertainDayPricePayload,
  UpdatePricePayload,
} from "../types/pricesTypes";

type PricesState = {
  prices: ResponsePriceType | null;
  certainDayPricesList: ResponseCertainDayPriceTypeList;
  certainDayPriceStatuses: CertainDayPriceStatusList;
};

const initialState: PricesState = {
  prices: null,
  certainDayPricesList: [],
  certainDayPriceStatuses: [],
};

const PricesSlice = createSlice({
  name: "rentalObjectPrices",
  initialState,
  reducers: {
    getPrices(_, __: PayloadAction<number>) {},
    setPrices(state, action: PayloadAction<ResponsePriceType>) {
      state.prices = action.payload;
    },
    updatePrices(_, __: PayloadAction<UpdatePricePayload>) {},
    getCertainDayPrices(_, __: PayloadAction<number>) {},
    setCertainDayPrices(state, action: PayloadAction<ResponseCertainDayPriceTypeList>) {
      state.certainDayPricesList = action.payload;
      state.certainDayPriceStatuses = action.payload.map((item) => ({
        id: item.id,
        isLoading: false,
        isNew: false,
        isSuccess: false,
      }));
    },
    createCertainPrice(_, __: PayloadAction<CreateCertainDayPricePayload>) {},
    addCertianDayPriceInList(state, action: PayloadAction<ResponseCertainDayPriceType>) {
      state.certainDayPricesList = [...state.certainDayPricesList, action.payload];
    },
    removeCertainDayPrice(_, __: PayloadAction<number>) {},
    removeCertainDayPriceFromList(state, action: PayloadAction<number>) {
      state.certainDayPricesList = state.certainDayPricesList.filter(
        (item) => item.id !== action.payload
      );
    },
    updateCertainDayPrice(_, __: PayloadAction<UpdateCertainDayPricePayload>) {},
    updateCertainDayPriceInList(state, action: PayloadAction<ResponseCertainDayPriceType>) {
      const priceIndex = state.certainDayPricesList.findIndex(
        (item) => item.id === action.payload.id
      );
      const pricesCopy = [...state.certainDayPricesList];
      pricesCopy.splice(priceIndex, 1, action.payload);
      state.certainDayPricesList = pricesCopy;
    },
    setCertainDayPricesStatus(state, action: PayloadAction<CertainDayPriceStatus>) {
      const statusIndex = state.certainDayPriceStatuses.findIndex(
        (item) => item.id === action.payload.id
      );
      const statusesCopy = [...state.certainDayPriceStatuses];
      statusesCopy.splice(statusIndex, 1, action.payload);
      state.certainDayPriceStatuses = statusesCopy;
    },
    addCertainDayPricesStatus(state, action: PayloadAction<CertainDayPriceStatus>) {
      state.certainDayPriceStatuses = [...state.certainDayPriceStatuses, action.payload];
    },
    removeCertainDayPricesStatus(state, action: PayloadAction<number>) {
      state.certainDayPriceStatuses = state.certainDayPriceStatuses.filter(
        (item) => item.id !== action.payload
      );
    },
  },
});

export const {
  getPrices,
  setPrices,
  updatePrices,
  getCertainDayPrices,
  setCertainDayPrices,
  createCertainPrice,
  addCertianDayPriceInList,
  removeCertainDayPrice,
  removeCertainDayPriceFromList,
  updateCertainDayPrice,
  updateCertainDayPriceInList,
  setCertainDayPricesStatus,
  addCertainDayPricesStatus,
  removeCertainDayPricesStatus,
} = PricesSlice.actions;

export default PricesSlice.reducer;

export const pricesName = PricesSlice.name;

export const PricesSelectors = {
  getPrices: (state: RootState) => state.rentalObjectPrices.prices,
  getCertainDayPrices: (state: RootState) => state.rentalObjectPrices.certainDayPricesList,
  getCertainDayPriceStatuses: (state: RootState) =>
    state.rentalObjectPrices.certainDayPriceStatuses,
};
