

import styles from "./BookingSectionTitle.module.scss";

type MyProps ={
  title:string
}

const BookingSectionTitle = ({title}:MyProps ) => {
  
  return (
    <>
      <div className={styles.title}>{title}</div>
      <div className={styles.divider}></div>
    </>
    )
}

export default BookingSectionTitle ;
