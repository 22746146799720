import { FC, MutableRefObject } from "react"
import { BookingFormType, BookingType } from "../../../redux/types/bookingTypes"
import classNames from "classnames"
import styles from "./MobileChessBanner.module.scss"
import { AwaitBookingIcon, CalendarIcon2, CloseIcon, ConfirmBookingIcon } from "../../../assets/icons"
import { getCountryFlag } from "../../../utils/getCountryFlag"
import { useDispatch } from "react-redux"
import { closeChessModalWindow } from "../../../redux/reducers/bookingSlice"
import { setModalWindowData } from "../../../redux/reducers/pageSlice"
import { ModalWindowTypes } from "../../../utils/@globalTypes"
import moment from "moment"

type BannerProps = {
  booking:BookingType,
  bookingClassName: string,
  btnRef:MutableRefObject<null>
}

const MobileChessBanner:FC<BannerProps> = ({
  booking,
  bookingClassName,
  btnRef
})=>{
  const dispatch = useDispatch();
  const status = booking.status==="awt" ? "Ожидает подтверждения" : "Подтверждена"
  const flag = getCountryFlag(booking.nationality);
  const roundSumSign:number = 2;
  const total = Number(booking.total).toFixed(roundSumSign) +" BYN";  
  const credit = ((+booking.total) - (+booking.paid)).toFixed(roundSumSign) +" BYN";

  const closeWindow = ()=> {
    dispatch(closeChessModalWindow())
  }
  const openModalWindow=()=>{  
    dispatch(closeChessModalWindow())
    dispatch(setModalWindowData({type: ModalWindowTypes.SHOW_RESERVATION, data:{booking:booking, type:BookingFormType.view}}))        
  }

  return(
    <div className={classNames(styles.container, styles[bookingClassName] )}>
      <div className={styles.header}>
        <div className={styles.statusLine}>
          <div className={styles.statusIcon}>
            {booking.status==="awt" && <AwaitBookingIcon/>}
            {booking.status==="cfm" && <ConfirmBookingIcon/>}
          </div>
          <div className={styles.statusName}>
            {status}
          </div>
        </div>
        <div className={styles.close} onClick={closeWindow} ref={btnRef}>
          <CloseIcon/>
        </div>
      </div>

      <div className={styles.personLine}>
        <div className={styles.flag}>
          {flag({})}
        </div>
        <div className={styles.person}>
          <div>{booking.last_name}</div>
          <div>{booking.first_name}</div>
        </div>
      </div>

      <div className={styles.dates}>
        <div className={styles.dates_left}>
          <CalendarIcon2/>
          <span className={styles.date}>{moment(booking.check_in_date).format('DD.MM.YYYY')}</span>
        </div>
        <div className={styles.dates_right}>
          {" "}&mdash;{" "}
          <span className={styles.date}>{moment(booking.check_out_date).format('DD.MM.YYYY')}</span>
        </div>
      </div>

      <div className={styles.countLine}>
        <div> 
          <span className={styles.countName}>
            Всего: 
          </span>
          <span className={styles.summ}>
            {total} 
          </span>
        </div>
        <div >
          <span className={styles.countName}>
            Долг: 
          </span>
          <span className={styles.summ}>
            {credit} 
          </span>
        </div>
      </div>

      <div className={styles.link} onClick={openModalWindow}>
        Перейти к брони
      </div>
    </div>
  )
}

export default MobileChessBanner