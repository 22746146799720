import React, { FC, ReactNode } from "react";
import styles from "./PagesContainer.module.scss";
import Sidebar from "./Sidebar";
import Header from "./Header";

type PagesContainerProps = {
  children: ReactNode;
};

const PagesContainer: FC<PagesContainerProps> = ({ children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        <Sidebar />
      </div>
      <div className={styles.rightWrapper}>
        <Header />
        <div className={styles.contentWrapper}>{children}</div>
      </div>
    </div>
  );
};

export default PagesContainer;
