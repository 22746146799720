import React from "react";

export const DeleteIcon = ({ width = "24", height = "24", fill = "#8692A6" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9403 3.39039C15.6535 2.54628 14.9086 2 14.0651 2H9.93709L9.78017 2.00633C8.95038 2.07349 8.24229 2.66897 8.02422 3.50533L7.77661 4.76813L7.74759 4.87929C7.62902 5.23928 7.29753 5.48913 6.91889 5.48913H3.73139L3.63214 5.49598C3.27515 5.54564 3 5.85945 3 6.23916C3 6.65338 3.32745 6.98918 3.73139 6.98918L6.91889 6.98918H17.0833L20.2686 6.98918L20.3679 6.98234C20.7248 6.93267 21 6.61887 21 6.23916C21 5.82493 20.6725 5.48913 20.2686 5.48913H17.0833L16.9713 5.48177C16.6037 5.4332 16.2999 5.14872 16.2255 4.76783L15.9886 3.55209L15.9403 3.39039ZM14.9155 5.48913C14.8822 5.40445 14.8534 5.31721 14.8294 5.22768L14.7911 5.06208L14.5648 3.8928C14.5119 3.68991 14.3485 3.54138 14.1514 3.50742L14.0651 3.50006H9.93709C9.73188 3.50006 9.54947 3.62322 9.4737 3.77803L9.448 3.84604L9.21097 5.06238C9.18205 5.21048 9.14016 5.35311 9.08668 5.48913H14.9155ZM18.9767 8.72018C19.3458 8.75069 19.6287 9.05716 19.6483 9.42606L19.6388 9.63174L19.3243 13.483L18.9944 17.2414C18.9246 17.9917 18.8621 18.6245 18.8082 19.1227C18.6208 20.8588 17.4938 21.9323 15.7949 21.9641C13.1477 22.013 10.6031 22.0125 8.13205 21.9591C6.4823 21.9244 5.37199 20.8393 5.18788 19.1297L5.06063 17.8702L4.83827 15.427L4.61049 12.7461L4.35005 9.52788C4.31768 9.11499 4.61784 8.75336 5.02048 8.72016C5.38956 8.68974 5.71663 8.94585 5.79351 9.30678L5.82344 9.60174L6.06799 12.6187L6.33502 15.7459C6.45479 17.0996 6.55867 18.1952 6.64179 18.9648C6.74671 19.939 7.2597 20.4404 8.16244 20.4594C10.6142 20.5124 13.1398 20.5129 15.7684 20.4643C16.726 20.4464 17.2472 19.9499 17.3543 18.9574L17.481 17.7046C17.5181 17.3185 17.5577 16.8923 17.5997 16.4293L17.867 13.3538L18.1889 9.40751C18.2187 9.02902 18.5175 8.73891 18.8773 8.71882L18.9767 8.72018Z"
        fill={fill}
      />
    </svg>
  );
};
