import React from "react";

export const DocumentIcon = ({ width = "28", height = "25", fill = "#ffffff" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6984 16.8994H9.56775"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6984 12.5384H9.56775"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6703 8.1876H9.56787"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9151 2.86437C17.9151 2.86437 9.2698 2.86854 9.25629 2.86854C6.14818 2.88625 4.22363 4.77791 4.22363 7.66333V17.2425C4.22363 20.1425 6.16282 22.0415 9.29796 22.0415C9.29796 22.0415 17.9421 22.0383 17.9567 22.0383C21.0648 22.0206 22.9905 20.1279 22.9905 17.2425V7.66333C22.9905 4.76333 21.0502 2.86437 17.9151 2.86437Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
