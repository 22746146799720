import React from "react";

export const LocationIcon = ({ width = "16", height = "16", fill = "#0F2664" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.0179 1.33329C4.94521 1.32285 2.43543 3.76749 2.33512 6.82749L2.33203 7.01742C2.3831 8.5173 2.89341 9.94705 3.79222 11.125L4.00331 11.4005C4.86123 12.4913 5.88077 13.4488 7.02555 14.238L7.30118 14.4235L7.34139 14.4551C7.73376 14.737 8.26354 14.737 8.65591 14.4551L8.68938 14.4276C9.59774 13.8385 10.4354 13.1463 11.1857 12.3648C12.7111 10.7501 13.6305 8.94075 13.6652 7.10324L13.6653 7.03502C13.6757 3.95453 11.2386 1.43703 8.188 1.33638L8.0179 1.33329ZM8.01378 2.34488C10.5328 2.35349 12.578 4.36618 12.6539 6.87264L12.6561 7.09364C12.6269 8.63419 11.8203 10.2215 10.4557 11.666C9.75996 12.3907 8.97984 13.035 8.13372 13.5829L8.07732 13.6253C8.03175 13.6648 7.96416 13.6648 7.9186 13.6253L7.86379 13.5839C6.60332 12.7609 5.49705 11.7219 4.59558 10.5144C3.86946 9.5627 3.43918 8.42483 3.35257 7.23783L3.33984 6.99981C3.3484 4.47471 5.35489 2.42322 7.85364 2.34705L8.01378 2.34488ZM7.99865 4.93411C6.80639 4.93411 5.83987 5.90361 5.83987 7.09956C5.83987 8.2955 6.80639 9.265 7.99865 9.265C9.19091 9.265 10.1574 8.2955 10.1574 7.09956C10.1574 5.90361 9.19091 4.93411 7.99865 4.93411ZM7.99796 5.94573C8.63324 5.94573 9.14824 6.46232 9.14824 7.09956C9.14824 7.7368 8.63324 8.25339 7.99796 8.25339C7.36268 8.25339 6.84768 7.7368 6.84768 7.09956C6.84768 6.46232 7.36268 5.94573 7.99796 5.94573Z"
        fill={fill}
      />
    </svg>
  );
};
