import React from "react";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { OptionsListType } from "../../utils/@globalTypes";
import { RoomListWithStatusTypeList } from "../../components/RoomCard/@types";
import {
  CreateRoomPayload,
  RentalObjectRoomTypesPayload,
  RoomType,
  RoomTypeList,
  UpdateRoomPayload,
} from "../types/roomTypes";

type RoomState = {
  currentRoomsList: RoomTypeList;
  roomTypes: OptionsListType;
};

const initialState: RoomState = {
  currentRoomsList: [],
  roomTypes: [],
};

const RoomSlice = createSlice({
  name: "rentalObjectRooms",
  initialState,
  reducers: {
    getRentalObjectRoomTypes(_, __: PayloadAction<RentalObjectRoomTypesPayload>) {},
    setRentalObjectRoomTypes(state, action: PayloadAction<OptionsListType>) {
      state.roomTypes = action.payload;
    },
    getCurrentRentalObjectRooms(_, __: PayloadAction<number>) {},
    setCurrentRentalObjectRooms(state, action: PayloadAction<RoomTypeList>) {
      state.currentRoomsList = action.payload;
    },
    saveRooms(_, __: PayloadAction<RoomListWithStatusTypeList>) {},
    createRoom(_, __: PayloadAction<CreateRoomPayload>) {},
    addRoomToList(state, action: PayloadAction<RoomType>) {
      state.currentRoomsList = [...state.currentRoomsList, action.payload];
    },
    updateRoom(_, __: PayloadAction<UpdateRoomPayload>) {},
    updateRoomInList(state, action: PayloadAction<RoomType>) {
      const rooms = state.currentRoomsList;
      const roomIndex = rooms.findIndex((item) => item.id === action.payload.id);
      const roomsCopy = [...state.currentRoomsList];
      roomsCopy.splice(roomIndex, 1, action.payload);
      state.currentRoomsList = roomsCopy;
    },
    removeRoom(_, __: PayloadAction<number>) {},
    removeRoomFromList(state, action: PayloadAction<number>) {
      state.currentRoomsList = state.currentRoomsList.filter((item) => item.id !== action.payload);
    },
  },
});

export const {
  getRentalObjectRoomTypes,
  setRentalObjectRoomTypes,
  getCurrentRentalObjectRooms,
  setCurrentRentalObjectRooms,
  saveRooms,
  createRoom,
  addRoomToList,
  updateRoom,
  updateRoomInList,
  removeRoom,
  removeRoomFromList,
} = RoomSlice.actions;

export default RoomSlice.reducer;

export const roomName = RoomSlice.name;

export const RoomSelectors = {
  getCurrentRentalObjectRooms: (state: RootState) => state.rentalObjectRooms.currentRoomsList,
  getRoomTypes: (state: RootState) => state.rentalObjectRooms.roomTypes,
};
