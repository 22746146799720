import { FC } from "react";

import styles from "./FilterBanner.module.scss";
import { CloseIcon } from "../../../assets/icons";

type BannerProps = {
  title:string,  
  handlerClose:React.Dispatch<React.SetStateAction<boolean>>;
}


const FilterBanner:FC<BannerProps> = ({title, handlerClose}) => {
  const onClose =()=> {
    handlerClose(false);
  }
  return (
    <div className={styles.container}>
      {title}
      <div className={styles.btnClose} onClick={onClose}>
        <CloseIcon/>
      </div>
    </div>

  )
}

export default FilterBanner;