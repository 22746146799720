import { FC } from "react";
import styles from './CheckerboardCell.module.scss';
import CheckerboardBanner from "../CheckerboardBanner";
import classNames from "classnames";
import { BookingType } from "../../redux/types/bookingTypes";
import moment from "moment";
import useWindowDimensions from "../../utils/useWindowDimensions";

type CheckerboardCellProps= {
  itemWidth:number,
  id:number,
  booking:BookingType | undefined,
  longBooking: BookingType | null | undefined,
  index:number,
  day:moment.Moment
}

const CheckerboardCell:FC<CheckerboardCellProps> = ({
 itemWidth,
 id,
 booking,
 longBooking,
 index,
 day
}) => {
  const { width } = useWindowDimensions();

  return(
    <div className={classNames(styles.tableGrid)} id={`id${id}${day.unix()}`}
      style={{
        width:itemWidth                  
      }} 
    >
    {booking && 
      <CheckerboardBanner 
        booking={booking} 
        cellWidth={itemWidth} 
        key={index}
        currentDay={day}
        type="start"
      />}
    {longBooking && 
      <CheckerboardBanner 
        booking={longBooking} 
        cellWidth={itemWidth} 
        key={index}
        currentDay={day}
        type="ending"       
      />}
  </div>    
  )
}

export default CheckerboardCell;