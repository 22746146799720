import React from "react";

export const DishwasherIcon = ({ width = "24", height = "24", fill = "#0F2664" }) => {
  return (
    <div className="feature">
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.8693 0H4.11768C3.2688 0 2.57812 0.690674 2.57812 1.53955V15.6006C2.57812 15.7949 2.7356 15.9521 2.92969 15.9521C3.12396 15.9521 3.28125 15.7949 3.28125 15.6006V5.08887H20.7057V20.1691C20.7057 20.6303 20.3306 21.0055 19.8693 21.0055H4.11768C3.65643 21.0055 3.28125 20.6303 3.28125 20.1691V17.0067C3.28125 16.8124 3.12396 16.6551 2.92969 16.6551C2.73541 16.6551 2.57812 16.8124 2.57812 17.0067V20.1691C2.57812 20.9412 3.14941 21.5823 3.89154 21.692V23.3672C3.89154 23.7162 4.17554 24 4.52435 24H5.75995C6.02216 24 6.26019 23.8352 6.35266 23.5895L6.54565 23.0748H17.3719L17.5649 23.5898C17.6572 23.8352 17.8954 24 18.1575 24H19.4625C19.8115 24 20.0953 23.7162 20.0953 23.3672V21.692C20.8374 21.5823 21.4089 20.9412 21.4089 20.1691V1.53955C21.4089 0.690674 20.7182 0 19.8693 0ZM18.2062 23.2969L17.9899 22.7201C17.9112 22.5115 17.7083 22.3715 17.4853 22.3715H6.43176C6.20911 22.3715 6.00659 22.5115 5.92731 22.721L5.71143 23.2969H4.59467V21.7086H19.3922V23.2969H18.2062ZM3.28125 4.38574V1.53955C3.28125 1.07831 3.65643 0.703125 4.11768 0.703125H19.8693C20.3306 0.703125 20.7057 1.07831 20.7057 1.53955V4.38574H3.28125Z"
          fill={fill}
        />
        <path
          d="M19.6511 9.54645C19.8452 9.54645 20.0026 9.38916 20.0026 9.19489V6.14453C20.0026 5.95044 19.8452 5.79297 19.6511 5.79297H4.33594C4.14185 5.79297 3.98438 5.95044 3.98438 6.14453V19.9518C3.98438 20.1461 4.14185 20.3033 4.33594 20.3033H19.6511C19.8452 20.3033 20.0026 20.1461 20.0026 19.9518V10.6011C20.0026 10.407 19.8452 10.2496 19.6511 10.2496C19.4568 10.2496 19.2995 10.407 19.2995 10.6011V19.6002H4.6875V6.49609H19.2995V9.19489C19.2995 9.38916 19.4568 9.54645 19.6511 9.54645Z"
          fill={fill}
        />
        <path
          d="M13.54 1.4668H10.4463C10.1491 1.4668 9.90723 1.70868 9.90723 2.00586V3.08398C9.90723 3.38116 10.1491 3.62305 10.4463 3.62305H13.54C13.8374 3.62305 14.0791 3.38116 14.0791 3.08398V2.00586C14.0791 1.70868 13.8374 1.4668 13.54 1.4668ZM13.376 2.91992H10.6104V2.16992H13.376V2.91992Z"
          fill={fill}
        />
        <path
          d="M16.2705 2.19336H15.7549C15.5608 2.19336 15.4033 2.35083 15.4033 2.54492C15.4033 2.7392 15.5608 2.89648 15.7549 2.89648H16.2705C16.4648 2.89648 16.6221 2.7392 16.6221 2.54492C16.6221 2.35083 16.4648 2.19336 16.2705 2.19336Z"
          fill={fill}
        />
        <path
          d="M8.23145 2.19336H7.71582C7.52173 2.19336 7.36426 2.35083 7.36426 2.54492C7.36426 2.7392 7.52173 2.89648 7.71582 2.89648H8.23145C8.42572 2.89648 8.58301 2.7392 8.58301 2.54492C8.58301 2.35083 8.42572 2.19336 8.23145 2.19336Z"
          fill={fill}
        />
        <path
          d="M9.79846 10.8247C9.85339 10.6885 9.8371 10.5343 9.75488 10.4124C9.6723 10.2899 9.53479 10.2168 9.38721 10.2168H6.08783C5.94025 10.2168 5.80273 10.2899 5.72015 10.4125C5.63794 10.5345 5.62164 10.6887 5.67676 10.8249C5.97357 11.5584 6.62341 12.0662 7.38586 12.1866V14.0084C6.57983 14.1717 5.97119 14.8858 5.97119 15.7394V17.9327C5.97119 18.2273 6.21069 18.4668 6.50531 18.4668H8.96973C9.26434 18.4668 9.50384 18.2273 9.50384 17.9327V15.7394C9.50384 14.8858 8.8952 14.1717 8.08899 14.0084V12.1866C8.85162 12.0662 9.50146 11.5582 9.79846 10.8247ZM6.53369 10.9199H8.94153C8.65881 11.288 8.21881 11.511 7.73761 11.511C7.25622 11.511 6.81622 11.288 6.53369 10.9199ZM8.80072 15.7394V17.7637H6.67432V15.7394C6.67432 15.1533 7.15131 14.6763 7.73761 14.6763C8.32391 14.6763 8.80072 15.1533 8.80072 15.7394Z"
          fill={fill}
        />
        <path
          d="M14.4538 18.4662C16.5983 18.4662 18.3431 16.7214 18.3431 14.5768C18.3431 12.4321 16.5983 10.6875 14.4538 10.6875C12.3093 10.6875 10.5645 12.4321 10.5645 14.5768C10.5645 16.7214 12.3093 18.4662 14.4538 18.4662ZM14.4538 11.3906C16.2107 11.3906 17.64 12.8199 17.64 14.5768C17.64 16.3337 16.2107 17.7631 14.4538 17.7631C12.6969 17.7631 11.2676 16.3337 11.2676 14.5768C11.2676 12.8199 12.6969 11.3906 14.4538 11.3906Z"
          fill={fill}
        />
        <path
          d="M14.4539 16.3979C15.4573 16.3979 16.2737 15.5815 16.2737 14.5779C16.2737 13.5743 15.4573 12.7578 14.4539 12.7578C13.4503 12.7578 12.6338 13.5743 12.6338 14.5779C12.6338 15.5815 13.4503 16.3979 14.4539 16.3979ZM14.4539 13.4609C15.0696 13.4609 15.5706 13.9619 15.5706 14.5779C15.5706 15.1937 15.0696 15.6946 14.4539 15.6946C13.8379 15.6946 13.3369 15.1937 13.3369 14.5779C13.3369 13.9621 13.8379 13.4609 14.4539 13.4609Z"
          fill={fill}
        />
        <path
          d="M15.3201 7.63867C15.3201 7.4444 15.1627 7.28711 14.9686 7.28711H9.01855C8.82446 7.28711 8.66699 7.4444 8.66699 7.63867C8.66699 7.83276 8.82446 7.99023 9.01855 7.99023H14.9686C15.1627 7.99023 15.3201 7.83276 15.3201 7.63867Z"
          fill={fill}
        />
      </svg>
      <div>Посудомоечная машина</div>
    </div>
  );
};
