import React from "react";

export const CloseIcon = ({ width = "20", height = "20", fill = "#8692A6" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.61977 1.27791C2.24922 0.907364 1.64845 0.907364 1.27791 1.27791C0.907364 1.64845 0.907364 2.24922 1.27791 2.61977L8.65814 10L1.27791 17.3802C0.907365 17.7508 0.907365 18.3515 1.27791 18.7221C1.64846 19.0926 2.24922 19.0926 2.61977 18.7221L10 11.3419L17.3801 18.722C17.7507 19.0925 18.3514 19.0925 18.722 18.722C19.0925 18.3514 19.0925 17.7507 18.722 17.3801L11.3419 10L18.7221 2.61977C19.0926 2.24922 19.0926 1.64845 18.7221 1.27791C18.3515 0.907364 17.7508 0.907364 17.3802 1.27791L10 8.65814L2.61977 1.27791Z"
        fill={fill}
        stroke="white"
        strokeWidth="0.3"
        strokeLinecap="round"
      />
    </svg>
  );
};
