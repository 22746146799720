import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPageData } from "../../redux/reducers/pageSlice";
import { getMainObjectList } from "../../redux/reducers/mainObjectSlice";
import { Navigate, Route, Routes } from "react-router-dom";
import MainObjects from "./MainObjects";
import ObjectEditing from "./ObjectEditing";
import { RoutesList } from "../../App";
import RentalBasicSettings from "./RentalObjectSettings";

const ObjectManagement = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setPageData({
        pageTitle: "Управление объектами",
        pageDescription: "Начиная с базовых настроек до управления доступом",
      })
    );

    return () => {
      dispatch(setPageData(null));
    };
  }, []);

  useEffect(() => {
    dispatch(getMainObjectList({}));
  }, []);

  return (
    <Routes>
      <Route index element={<MainObjects />} />
      <Route
        path={RoutesList.objectManagement.objectEditingChild.path}
        element={<ObjectEditing />}
      />
      <Route
        path={RoutesList.objectManagement.rentalObjectEditingChild.path}
        element={<RentalBasicSettings />}
      />
      <Route
        path={RoutesList.any.path}
        element={<Navigate to={RoutesList.notFound.fullPath} replace />}
      />
    </Routes>
  );
};

export default ObjectManagement;
