import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  addMainObjectInList,
  createMainObject,
  getMainObject,
  getMainObjectList,
  getMainObjectTabsData,
  getMainObjectTypes,
  removeMainObject,
  removeMainObjectFromList,
  setCurrentObject,
  setIsLoadingMainObjectCard,
  setMainObjectList,
  setMainObjectTypes,
  updateMainObject,
  updateMainObjectInList,
} from "../reducers/mainObjectSlice";
import { PayloadAction } from "@reduxjs/toolkit";

import { ApiResponse } from "apisauce";
import API from "../api";
import callCheckingAuth from "./callCheckingAuth";
import { setErrorResponeData } from "../reducers/authSlice";
import {
  CreateObjectPayloadData,
  GetMainObjectsPayload,
  MainObject,
  MainObjectTypesResponseData,
  MainObjectsResponseData,
  ObjectTypesPayload,
  UpdateObjectPayload,
} from "../types/mainObjectTypes";
import { ErrorResponseData, ErrorResponseDetailsData, PayloadWithCallback } from "../types/@types";
import { setLoadersData, setMessage, setModalWindowData } from "../reducers/pageSlice";
import { LoaderTypes, MessageTypes, ModalWindowTypes } from "../../utils/@globalTypes";
import { getRentalObjectListWorker } from "./rentalObjectSaga";
import { getRentalObjectList } from "../reducers/rentalObjectSlice";

function* createObjectWorker(action: PayloadAction<CreateObjectPayloadData>) {
  yield put(setLoadersData({ type: LoaderTypes.CREATE_OBJECT_POPUP, value: true }));

  const { data, callback } = action.payload;

  const {
    ok,
    data: responseData,
    status,
  }: ApiResponse<MainObject, ErrorResponseData> = yield callCheckingAuth(
    API.createMainObjectRequest,
    data
  );

  if (responseData && ok && callback) {
    yield put(addMainObjectInList(responseData));
    yield put(setModalWindowData({ type: ModalWindowTypes.CLOSE }));
    callback(responseData.id);
  } else {
    if (status) {
      if (responseData && !ok && status === 400) {
        yield put(setErrorResponeData(responseData));
      } else {
        yield put(
          setMessage({
            status: MessageTypes.ERROR,
            message: "Ошибка создания объекта",
            code: status,
          })
        );
      }
    }
  }

  yield put(setLoadersData({ type: LoaderTypes.CREATE_OBJECT_POPUP, value: false }));
}

function* getObjectTypesWorker(action: PayloadAction<ObjectTypesPayload>) {
  const { ok, data, status }: ApiResponse<MainObjectTypesResponseData> = yield call(
    API.getMainObjectTypesRequest,
    action.payload
  );

  if (data && ok) {
    const optionsList = data.results.map((item) => {
      return { value: item.id.toString(), label: item.title };
    });
    yield put(setMainObjectTypes({ fullData: data.results, optionsList }));
  } else {
    if (status) {
      yield put(
        setMessage({
          status: MessageTypes.ERROR,
          message: "Ошибка получения типов объекта",
          code: status,
        })
      );
    }
  }
}

function* getMainObjectListWorker(action: PayloadAction<GetMainObjectsPayload>) {
  yield put(setLoadersData({ type: LoaderTypes.MAIN_OBJECTS_PAGE, value: true }));

  const {
    ok,
    data: responseData,
    status,
  }: ApiResponse<MainObjectsResponseData> = yield callCheckingAuth(
    API.getMainObjectListRequest,
    action.payload
  );

  if (responseData && ok) {
    yield put(setMainObjectList(responseData.results));
  } else {
    yield put(setMainObjectList([]));
    if (status) {
      yield put(
        setMessage({
          status: MessageTypes.ERROR,
          message: "Ошибка получения списка объектов",
          code: status,
        })
      );
    }
  }

  yield put(setLoadersData({ type: LoaderTypes.MAIN_OBJECTS_PAGE, value: false }));
}

function* getMainObjectWorker(action: PayloadAction<PayloadWithCallback<string>>) {
  const { callback, data } = action.payload;

  const {
    ok,
    data: responseData,
    status,
  }: ApiResponse<MainObject, ErrorResponseDetailsData> = yield callCheckingAuth(
    API.getMainObjectRequest,
    data
  );

  if (responseData && ok) {
    yield put(setCurrentObject(responseData));
  } else {
    if (status) {
      if (responseData && !ok && status === 404 && callback) {
        callback();
      } else {
        yield put(
          setMessage({
            status: MessageTypes.ERROR,
            message: "Ошибка получения объекта",
            code: status,
          })
        );
      }
    }
  }
}

function* getMainObjectTabsDataWorker(action: PayloadAction<PayloadWithCallback<string>>) {
  yield put(setLoadersData({ type: LoaderTypes.OBJECT_EDITING_PAGE, value: true }));

  yield all([
    getMainObjectWorker(getMainObject(action.payload)),
    getRentalObjectListWorker(getRentalObjectList({ main_object: action.payload.data })),
  ]);

  yield put(setLoadersData({ type: LoaderTypes.OBJECT_EDITING_PAGE, value: false }));
}

function* removeMainObjectWorker(action: PayloadAction<string>) {
  yield put(
    setIsLoadingMainObjectCard({
      id: action.payload,
      value: true,
    })
  );

  const { ok, status }: ApiResponse<undefined> = yield callCheckingAuth(
    API.removeMainObjectRequest,
    action.payload
  );

  if (ok) {
    yield put(removeMainObjectFromList(action.payload));
  } else {
    yield put(
      setIsLoadingMainObjectCard({
        id: action.payload,
        value: false,
      })
    );

    if (status) {
      if (status === 404) {
        yield put(getMainObjectList({}));

        yield put(
          setMessage({
            status: MessageTypes.ERROR,
            message: "Карточка объекта обновлена или удалена",
            code: status,
          })
        );
      } else {
        yield put(
          setMessage({
            status: MessageTypes.ERROR,
            message: "Ошибка удаления карточки объекта",
            code: status,
          })
        );
      }
    }
  }
}

function* updateMainObjectWorker(action: PayloadAction<UpdateObjectPayload>) {
  const { data, id } = action.payload;

  const {
    ok,
    data: responseData,
    status,
  }: ApiResponse<MainObject, ErrorResponseData> = yield callCheckingAuth(
    API.updateMainObjectRequest,
    id,
    data
  );

  if (responseData && ok) {
    yield put(setCurrentObject(responseData));
    yield put(updateMainObjectInList(responseData));
    yield put(
      setMessage({
        status: MessageTypes.POSITIVE,
        message: "Изменения сохранены",
        code: 200,
      })
    );
  } else {
    if (status) {
      if (responseData && !ok && status === 400) {
        yield put(setErrorResponeData(responseData));
      } else {
        yield put(
          setMessage({
            status: MessageTypes.ERROR,
            message: "Ошибка сохранения",
            code: status,
          })
        );
      }
    }
  }
}

export default function* mainObjectSaga() {
  yield all([
    takeLatest(createMainObject, createObjectWorker),
    takeLatest(getMainObjectTypes, getObjectTypesWorker),
    takeLatest(getMainObjectList, getMainObjectListWorker),
    takeLatest(getMainObject, getMainObjectWorker),
    takeLatest(getMainObjectTabsData, getMainObjectTabsDataWorker),
    takeEvery(removeMainObject, removeMainObjectWorker),
    takeLatest(updateMainObject, updateMainObjectWorker),
  ]);
}
