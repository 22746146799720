import React from "react";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { UpdatePricePayload } from "../types/pricesTypes";
import { BookingSettingsType, UpdateBookingSettingsPayload } from "../types/bookingSettingsTypes";

type BookingSettingsState = {
  bookingSettings: BookingSettingsType | null;
};

const initialState: BookingSettingsState = {
  bookingSettings: null,
};

const BookingSettingsSlice = createSlice({
  name: "rentalObjectBookingSettings",
  initialState,
  reducers: {
    getBookingSettings(_, __: PayloadAction<number>) {},
    setBookingSettings(state, action: PayloadAction<BookingSettingsType>) {
      state.bookingSettings = action.payload;
    },
    updateBookingSettings(_, __: PayloadAction<UpdateBookingSettingsPayload>) {},
  },
});

export const { getBookingSettings, setBookingSettings, updateBookingSettings } =
  BookingSettingsSlice.actions;

export default BookingSettingsSlice.reducer;

export const bookingSettingsName = BookingSettingsSlice.name;

export const BookingSettingsSelectors = {
  getBookingSettings: (state: RootState) => state.rentalObjectBookingSettings.bookingSettings,
};
