import React, { FC, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ButtonType,
  InputModeType,
  InputType,
  FieldTypes,
  TelephoneType,
  AddressType,
} from "../../../utils/@globalTypes";
import Input from "../../../components/Input";
import SelectComponent from "../../../components/SelectComponent";
import Button from "../../../components/Button";
import { banDoubleSpace, getErrorText, setFieldErrorText } from "../../../utils/functions";

import {
  FIRST_NAME_REQ,
  LAST_NAME_REQ,
  MIDDLE_NAME_REQ,
  TaxPayerNumberList,
  IND_TAXPAYER_NUMBER_REQ,
  ADDRESS_REQ,
  LOCALITY_REQ,
  REGION_REQ,
  REGION_ERROR,
  LOCALITY_ERROR,
  ADDRESS_ERROR,
  TAXPAYER_NUMBER_ERROR,
  FIRST_NAME_ERROR,
  LAST_NAME_ERROR,
  MIDDLE_NAME_ERROR,
} from "../../../utils/constants";
import IntlTelInput from "../../../components/IntlTelInput";
import { UserSelectors, updateIndividualInfo } from "../../../redux/reducers/userSlice";

import styles from "./IndividualSettings.module.scss";
import { RoutesList } from "../../../App";
import { UpdateIndividualData } from "../../../redux/types/userTypes";
import { MainObjectSelectors } from "../../../redux/reducers/mainObjectSlice";
import { AuthSelectors, setErrorResponeData } from "../../../redux/reducers/authSlice";

type IndividualSettingsType = {
  id?: number;
};

const IndividualSettings: FC<IndividualSettingsType> = ({ id }) => {
  const dispatch = useDispatch();
  const individual = useSelector(UserSelectors.getIndividualInfo);
  const countriesList = useSelector(MainObjectSelectors.getCountriesOptions);
  const errorResponseData = useSelector(AuthSelectors.getErrorResponseData);

  const [firstName, setFirstName] = useState("");
  const [surName, setSurname] = useState("");
  const [middleName, setMiddlename] = useState("");
  const [country, setCountry] = useState("");
  const [taxpayerNumber, setTaxpayerNumber] = useState("");
  const [taxpayerPlaceholder, setTaxpayerPlaceholder] = useState("Введите УНП");
  const [adressArea, setAdressarea] = useState("");
  const [adressTown, setAdresstown] = useState("");
  const [adressStreet, setAdressstreet] = useState("");

  const [firstNameError, setFirstNameError] = useState("");
  const [surnameError, setSurnameError] = useState("");
  const [middlenameError, setMiddlenameError] = useState("");
  const [telephoneError, setTelephoneError] = useState("");
  const [taxpayernumberError, setTaxpayernumberError] = useState("");
  const [adressAreaError, setAdressareaError] = useState("");
  const [adressTownError, setAdresstownError] = useState("");
  const [adresStreetError, setAdresstreetError] = useState("");

  const [nameTouched, setNameTouched] = useState(false);
  const [surnameTouched, setSurnameTouched] = useState(false);
  const [middlenameTouched, setMiddlenameTouched] = useState(false);
  const [telephoneTouched, setTelephoneTouched] = useState(false);
  const [taxpayerNumberTouched, setTaxpayerNumberTouched] = useState(false);
  const [adressareaTouched, setAdressareaTouched] = useState(false);
  const [adresstownTouched, setAdresstownTouched] = useState(false);
  const [adresstreetTouched, setAdresstreetTouched] = useState(false);

  const [telephone, setTelephone] = useState<TelephoneType>({
    country_code: "",
    phone_code: "",
    phone_number: "",
  });
  const [isTelephoneValid, setIsTelephoneValid] = useState(false);

  const onConfirmBtnClick = () => {
    setNameTouched(true);
    setSurnameTouched(true);
    setMiddlenameTouched(true);
    setTelephoneTouched(true);
    setAdressareaTouched(true);
    setAdresstownTouched(true);
    setAdresstreetTouched(true);
    setTaxpayerNumberTouched(true);

    if (
      !!individual &&
      !firstNameError &&
      !surnameError &&
      !middlenameError &&
      !telephoneError &&
      !adressAreaError &&
      !adressTownError &&
      !adresStreetError &&
      !taxpayernumberError &&
      firstName.length > 0 &&
      surName.length > 0 &&
      adressArea.length > 0 &&
      adressTown.length > 0 &&
      adressStreet.length > 0 &&
      taxpayerNumber.length > 0
    ) {
      const {
        first_name,
        last_name,
        middle_name,
        country: countryRes,
        unp,
        registration_address,
        phone,
      } = individual;

      const datas: UpdateIndividualData = {};

      first_name !== firstName && (datas.first_name = firstName);
      last_name !== surName && (datas.last_name = surName);
      middle_name !== middleName && (datas.middle_name = middleName);
      countryRes !== country && (datas.country = country);
      unp !== taxpayerNumber && (datas.unp = taxpayerNumber);
      if (
        registration_address.region !== adressArea ||
        registration_address.locality !== adressTown ||
        registration_address.address !== adressStreet
      ) {
        const newIndivAddress: AddressType = {
          region: adressArea,
          locality: adressTown,
          address: adressStreet,
        };
        datas.registration_address = newIndivAddress;
      }

      if (
        (phone &&
          (phone.country_code !== telephone.country_code ||
            phone.phone_code !== telephone.phone_code ||
            phone.phone_number !== telephone.phone_number)) ||
        (!phone && telephone.phone_number.length > 0)
      ) {
        datas.phone = telephone.phone_number.length > 0 ? telephone : null;
      }

      id && dispatch(updateIndividualInfo({ id, data: datas }));
    }
  };

  useLayoutEffect(() => {
    if (individual) {
      const {
        first_name,
        last_name,
        middle_name,
        country: countryRes,
        unp,
        registration_address: { region, locality, address },
      } = individual;

      first_name !== null && setFirstName(first_name);
      last_name !== null && setSurname(last_name);
      middle_name !== null && setMiddlename(middle_name);
      countryRes !== null && setCountry(countryRes);
      unp !== null && setTaxpayerNumber(unp);
      region !== null && setAdressarea(region);
      locality !== null && setAdresstown(locality);
      address !== null && setAdressstreet(address);
    }
  }, [individual]);

  useEffect(() => {
    const choice = TaxPayerNumberList.find((item) => item.value === country);
    setTaxpayerPlaceholder(choice?.label || "Введите УНП");
  }, [country]);

  useEffect(() => {
    if (errorResponseData) {
      errorResponseData.first_name &&
        setFirstNameError(
          getErrorText(errorResponseData.first_name[0], FIRST_NAME_ERROR, FieldTypes.FIRST_NAME)
        );

      errorResponseData.last_name &&
        setSurnameError(
          getErrorText(errorResponseData.last_name[0], LAST_NAME_ERROR, FieldTypes.LAST_NAME)
        );

      errorResponseData.middle_name &&
        setMiddlenameError(
          getErrorText(errorResponseData.middle_name[0], MIDDLE_NAME_ERROR, FieldTypes.MIDDLE_NAME)
        );

      errorResponseData.unp &&
        setTaxpayernumberError(
          getErrorText(
            errorResponseData.unp[0],
            TAXPAYER_NUMBER_ERROR,
            FieldTypes.IND_TAXPAYER_NUMBER
          )
        );

      errorResponseData.region &&
        setAdressareaError(
          getErrorText(errorResponseData.region[0], REGION_ERROR, FieldTypes.REGION)
        );

      errorResponseData.locality &&
        setAdresstownError(
          getErrorText(errorResponseData.locality[0], LOCALITY_ERROR, FieldTypes.LOCALITY)
        );

      errorResponseData.address &&
        setAdresstreetError(
          getErrorText(errorResponseData.address[0], ADDRESS_ERROR, FieldTypes.ADDRESS)
        );

      dispatch(setErrorResponeData(null));
    }
  }, [errorResponseData]);

  // Name

  useEffect(() => {
    setFieldErrorText(nameTouched, firstName, setFirstNameError, FieldTypes.FIRST_NAME, true);
  }, [nameTouched, firstName]);

  // Surname

  useEffect(() => {
    setFieldErrorText(surnameTouched, surName, setSurnameError, FieldTypes.LAST_NAME, true);
  }, [surnameTouched, surName]);

  // Middlename

  useEffect(() => {
    setFieldErrorText(
      middlenameTouched,
      middleName,
      setMiddlenameError,
      FieldTypes.MIDDLE_NAME,
      false
    );
  }, [middlenameTouched, middleName]);

  // TaxpayerNumber

  useEffect(() => {
    setFieldErrorText(
      taxpayerNumberTouched,
      taxpayerNumber,
      setTaxpayernumberError,
      FieldTypes.IND_TAXPAYER_NUMBER,
      true
    );
  }, [taxpayerNumberTouched, taxpayerNumber]);

  // Telephone

  useEffect(() => {
    setFieldErrorText(
      telephoneTouched,
      telephone.phone_number,
      setTelephoneError,
      FieldTypes.TELEPHONE,
      false,
      isTelephoneValid
    );
  }, [telephoneTouched, telephone.phone_number, isTelephoneValid]);

  // Address

  useEffect(() => {
    setFieldErrorText(adressareaTouched, adressArea, setAdressareaError, FieldTypes.REGION, true);
  }, [adressareaTouched, adressArea]);

  useEffect(() => {
    setFieldErrorText(adresstownTouched, adressTown, setAdresstownError, FieldTypes.LOCALITY, true);
  }, [adresstownTouched, adressTown]);

  useEffect(() => {
    setFieldErrorText(
      adresstreetTouched,
      adressStreet,
      setAdresstreetError,
      FieldTypes.ADDRESS,
      true
    );
  }, [adresstreetTouched, adressStreet]);

  const isValid = useMemo(() => {
    return (
      !firstNameError &&
      !surnameError &&
      !middlenameError &&
      !telephoneError &&
      !taxpayernumberError &&
      !adressAreaError &&
      !adressTownError &&
      !adresStreetError
    );
  }, [
    firstNameError,
    surnameError,
    middlenameError,
    telephoneError,
    taxpayernumberError,
    adressAreaError,
    adressTownError,
    adresStreetError,
  ]);

  const isFieldsChanged = useMemo(() => {
    if (!!individual) {
      const {
        first_name,
        last_name,
        middle_name,
        country: countryRes,
        unp,
        registration_address,
        phone,
      } = individual;
      return (
        first_name !== firstName ||
        last_name !== surName ||
        middle_name !== middleName ||
        countryRes !== country ||
        unp !== taxpayerNumber ||
        registration_address.region !== adressArea ||
        registration_address.locality !== adressTown ||
        registration_address.address !== adressStreet ||
        (phone &&
          (phone.country_code !== telephone.country_code ||
            phone.phone_code !== telephone.phone_code ||
            phone.phone_number !== telephone.phone_number)) ||
        (!phone && telephone.phone_number.length > 0)
      );
    }
  }, [
    individual,
    firstName,
    surName,
    telephone,
    middleName,
    country,
    taxpayerNumber,
    adressArea,
    adressTown,
    adressStreet,
  ]);

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <h3 className={styles.title}>Данные физического лица</h3>
        <Input
          id="lastName"
          value={surName}
          title="Фамилия*"
          name="lastName"
          placeholder="Введите фамилию"
          type={InputType.TEXT}
          inputMode={InputModeType.TEXT}
          errText={surnameError}
          onChange={setSurname}
          onBlur={setSurnameTouched}
          requirementsText={LAST_NAME_REQ}
          banSymbols={/\s/g}
        />
        <Input
          id="firstName"
          value={firstName}
          title="Имя*"
          name="firstName"
          placeholder="Введите имя"
          type={InputType.TEXT}
          inputMode={InputModeType.TEXT}
          errText={firstNameError}
          onChange={setFirstName}
          autoComplete="given-name"
          onBlur={setNameTouched}
          requirementsText={FIRST_NAME_REQ}
          banSymbols={/\s/g}
        />
        <Input
          id="middleName"
          value={middleName}
          title="Отчество"
          name="middleName"
          placeholder="Введите отчество"
          type={InputType.TEXT}
          inputMode={InputModeType.TEXT}
          errText={middlenameError}
          onChange={setMiddlename}
          onBlur={setMiddlenameTouched}
          requirementsText={MIDDLE_NAME_REQ}
          banSymbols={/\s/g}
        />
        <IntlTelInput
          id="MobilePhone"
          title="Мобильный телефон"
          value={telephone}
          placeholder="Введите номер телефона"
          onChange={setTelephone}
          errText={telephoneError}
          setError={setTelephoneError}
          onBlur={setTelephoneTouched}
          setIsValidate={setIsTelephoneValid}
          preloadedData={individual?.phone}
        />
        <Input
          id="taxpayerNumber"
          value={taxpayerNumber}
          title="Номер налогоплательщика*"
          name="taxpayerNumber"
          placeholder={taxpayerPlaceholder}
          type={InputType.TEXT}
          inputMode={InputModeType.TEXT}
          errText={taxpayernumberError}
          onChange={setTaxpayerNumber}
          onBlur={setTaxpayerNumberTouched}
          requirementsText={IND_TAXPAYER_NUMBER_REQ}
          banSymbols={/\s/g}
        />
        <h3 className={styles.title}>Адрес регистрации (по паспорту)</h3>
        <Input
          id="adress_area"
          value={adressArea}
          title="Административное деление*"
          name="place_area"
          placeholder="Административное деление"
          type={InputType.TEXT}
          inputMode={InputModeType.TEXT}
          errText={adressAreaError}
          onChange={setAdressarea}
          onBlur={setAdressareaTouched}
          requirementsText={REGION_REQ}
          customBanSymbols={banDoubleSpace}
          autoComplete="address-level1"
        />
        <Input
          id="adress_town"
          value={adressTown}
          title="Населенный пункт*"
          name="adress_town"
          placeholder="Населенный пункт"
          type={InputType.TEXT}
          inputMode={InputModeType.TEXT}
          errText={adressTownError}
          onChange={setAdresstown}
          onBlur={setAdresstownTouched}
          requirementsText={LOCALITY_REQ}
          customBanSymbols={banDoubleSpace}
          autoComplete="address-level2"
        />
        <Input
          id="adress_street"
          value={adressStreet}
          title="Адрес*"
          name="adress_street"
          placeholder="Адрес"
          type={InputType.TEXT}
          inputMode={InputModeType.TEXT}
          errText={adresStreetError}
          onChange={setAdressstreet}
          onBlur={setAdresstreetTouched}
          requirementsText={ADDRESS_REQ}
          customBanSymbols={banDoubleSpace}
          autoComplete="street-address"
        />
        <SelectComponent
          title="Страна*"
          optionsList={countriesList}
          currentValue={country}
          isSearchable={true}
          setSelecValue={setCountry}
          defaultValueId={0}
        />
      </div>

      <div className={styles.btnFormWrap}>
        <Button
          title="Сохранить"
          type={ButtonType.PRIMARY_SMALL}
          onClick={onConfirmBtnClick}
          className={styles.btnConfirm}
          disabled={!isValid || !isFieldsChanged}
        />
        <Button
          title="Отмена"
          type={ButtonType.SECONDARY_SMALL}
          path={RoutesList.checkerboard.fullPath}
          className={styles.btnCancel}
        />
      </div>
    </div>
  );
};

export default IndividualSettings;
