import React from "react";

export const GrillIcon = ({ width = "24", height = "24", fill = "#0F2664" }) => {
  return (
    <div className="feature">
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.12821 16.9863L7.12305 20.0057"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.1123 16.9863L17.1175 20.0057"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.7043 11.9531C18.7043 14.0512 17.1559 17.0318 12.1236 17.0318C7.47845 17.0318 5.54297 14.0512 5.54297 11.9531"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.1236 13.2468C15.758 13.2468 18.7043 12.5917 18.7043 11.7835C18.7043 10.9754 15.758 10.3203 12.1236 10.3203C8.48922 10.3203 5.54297 10.9754 5.54297 11.7835C5.54297 12.5917 8.48922 13.2468 12.1236 13.2468Z"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.124 18.8822V17.3105"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.9549 10.7227L9.5459 12.8517"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.7256 10.8086L13.2236 13.0847"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.9756 12.4882C19.5795 12.9605 20.2298 13.0689 20.524 12.7979C20.6249 12.7177 20.6958 12.6059 20.7253 12.4805"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.36491 12.4882C4.76104 12.9605 4.11072 13.0689 3.81653 12.7979C3.71564 12.7177 3.64473 12.6059 3.61523 12.4805"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <div>Мангал</div>
    </div>
  );
};
