import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setModalWindowData, setPageData } from "../../redux/reducers/pageSlice";
import PriceCard from "../../components/PriceCard";
import { tariffs } from "../../components/PriceCard/tarifDatas";


import styles from "./Prices.module.scss";

const Prices = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setPageData({
        pageTitle: "Информация о тарифах",
        pageDescription: "Настройки тарифного плана",
      })
    );

    return () => {
      dispatch(setPageData(null));
    };
  }, []);
  
  const cards = tariffs.map(item=>{
    return(
      <PriceCard tarif = {item}/>
    )
  })

  return (
    <div className={styles.container}>
      Скоро здесь будут тарифы
    </div>
  );
};

export default Prices;