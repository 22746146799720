import React, { FC, ReactNode } from "react";
import styles from "./Tabs.module.scss";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { TabsNames } from "../../utils/@globalTypes";
import { TabListType } from "./@types";

type TabsProps = {
  children: ReactNode;
  tabsList: TabListType;
  activeTab?: TabsNames;
  className?: string;
  containerClassName?: string;
};

const Tabs: FC<TabsProps> = ({ children, tabsList, activeTab, className, containerClassName }) => {
  return (
    <>
      <div className={classNames(styles.tabsWrapper, containerClassName)}>
        {tabsList.map(({ path, title }) => {
          return (
            <Link
              to={path}
              className={classNames(styles.tab, className, {
                [styles.activeTab]: activeTab === path,
              })}
              key={path}
            >
              {title}
            </Link>
          );
        })}
      </div>
      <div
        className={classNames(styles.contentWrapper, {
          [styles.firstTabActive]: tabsList[0].path === activeTab,
          [styles.lastTabActive]: tabsList[tabsList.length - 1].path === activeTab,
        })}
      >
        {children}
      </div>
    </>
  );
};

export default Tabs;
