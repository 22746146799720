import React, { FC, useEffect, useRef, useState } from "react";
import Select, {
  ActionMeta,
  components,
  DropdownIndicatorProps,
  InputActionMeta,
  OnChangeValue,
} from "react-select";
import "./SelectComponent.scss";
import classNames from "classnames";
import { OptionType, OptionsListType } from "../../utils/@globalTypes";
import { Arrow2Icon } from "../../assets/icons";

type SelectComponentProps = {
  title?: string;
  placeholder?: string;
  isDisabled?: boolean;
  optionsList?: OptionsListType | null;
  currentValue: string;
  setSelecValue: (value: any) => void;
  defaultValueId?: number;
  isSearchable?: boolean;
  isClearable?: boolean;
  errText?: string;
  onBlur?: (value: boolean) => void;
  isLoading?: boolean;
  isBooking?: boolean;
  isFocus?: boolean;
  maxMenuHeight?: number; // Максимальная высота открывающегося меню
  // data?: any;
  name?:string,
  id?:string  
};

const SelectComponent: FC<SelectComponentProps> = ({
  title,
  placeholder,
  isDisabled,
  optionsList,
  currentValue,
  setSelecValue,
  isSearchable,
  defaultValueId,
  isClearable,
  errText,
  isLoading,
  onBlur,
  isFocus,
  isBooking, //признак использования компонента для просмотра/редактирования/создания брони. Отдельная стилизация
  maxMenuHeight,
  name,
  id  
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const DropdownIndicator = (props: DropdownIndicatorProps<OptionType, true>) => {
    return (
      <components.DropdownIndicator {...props}>
        <Arrow2Icon />
      </components.DropdownIndicator>
    );
  };

  const onBlurSelect = () => {
   onBlur && onBlur(true);
  };

  const getValue = () => {
    return currentValue && optionsList
      ? optionsList.find((option) => option.value === currentValue)
      : null;
  };

  const onChange = (newValue: OnChangeValue<OptionType, boolean>) => {
    setSelecValue(newValue ? (newValue as OptionType).value : "");
    return newValue;
  };

  useEffect(() => {
    defaultValueId !== undefined && optionsList && onChange(optionsList[defaultValueId]);
  }, [defaultValueId]);

  return (
    <div>
      {title && <p className={classNames(isBooking && "small", "customSelect__title")}>{title}</p>}
      <div
        className={classNames(
          "customSelect__wrapper",
          {
            "customSelect-disabled": isDisabled,
          },
          isBooking && "customSelect-small"
        )}
      >
        <Select
          components={{ DropdownIndicator }}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: "#FFFFFF",
              color: "#0f2664",
              minHeight: "33px",
              height: isBooking ? "33px" : undefined,
              border: errText
                ? "1px solid #b91212"
                : errText && state.isFocused
                ? "1px solid #b91212"
                : state.isFocused || (currentValue.length > 0 && !isBooking)
                ? "1px solid #1855b7"
                : isBooking
                ? "0.25px solid rgb(105, 111, 121, 0.5)"
                : "1px solid #696f79",
              boxShadow: state.isFocused ? "0px 4px 7px 3px rgba(24, 85, 183, 0.11)" : "0",
              transition: "0.3s",
            }),
            option: (baseStyles, { data, isFocused }) => ({
              ...baseStyles,
              color: data.color ? data.color : isFocused ? "#0a3a86" : "#696f79",
              fontSize: isBooking ? "14px" : undefined,
            }),
            noOptionsMessage: (baseStyles) => ({
              ...baseStyles,
              padding: "5px 10px",
              color: "#696f79",
            }),
            singleValue: (styles, { data }) => ({
              ...styles,
              color: data.color,
            }),
            dropdownIndicator: (baseStyles) => ({
              ...baseStyles,
              transform: isMenuOpen ? "rotate(180deg)" : "rotate(0)",
            }),
            menu: (baseStyles) => ({
              ...baseStyles,
              borderColor: errText ? "#b91212" : "#1855b7",
            }),
          }}
          onChange={onChange}
          value={getValue()}
          className="customSelect"
          classNamePrefix="customSelect"
          isClearable={isClearable}
          isSearchable={isSearchable || false}
          options={optionsList !== null ? optionsList : undefined}
          placeholder={placeholder || "Выберите из списка"}
          isDisabled={isDisabled}
          unstyled
          noOptionsMessage={() => "Нет вариантов"}
          isLoading={isLoading}
          onBlur={onBlurSelect}
          onMenuOpen={() => {
            setTimeout(() => setIsMenuOpen(true), 0);
          }}
          onMenuClose={() => setIsMenuOpen(false)}
          // menuPlacement="auto"
          maxMenuHeight={maxMenuHeight ?? 105}
          // menuPosition={"fixed"}
          autoFocus={isFocus}
          name={name}
          id={id}
        />
        {errText && (
          <p className={classNames(isBooking && "small", "customSelect__errText")}>{errText}</p>
        )}
      </div>
    </div>
  );
};

export default SelectComponent;
