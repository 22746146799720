import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ModalWindowTypes } from "../utils/@globalTypes";
import { PageSelectors, setModalWindowData } from "../redux/reducers/pageSlice";
import CreatingMainObjectWindow from "../components/ModalWindow/CreatingMainObjectWindow";
import ActivateAccountWindow from "../components/ModalWindow/ActivateAccountWindow";
import CreatingRentalObjectWindow from "../components/ModalWindow/CreatingRentalObjectWindow";
import ShowReservationWindow from "../components/ModalWindow/ShowReservationWindow";
import CreatingReservationWindow from "../components/ModalWindow/CreatingReservationWindow";

const ModalWindowProvider = () => {
  const dispatch = useDispatch();

  const modalWindowType = useSelector(PageSelectors.getModalWindowData("type"));
  const modalWindowData = useSelector(PageSelectors.getModalWindowData("data"));

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const currentModalWindowType = searchParams.get("popup");

    if (modalWindowType) {
      if (modalWindowType !== ModalWindowTypes.CLOSE) {
        setSearchParams({ popup: modalWindowType });
        dispatch(setModalWindowData({ type: null, data: modalWindowData }));
      } else if (modalWindowType === ModalWindowTypes.CLOSE && currentModalWindowType) {
        const filteredParams = Object.fromEntries(
          [...searchParams.entries()].filter((item) => item[0] !== "popup")
        );

        setSearchParams(filteredParams);
      }
    }
  }, [modalWindowType, searchParams, modalWindowData]);

  switch (searchParams.get("popup")) {
    case ModalWindowTypes.CREATE_MAIN_OBJECT:
      return <CreatingMainObjectWindow />;
    case ModalWindowTypes.ACTIVATE_ACCOUNT:
      return <ActivateAccountWindow />;
    case ModalWindowTypes.CREATE_RENTAL_OBJECT:
      return <CreatingRentalObjectWindow />;
    case ModalWindowTypes.CREATE_RESERVATION:
      return <CreatingReservationWindow />;
    case ModalWindowTypes.SHOW_RESERVATION:
      return <ShowReservationWindow data={modalWindowData}/>;
    default:
      return null;
  }
};

export default ModalWindowProvider;
