
import { AwaitBookingIcon, ConfirmBookingIcon } from "../../../assets/icons";
import styles from "./HelpPanel.module.scss";

const HelpPanel = () => {

  return(
    <div className={styles.container}>
      <div> <AwaitBookingIcon width="20" height="20"/> Ожидает подтверждения </div>
      <div> <ConfirmBookingIcon width="20" height="20"/> Бронь подтверждена </div>
    </div>
  )
}

export default HelpPanel