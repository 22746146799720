import React from "react";

export const PierIcon = ({ width = "24", height = "24", fill = "#0F2664" }) => {
  return (
    <div className="feature">
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_5382_44236)">
          <path
            d="M4.81836 7.63867H19.2496"
            stroke={fill}
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M3.18848 10.2285H20.8795"
            stroke={fill}
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M6.04094 14.8317C6.78797 14.1425 8.26505 13.7802 9.10546 14.4783C9.24128 14.5666 9.27524 14.5931 9.41955 14.6285C9.95436 14.7345 10.5061 14.549 11.0834 14.3899C11.3126 14.3192 11.5588 14.4606 11.6267 14.708C11.6776 14.8936 11.6097 15.088 11.4739 15.194C11.0919 15.4945 10.6674 15.7684 10.1496 15.9363C9.60631 16.1219 8.8423 16.1219 8.34994 15.7684C8.10376 15.6093 8.0783 15.574 7.95945 15.5121C7.4586 15.2912 6.90682 15.3884 6.3041 15.468C5.96454 15.5298 5.76081 15.035 6.04094 14.8229V14.8317Z"
            fill={fill}
          />
          <path
            d="M7.19524 8.0018L6.88964 11.4039C6.85568 11.8634 6.17657 11.8545 6.1511 11.4039L5.8455 8.0018C5.78607 6.9856 7.26315 6.97676 7.20373 8.0018H7.19524Z"
            fill={fill}
          />
          <path
            d="M9.95403 8.0018L9.64843 11.4039C9.61447 11.8634 8.93536 11.8545 8.90989 11.4039L8.60429 8.0018C8.54486 6.9856 10.0219 6.97676 9.96252 8.0018H9.95403Z"
            fill={fill}
          />
          <path
            d="M12.7128 8.0018L12.4072 11.4039C12.3733 11.8634 11.6941 11.8545 11.6687 11.4039L11.3631 8.0018C11.3037 6.9856 12.7807 6.97676 12.7213 8.0018H12.7128Z"
            fill={fill}
          />
          <path
            d="M15.4638 8.0018L15.1582 11.4039C15.1242 11.8634 14.4451 11.8545 14.4197 11.4039L14.1141 8.0018C14.0546 6.9856 15.5317 6.97676 15.4723 8.0018H15.4638Z"
            fill={fill}
          />
          <path
            d="M18.2226 8.0018L17.917 11.4039C17.883 11.8634 17.2039 11.8545 17.1784 11.4039L16.8728 8.0018C16.8134 6.9856 18.2905 6.97676 18.2311 8.0018H18.2226Z"
            fill={fill}
          />
          <path
            d="M18.4771 16.9088C17.9762 16.9088 17.5263 16.8734 17.1189 17.059C16.8727 17.2269 16.3548 17.4655 15.9983 17.4301C15.4211 17.4478 13.6214 16.9088 14.0798 16.1135C14.1817 15.9633 14.3599 15.8926 14.5297 15.9279C15.056 15.9986 15.7521 16.1577 16.1511 15.8837C16.253 15.8131 16.5925 15.6628 16.8472 15.6452C17.3735 15.5391 19.1817 16.034 18.7827 16.7586C18.7063 16.8558 18.5959 16.9088 18.4856 16.9088H18.4771Z"
            fill={fill}
          />
          <path
            d="M18.9351 3.70703H5.06411C3.74669 3.70703 2.67871 4.81874 2.67871 6.19009V17.8101C2.67871 19.1815 3.74669 20.2932 5.06411 20.2932H18.9351C20.2525 20.2932 21.3205 19.1815 21.3205 17.8101V6.19009C21.3205 4.81874 20.2525 3.70703 18.9351 3.70703Z"
            stroke={fill}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_5382_44236">
            <rect width="20" height="18" fill="white" transform="translate(2 3)" />
          </clipPath>
        </defs>
      </svg>
      <div>Личный пирс</div>
    </div>
  );
};
