import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import styles from "./PlacementSettings.module.scss";
import Button from "../../../../components/Button";
import { ButtonType, TabsNames } from "../../../../utils/@globalTypes";
import RoomCard from "../../../../components/RoomCard";
import { useDispatch, useSelector } from "react-redux";
import { RoomListWithStatusTypeList } from "../../../../components/RoomCard/@types";
import { useParams } from "react-router-dom";
import { RoutesList } from "../../../../App";
import { RoomSelectors, saveRooms } from "../../../../redux/reducers/roomSlice";

const PlacementSettings = () => {
  const dispatch = useDispatch();
  const { mainId, rentalId } = useParams();

  const currentRentalObjectRooms = useSelector(RoomSelectors.getCurrentRentalObjectRooms);

  const [roomList, setRoomList] = useState<RoomListWithStatusTypeList>([]);
  const [newRoomList, setNewRoomList] = useState<RoomListWithStatusTypeList>([]);
  const [isErrors, setIsErrors] = useState<boolean[]>([]);
  const [isSave, setIsSave] = useState(false);

  const onSaveObjectBtnClick = () => {
    setIsSave(true);
  };

  useEffect(() => {
    if (isSave && newRoomList.length) {
      if (
        !newRoomList.some((room) => room.isValid === false && !room.isDelete) &&
        !newRoomList.some((room) => room.room.type.length === 0 && !room.isDelete) &&
        JSON.stringify(currentRentalObjectRooms) !==
          JSON.stringify(newRoomList.filter((item) => !item.isDelete).map((item) => item.room))
      ) {
        dispatch(saveRooms(newRoomList));
      }
      setIsSave(false);
      setRoomList(newRoomList);
      setNewRoomList([]);
    }
  }, [isSave, newRoomList]);

  const onAddRoomBtnClick = () => {
    if (rentalId) {
      setRoomList((prevState) => [
        ...prevState,
        {
          isValid: true,
          isNew: true,
          isDelete: false,
          isUpdate: false,
          room: {
            id: prevState.length * Math.random(),
            type: "",
            beds: {
              double_bed: 0,
              single_bed: 0,
              children_bed: 0,
              extra_sleeping_place: 0,
            },
            name: "",
            rental_object: +rentalId,
          },
        },
      ]);
    }
  };

  useLayoutEffect(() => {
    setRoomList(
      currentRentalObjectRooms.map((item) => ({
        isValid: true,
        isNew: false,
        isDelete: false,
        isUpdate: false,
        room: item,
      }))
    );
  }, [currentRentalObjectRooms]);

  const isValid = useMemo(() => {
    return !isErrors.some((isError) => isError);
  }, [isErrors]);

  // const isFieldsChanged = useMemo(() => {
  //   return JSON.stringify(currentRentalObjectRooms) !== JSON.stringify(newRoomList);
  // }, [currentRentalObjectRooms, newRoomList]);

  return (
    <>
      <h3 className={styles.contentTitle}>Комнаты (спальни)</h3>
      {roomList.length > 0 && (
        <div className={styles.roomCardListWrapper}>
          {roomList.map((item, index) => {
            return (
              <RoomCard
                key={item.room.id}
                isSave={isSave}
                room={item}
                roomIndex={index}
                setIsError={setIsErrors}
                setRoomData={setNewRoomList}
              />
            );
          })}
        </div>
      )}
      <Button
        title="Добавить комнату"
        type={ButtonType.PRIMARY_SMALL}
        onClick={onAddRoomBtnClick}
      />
      <div className={styles.mainBtnsWrapper}>
        <Button
          title="Сохранить"
          type={ButtonType.PRIMARY_SMALL}
          onClick={onSaveObjectBtnClick}
          disabled={!isValid}
        />
        <Button
          title={/*isFieldsChanged ? "Отмена" : "Назад"*/ "Назад"}
          type={ButtonType.SECONDARY_SMALL}
          path={`${RoutesList.objectManagement.objectEditingChild.fullPath}/${mainId}/${TabsNames.OBJECT_EDITING}`}
        />
      </div>
    </>
  );
};

export default PlacementSettings;
