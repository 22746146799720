import React, { useState } from "react";
import { useSelector } from "react-redux";
import { UserSelectors } from "../../../redux/reducers/userSlice";
import RadioButton from "../../../components/RadioButton";
import IndividualSettings from "../IndividualSettings";
import EntitySettings from "../EntitySettings";

import styles from "./OrganizationWrap.module.scss";

const OrganizationWrap = () => {
  const owner = useSelector(UserSelectors.getOwnerInfo);

  const RadioButtonList = ["Физическое лицо", "Юридическое лицо"];
  const [activeRadioBtn, setActiveRadioBtn] = useState<string>(RadioButtonList[0]);

  return (
    <>
      <h3 className={styles.title}>Данные об организации</h3>
      <RadioButton
        activeTab={activeRadioBtn}
        btnsList={RadioButtonList}
        onClick={setActiveRadioBtn}
        size="big"
      />
      <div className={styles.contentContainer}>
        {activeRadioBtn === RadioButtonList[1] ? (
          <EntitySettings id={owner?.entity_id} />
        ) : (
          <IndividualSettings id={owner?.individual_id} />
        )}
      </div>
    </>
  );
};

export default OrganizationWrap;
