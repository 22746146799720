import React from "react";

export const EditIcon = ({ width = "24", height = "24", fill = "#8692A6" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.96867 13.9157L13.6592 4.41662C14.906 3.19446 16.9283 3.19446 18.175 4.41662L19.4694 5.6854C20.7162 6.90755 20.7162 8.88985 19.4694 10.112L9.74061 19.6486C9.1843 20.1939 8.43007 20.4999 7.64282 20.4999H3.65854C3.28841 20.4999 2.99098 20.201 3.00021 19.8383L3.10043 15.8975C3.12036 15.1526 3.43127 14.4425 3.96867 13.9157ZM18.5367 6.59832L17.2423 5.32954C16.5099 4.61157 15.3216 4.61157 14.5891 5.32954L13.8374 6.06644L17.7853 9.93559L18.5367 9.1991C19.2691 8.48113 19.2691 7.31628 18.5367 6.59832ZM4.89856 14.8287L12.906 6.97936L16.854 10.8485L8.80788 18.7357L8.70086 18.8317C8.40528 19.0745 8.0308 19.2089 7.6414 19.2089L4.33203 19.2084L4.41565 15.9305C4.42672 15.5169 4.59984 15.1215 4.89856 14.8287ZM21 19.8545C21 19.498 20.7052 19.2089 20.3415 19.2089H13.471L13.3816 19.2148C13.0602 19.2576 12.8125 19.5277 12.8125 19.8545C12.8125 20.211 13.1073 20.5 13.471 20.5H20.3415L20.4308 20.4941C20.7523 20.4514 21 20.1813 21 19.8545Z"
        fill={fill}
      />
    </svg>
  );
};
