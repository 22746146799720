import React from "react";

export const Arrow2Icon = ({ width = "25", height = "25", fill = "#696F79" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7904 8.854L12.4987 16.1457L5.20703 8.854"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
