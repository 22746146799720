import React from "react";

export const Armenia = ({ width = "16", height = "12"}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="flag-icon-css-am" viewBox="0 0 640 480" 
      width={width} height={height}>
      <path fill="red" d="M0 0h640v160H0z"/>
      <path fill="#00f" d="M0 160h640v160H0z"/>
      <path fill="orange" d="M0 320h640v160H0z"/>
    </svg>
  );
};