
import { BookingFormType } from "../../../redux/types/bookingTypes";
import { CloseIcon, DeleteIcon, EditIcon } from "../../../assets/icons";
import { getTitle } from "../functions";
import Button from "../../Button";
import { ButtonType} from "../../../utils/@globalTypes";
import classNames from "classnames";

import styles from "./BookingHeader.module.scss";
import { FormikProps } from "formik";

type HeaderProps ={
    windowType: BookingFormType,
    handleClickEdit?:()=> void,     
    handleSubmit?:()=>void,   
    handleClickDelete?:()=> void,  
    isLast?:boolean,
    isReadyToDelete?:boolean,
    formik:FormikProps<any>,
    windowClose:()=>void;
}

const BookingHeader = ({
    windowType, 
    handleClickEdit, 
    handleClickDelete,
    isLast,
    isReadyToDelete,
    formik,
    windowClose
    }:HeaderProps) => {
        
    const title = getTitle(windowType);    
    const {dirty} = formik;
    
       
    return (
        <div className={styles.container}>
            <div className={styles.title}>
                {title}
                {windowType===BookingFormType.edit && 
                    <span className={styles.titleAdd}>(Режим редактирования)</span>
                }
            </div>
            <div className={styles.btnPanel}>
                {windowType===BookingFormType.view && !isLast&& 
                <div className={classNames(styles.btnEdit, styles.desktop)}>
                    <Button 
                        title="Редактировать" 
                        type={ButtonType.PRIMARY_SMALL}
                        onClick={handleClickEdit}
                        />
                </div>}
                {windowType===BookingFormType.view && !isLast&& 
                <div className={classNames(styles.btnEdit, styles.mob)}>
                    <Button 
                        type={ButtonType.SMALL}
                        title={<EditIcon />}
                        onClick={handleClickEdit}
                        />
                </div>}
                {!isLast&& <div className={styles.btnSave}>
                    <Button 
                        title="Сохранить" 
                        disabled={!dirty}
                        type={ButtonType.PRIMARY_SMALL} 
                        actionType="submit"                        
                    />                        
                </div>}
                {windowType!==BookingFormType.new &&  
                <div className={classNames(styles.btnDel, styles.desktop, isReadyToDelete&&styles.btnActiveDel)}>
                    <Button 
                        title="Удалить" 
                        type={ButtonType.PRIMARY_SMALL}
                        onClick={handleClickDelete}
                        disabled={!isReadyToDelete}
                        confirmWindowText="Вы действительно хотите удалить бронь?"
                        className="deleteBookingList"
                        // wrapperClassName="bookingList"
                        windowClassName="bookingFormConfirm"
                    />
                    {!isReadyToDelete&&<div className={styles.help}>
                        Для удаления брони необходимо изменить ее статус на "Отменена"
                    </div>}    
                </div>}
                {windowType!==BookingFormType.new &&  
                <div className={classNames(styles.btnDel, styles.mob, isReadyToDelete&&styles.btnActiveDel)}>
                    <Button 
                        type={ButtonType.SMALL}
                        title={<DeleteIcon />}
                        className="deleteBookingList"
                        wrapperClassName="bookingList"
                        windowClassName="bookingFormConfirm"
                        onClick={handleClickDelete}
                        disabled={!isReadyToDelete}
                        confirmWindowText="Вы действительно хотите удалить бронь?"     
                        
                    />
                    {!isReadyToDelete&&<div className={styles.help}>
                        Для удаления брони необходимо изменить ее статус на "Отменена"
                    </div>}    
                </div>}
                <div className={styles.btnClose} onClick={windowClose}>
                    <CloseIcon/>
                </div>
                
            </div>

        </div>
    )
}
export default BookingHeader ;