

import styles from "./BookingLineTitle.module.scss";

type MyProps ={
  title:string
}

const BookingLineTitle = ({title}:MyProps ) => {
  
  return (
    <>
      <div className={styles.title}>{title}</div>      
    </>
    )
}

export default BookingLineTitle ;
