import React, { FC } from "react";
import classNames from "classnames";
import styles from "./MainObjectCard.module.scss";
import { Home2Icon, LocationIcon } from "../../assets/icons";
import Button from "../Button";
import { AddressType, ButtonType } from "../../utils/@globalTypes";
import { RoutesList } from "../../App";
import Loader from "../Loader";

type MainObjectCardProps = {
  id: string;
  name: string;
  photo: string | null;
  objectType: string;
  address: AddressType | null;
  country?: string;
  // workPhone?: string;
  // mobilePhone?: string;
  // email?: string;
  // userName?: string;
  // jobTitle?: string;
  isLoading: boolean;
  onDeleteBtnClick: () => void;
};

const MainObjectCard: FC<MainObjectCardProps> = ({
  id,
  name,
  photo,
  objectType,
  address,
  country,
  // workPhone,
  // mobilePhone,
  // email,
  // userName,
  // jobTitle,
  isLoading,
  onDeleteBtnClick,
}) => {
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.objectTitle}>{name}</h2>
      <div className={styles.imgWrapper}>
        {photo ? <img src={photo} alt={name} /> : <Home2Icon />}
      </div>
      <div className={styles.objectInfoWrapper}>
        <p className={styles.objectType}>{objectType}</p>
        {country && (
          <div className={classNames(styles.objectInfoItem, styles.onlyCountry)}>
            <div className={styles.objectInfoTitle}>Адрес объекта:</div>
            <div className={styles.objectInfoDescrWrapper}>
              <div className={styles.objectInfoDescr}>
                <LocationIcon />
                <p className={styles.fullAdressText}>
                  {country}
                  {address && `, ${address.region}, ${address.locality}, ${address.address}`}
                </p>
              </div>
            </div>
          </div>
        )}
        {/* {jobTitle && name && (workPhone || mobilePhone || email) && (
          <div className={styles.objectInfoItem}>
            <div className={styles.objectInfoTitle}>{jobTitle}:</div>
            <p>{userName}</p>
            <div className={styles.objectInfoDescrWrapper}>
              <div className={styles.objectInfoDescr}>
                <TelephoneIcon />
                <div>
                  <p>{workPhone}</p>
                  <p>{mobilePhone}</p>
                </div>
              </div>
              <div className={styles.objectInfoDescr}>
                <MessageIcon />
                <div>
                  <p>{email}</p>
                </div>
              </div>
            </div>
          </div>
        )} */}
      </div>
      <div className={styles.cardBtnWrapper}>
        <Button
          title="Редактировать"
          path={`${RoutesList.objectManagement.objectEditingChild.part}/${id}`}
          type={ButtonType.PRIMARY_SMALL}
        />
        <Button
          title="Удалить"
          onClick={onDeleteBtnClick}
          type={ButtonType.SECONDARY_SMALL}
          confirmWindowText="Действительно хотите удалить?"
        />
      </div>
      {isLoading && (
        <div className={styles.loaderOverlay}>
          <Loader size={50} />
        </div>
      )}
    </div>
  );
};

export default MainObjectCard;
