import React from "react";

export const ShortLogo = ({ width = "80", height = "60", fill = "#ffffff" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2161_11903)">
        <path
          d="M41.6107 56.6575H37.6632L58.2343 12.4626H67.212L44.1576 60.2237H34.7986L11.7441 12.4626H20.788L41.6107 56.6575Z"
          fill={fill}
        />
        <path
          d="M50.0573 28.7583H28.833L31.1766 23.8729L35.7493 14.3486H43.141L47.7138 23.8729L50.0573 28.7583Z"
          fill="#F39130"
        />
        <path d="M44.267 10.6885H34.6233L39.6155 0.226318L44.267 10.6885Z" fill="#69C931" />
      </g>
      <defs>
        <clipPath id="clip0_2161_11903">
          <rect width="55.4237" height="60" fill="white" transform="translate(12)" />
        </clipPath>
      </defs>
    </svg>
  );
};
