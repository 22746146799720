import React from "react";

export const KitchenIcon = ({ width = "24", height = "24", fill = "#0F2664" }) => {
  return (
    <div className="feature">
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.9832 20.3855C21.5441 20.3855 20.1004 20.3855 18.6613 20.3855C15.6895 20.3855 12.7129 20.3855 9.74102 20.3855C7.25664 20.3855 4.77695 20.3855 2.29258 20.3855C1.86602 20.3855 1.43945 20.3855 1.01758 20.3855C1.13477 20.5027 1.25195 20.6199 1.36914 20.7371C1.36914 18.5762 1.36914 16.4152 1.36914 14.2543C1.36914 12.5621 1.36914 10.8699 1.36914 9.17773C1.25195 9.29492 1.13477 9.41211 1.01758 9.5293C2.45664 9.5293 3.90039 9.5293 5.33945 9.5293C8.31133 9.5293 11.2879 9.5293 14.2598 9.5293C16.7441 9.5293 19.2238 9.5293 21.7082 9.5293C22.1348 9.5293 22.5613 9.5293 22.9832 9.5293C22.866 9.41211 22.7488 9.29492 22.6316 9.17773C22.6316 11.3387 22.6316 13.4996 22.6316 15.6605C22.6316 17.3527 22.6316 19.0449 22.6316 20.7371C22.6316 21.1918 23.3348 21.1918 23.3348 20.7371C23.3348 18.5762 23.3348 16.4152 23.3348 14.2543C23.3348 12.5621 23.3348 10.8699 23.3348 9.17773C23.3348 8.98555 23.1754 8.82617 22.9832 8.82617C21.5441 8.82617 20.1004 8.82617 18.6613 8.82617C15.6895 8.82617 12.7129 8.82617 9.74102 8.82617C7.25664 8.82617 4.77695 8.82617 2.29258 8.82617C1.86602 8.82617 1.43945 8.82617 1.01758 8.82617C0.825391 8.82617 0.666016 8.98555 0.666016 9.17773C0.666016 11.3387 0.666016 13.4996 0.666016 15.6605C0.666016 17.3527 0.666016 19.0449 0.666016 20.7371C0.666016 20.9293 0.825391 21.0887 1.01758 21.0887C2.45664 21.0887 3.90039 21.0887 5.33945 21.0887C8.31133 21.0887 11.2879 21.0887 14.2598 21.0887C16.7441 21.0887 19.2238 21.0887 21.7082 21.0887C22.1348 21.0887 22.5613 21.0887 22.9832 21.0887C23.4379 21.0887 23.4379 20.3855 22.9832 20.3855Z"
          fill={fill}
        />
        <path
          d="M22.9832 20.3855C21.5441 20.3855 20.1004 20.3855 18.6613 20.3855C15.6895 20.3855 12.7129 20.3855 9.74102 20.3855C7.25664 20.3855 4.77695 20.3855 2.29258 20.3855C1.86602 20.3855 1.43945 20.3855 1.01758 20.3855C1.13477 20.5027 1.25195 20.6199 1.36914 20.7371C1.36914 18.4027 1.36914 16.0637 1.36914 13.7293C1.36914 13.4293 1.36914 13.134 1.36914 12.834C1.25195 12.9512 1.13477 13.0684 1.01758 13.1855C2.45664 13.1855 3.90039 13.1855 5.33945 13.1855C8.31133 13.1855 11.2879 13.1855 14.2598 13.1855C16.7441 13.1855 19.2238 13.1855 21.7082 13.1855C22.1348 13.1855 22.5613 13.1855 22.9832 13.1855C22.866 13.0684 22.7488 12.9512 22.6316 12.834C22.6316 15.1684 22.6316 17.5074 22.6316 19.8418C22.6316 20.1418 22.6316 20.4371 22.6316 20.7371C22.6316 21.1918 23.3348 21.1918 23.3348 20.7371C23.3348 18.4027 23.3348 16.0637 23.3348 13.7293C23.3348 13.4293 23.3348 13.134 23.3348 12.834C23.3348 12.6418 23.1754 12.4824 22.9832 12.4824C21.5441 12.4824 20.1004 12.4824 18.6613 12.4824C15.6895 12.4824 12.7129 12.4824 9.74102 12.4824C7.25664 12.4824 4.77695 12.4824 2.29258 12.4824C1.86602 12.4824 1.43945 12.4824 1.01758 12.4824C0.825391 12.4824 0.666016 12.6418 0.666016 12.834C0.666016 15.1684 0.666016 17.5074 0.666016 19.8418C0.666016 20.1418 0.666016 20.4371 0.666016 20.7371C0.666016 20.9293 0.825391 21.0887 1.01758 21.0887C2.45664 21.0887 3.90039 21.0887 5.33945 21.0887C8.31133 21.0887 11.2879 21.0887 14.2598 21.0887C16.7441 21.0887 19.2238 21.0887 21.7082 21.0887C22.1348 21.0887 22.5613 21.0887 22.9832 21.0887C23.4379 21.0887 23.4379 20.3855 22.9832 20.3855Z"
          fill={fill}
        />
        <path
          d="M7.69727 9.17695C7.69727 11.3379 7.69727 13.4988 7.69727 15.6598C7.69727 17.352 7.69727 19.0441 7.69727 20.7363C7.69727 21.191 8.40039 21.191 8.40039 20.7363C8.40039 18.5754 8.40039 16.4145 8.40039 14.2535C8.40039 12.5613 8.40039 10.8691 8.40039 9.17695C8.40039 8.72227 7.69727 8.72227 7.69727 9.17695Z"
          fill={fill}
        />
        <path
          d="M22.983 20.3855C21.0986 20.3855 19.2143 20.3855 17.3252 20.3855C14.5877 20.3855 11.8455 20.3855 9.10801 20.3855C8.75176 20.3855 8.4002 20.3855 8.04395 20.3855C8.16113 20.5027 8.27832 20.6199 8.39551 20.7371C8.39551 18.4027 8.39551 16.0637 8.39551 13.7293C8.39551 13.4293 8.39551 13.134 8.39551 12.834C8.27832 12.9512 8.16113 13.0684 8.04395 13.1855C9.92832 13.1855 11.8127 13.1855 13.7018 13.1855C16.4393 13.1855 19.1814 13.1855 21.9189 13.1855C22.2752 13.1855 22.6268 13.1855 22.983 13.1855C22.8658 13.0684 22.7486 12.9512 22.6314 12.834C22.6314 15.1684 22.6314 17.5074 22.6314 19.8418C22.6314 20.1418 22.6314 20.4371 22.6314 20.7371C22.6314 21.1918 23.3346 21.1918 23.3346 20.7371C23.3346 18.4027 23.3346 16.0637 23.3346 13.7293C23.3346 13.4293 23.3346 13.134 23.3346 12.834C23.3346 12.6418 23.1752 12.4824 22.983 12.4824C21.0986 12.4824 19.2143 12.4824 17.3252 12.4824C14.5877 12.4824 11.8455 12.4824 9.10801 12.4824C8.75176 12.4824 8.4002 12.4824 8.04395 12.4824C7.85176 12.4824 7.69238 12.6418 7.69238 12.834C7.69238 15.1684 7.69238 17.5074 7.69238 19.8418C7.69238 20.1418 7.69238 20.4371 7.69238 20.7371C7.69238 20.9293 7.85176 21.0887 8.04395 21.0887C9.92832 21.0887 11.8127 21.0887 13.7018 21.0887C16.4393 21.0887 19.1814 21.0887 21.9189 21.0887C22.2752 21.0887 22.6268 21.0887 22.983 21.0887C23.4377 21.0887 23.4377 20.3855 22.983 20.3855Z"
          fill={fill}
        />
        <path
          d="M15.1641 12.8332C15.1641 15.1676 15.1641 17.5066 15.1641 19.841C15.1641 20.141 15.1641 20.4363 15.1641 20.7363C15.1641 21.191 15.8672 21.191 15.8672 20.7363C15.8672 18.402 15.8672 16.0629 15.8672 13.7285C15.8672 13.4285 15.8672 13.1332 15.8672 12.8332C15.8672 12.3785 15.1641 12.3785 15.1641 12.8332Z"
          fill={fill}
        />
        <path
          d="M14.4992 11.3945C15.1789 11.3945 15.8586 11.3945 16.5383 11.3945C16.993 11.3945 16.993 10.6914 16.5383 10.6914C15.8586 10.6914 15.1789 10.6914 14.4992 10.6914C14.0445 10.6914 14.0445 11.3945 14.4992 11.3945Z"
          fill={fill}
        />
        <path
          d="M22.983 12.4824C21.0986 12.4824 19.2143 12.4824 17.3252 12.4824C14.5877 12.4824 11.8455 12.4824 9.10801 12.4824C8.75176 12.4824 8.4002 12.4824 8.04395 12.4824C8.16113 12.5996 8.27832 12.7168 8.39551 12.834C8.39551 11.6152 8.39551 10.3965 8.39551 9.17773C8.27832 9.29492 8.16113 9.41211 8.04395 9.5293C9.92832 9.5293 11.8127 9.5293 13.7018 9.5293C16.4393 9.5293 19.1814 9.5293 21.9189 9.5293C22.2752 9.5293 22.6268 9.5293 22.983 9.5293C22.8658 9.41211 22.7486 9.29492 22.6314 9.17773C22.6314 10.3965 22.6314 11.6152 22.6314 12.834C22.6314 13.2887 23.3346 13.2887 23.3346 12.834C23.3346 11.6152 23.3346 10.3965 23.3346 9.17773C23.3346 8.98555 23.1752 8.82617 22.983 8.82617C21.0986 8.82617 19.2143 8.82617 17.3252 8.82617C14.5877 8.82617 11.8455 8.82617 9.10801 8.82617C8.75176 8.82617 8.4002 8.82617 8.04395 8.82617C7.85176 8.82617 7.69238 8.98555 7.69238 9.17773C7.69238 10.3965 7.69238 11.6152 7.69238 12.834C7.69238 13.0262 7.85176 13.1855 8.04395 13.1855C9.92832 13.1855 11.8127 13.1855 13.7018 13.1855C16.4393 13.1855 19.1814 13.1855 21.9189 13.1855C22.2752 13.1855 22.6268 13.1855 22.983 13.1855C23.4377 13.1855 23.4377 12.4824 22.983 12.4824Z"
          fill={fill}
        />
        <path
          d="M6.71738 19.0965C5.25957 19.0965 3.80645 19.0965 2.34863 19.0965C2.46582 19.2137 2.58301 19.3309 2.7002 19.448C2.7002 17.6715 2.7002 15.8996 2.7002 14.123C2.58301 14.2402 2.46582 14.3574 2.34863 14.4746C3.80645 14.4746 5.25957 14.4746 6.71738 14.4746C6.6002 14.3574 6.48301 14.2402 6.36582 14.123C6.36582 15.8996 6.36582 17.6715 6.36582 19.448C6.36582 19.9027 7.06895 19.9027 7.06895 19.448C7.06895 17.6715 7.06895 15.8996 7.06895 14.123C7.06895 13.9309 6.90957 13.7715 6.71738 13.7715C5.25957 13.7715 3.80645 13.7715 2.34863 13.7715C2.15645 13.7715 1.99707 13.9309 1.99707 14.123C1.99707 15.8996 1.99707 17.6715 1.99707 19.448C1.99707 19.6402 2.15645 19.7996 2.34863 19.7996C3.80645 19.7996 5.25957 19.7996 6.71738 19.7996C7.16738 19.7996 7.16738 19.0965 6.71738 19.0965Z"
          fill={fill}
        />
        <path
          d="M8.04883 20.3855C5.78945 20.3855 3.53477 20.3855 1.27539 20.3855C1.19102 20.3855 1.10195 20.3855 1.01758 20.3855C1.13477 20.5027 1.25195 20.6199 1.36914 20.7371C1.36914 18.4027 1.36914 16.0637 1.36914 13.7293C1.36914 13.4293 1.36914 13.134 1.36914 12.834C1.25195 12.9512 1.13477 13.0684 1.01758 13.1855C3.27695 13.1855 5.53164 13.1855 7.79102 13.1855C7.87539 13.1855 7.96445 13.1855 8.04883 13.1855C7.93164 13.0684 7.81445 12.9512 7.69727 12.834C7.69727 15.1684 7.69727 17.5074 7.69727 19.8418C7.69727 20.1418 7.69727 20.4371 7.69727 20.7371C7.69727 21.1918 8.40039 21.1918 8.40039 20.7371C8.40039 18.4027 8.40039 16.0637 8.40039 13.7293C8.40039 13.4293 8.40039 13.134 8.40039 12.834C8.40039 12.6418 8.24102 12.4824 8.04883 12.4824C5.78945 12.4824 3.53477 12.4824 1.27539 12.4824C1.19102 12.4824 1.10195 12.4824 1.01758 12.4824C0.825391 12.4824 0.666016 12.6418 0.666016 12.834C0.666016 15.1684 0.666016 17.5074 0.666016 19.8418C0.666016 20.1418 0.666016 20.4371 0.666016 20.7371C0.666016 20.9293 0.825391 21.0887 1.01758 21.0887C3.27695 21.0887 5.53164 21.0887 7.79102 21.0887C7.87539 21.0887 7.96445 21.0887 8.04883 21.0887C8.50352 21.0887 8.50352 20.3855 8.04883 20.3855Z"
          fill={fill}
        />
        <path
          d="M14.2178 17.8078C14.2178 17.1281 14.2178 16.4484 14.2178 15.7687C14.2178 15.3141 13.5146 15.3141 13.5146 15.7687C13.5146 16.4484 13.5146 17.1281 13.5146 17.8078C13.5146 18.2578 14.2178 18.2578 14.2178 17.8078Z"
          fill={fill}
        />
        <path
          d="M17.5176 17.8078C17.5176 17.1281 17.5176 16.4484 17.5176 15.7687C17.5176 15.3141 16.8145 15.3141 16.8145 15.7687C16.8145 16.4484 16.8145 17.1281 16.8145 17.8078C16.8145 18.2578 17.5176 18.2578 17.5176 17.8078Z"
          fill={fill}
        />
        <path
          d="M3.47337 11.0437C3.44993 11.5921 2.64837 11.5921 2.62493 11.0437C2.60618 10.4999 3.45462 10.4999 3.47337 11.0437C3.49212 11.4937 4.19524 11.4984 4.17649 11.0437C4.15306 10.4109 3.67962 9.94212 3.05149 9.91868C2.42337 9.89524 1.94993 10.4531 1.92649 11.0437C1.90306 11.6718 2.46087 12.1452 3.05149 12.1687C3.67962 12.1921 4.15306 11.6343 4.17649 11.0437C4.19524 10.5937 3.49212 10.5937 3.47337 11.0437Z"
          fill={fill}
        />
        <path
          d="M6.43626 11.0437C6.41282 11.5921 5.61126 11.5921 5.58782 11.0437C5.56438 10.4999 6.41282 10.4999 6.43626 11.0437C6.45501 11.4937 7.15814 11.4984 7.13939 11.0437C7.11595 10.4109 6.64251 9.94212 6.01439 9.91868C5.38626 9.89524 4.91282 10.4531 4.88939 11.0437C4.86595 11.6718 5.42376 12.1452 6.01439 12.1687C6.64251 12.1921 7.11595 11.6343 7.13939 11.0437C7.15814 10.5937 6.45501 10.5937 6.43626 11.0437Z"
          fill={fill}
        />
        <path
          d="M2.07206 8.65742C2.19393 8.8918 2.31581 9.12617 2.43768 9.35586C2.49393 9.46367 2.6205 9.5293 2.74237 9.5293C3.933 9.5293 5.12831 9.5293 6.31893 9.5293C6.43612 9.5293 6.56737 9.46367 6.62362 9.35586C6.933 8.76992 7.27518 8.20742 7.27518 7.51836C7.27518 7.07773 7.27518 6.6418 7.27518 6.20117C7.27518 6.00898 7.11581 5.84961 6.92362 5.84961C5.32518 5.84961 3.73143 5.84961 2.133 5.84961C1.94081 5.84961 1.78143 6.00898 1.78143 6.20117C1.78612 7.03086 1.68768 7.89336 2.07206 8.65742C2.27362 9.06523 2.883 8.70898 2.68143 8.30117C2.48925 7.9168 2.48924 7.52773 2.48924 7.11055C2.48924 6.80586 2.48924 6.50586 2.48924 6.20117C2.37206 6.31836 2.25487 6.43555 2.13768 6.55273C3.73612 6.55273 5.32987 6.55273 6.92831 6.55273C6.81112 6.43555 6.69393 6.31836 6.57675 6.20117C6.57675 6.63242 6.57675 7.05898 6.57675 7.49023C6.57675 8.05742 6.27206 8.5168 6.01893 8.99961C6.12206 8.94336 6.2205 8.88242 6.32362 8.82617C5.133 8.82617 3.93768 8.82617 2.74706 8.82617C2.85018 8.88242 2.94862 8.94336 3.05175 8.99961C2.92987 8.76523 2.808 8.53086 2.68612 8.30117C2.46581 7.89805 1.86112 8.2543 2.07206 8.65742Z"
          fill={fill}
        />
        <path
          d="M8.04922 6.60352C7.67422 6.60352 7.30391 6.60352 6.92891 6.60352C6.47422 6.60352 6.47422 7.30664 6.92891 7.30664C7.30391 7.30664 7.67422 7.30664 8.04922 7.30664C8.50391 7.30664 8.50391 6.60352 8.04922 6.60352Z"
          fill={fill}
        />
        <path
          d="M2.13809 6.60352C1.76309 6.60352 1.39277 6.60352 1.01777 6.60352C0.563086 6.60352 0.563086 7.30664 1.01777 7.30664C1.39277 7.30664 1.76309 7.30664 2.13809 7.30664C2.59277 7.30664 2.59277 6.60352 2.13809 6.60352Z"
          fill={fill}
        />
        <path
          d="M13.7531 4.28906C16.0734 4.28906 18.3984 4.28906 20.7188 4.28906C21.4688 4.28906 22.2188 4.28906 22.9688 4.28906C23.4234 4.28906 23.4234 3.58594 22.9688 3.58594C20.6484 3.58594 18.3234 3.58594 16.0031 3.58594C15.2531 3.58594 14.5031 3.58594 13.7531 3.58594C13.2984 3.58594 13.2984 4.28906 13.7531 4.28906Z"
          fill={fill}
        />
        <path
          d="M17.5449 6.18203C17.5449 5.22109 17.5449 4.26016 17.5449 3.30391C17.5449 2.84922 16.8418 2.84922 16.8418 3.30391C16.8418 4.26484 16.8418 5.22578 16.8418 6.18203C16.8418 6.63672 17.5449 6.63672 17.5449 6.18203Z"
          fill={fill}
        />
        <path
          d="M17.6199 6.95579C17.5964 7.50423 16.7949 7.50423 16.7714 6.95579C16.748 6.41204 17.5964 6.41204 17.6199 6.95579C17.6386 7.40579 18.3417 7.41048 18.323 6.95579C18.2995 6.32298 17.8261 5.85423 17.198 5.83079C16.5699 5.80735 16.0964 6.36517 16.073 6.95579C16.0495 7.58392 16.6074 8.05735 17.198 8.08079C17.8261 8.10423 18.2995 7.54642 18.323 6.95579C18.337 6.50579 17.6339 6.50579 17.6199 6.95579Z"
          fill={fill}
        />
        <path
          d="M21.8018 6.13984C21.8018 5.17891 21.8018 4.21797 21.8018 3.25703C21.8018 2.80234 21.0986 2.80234 21.0986 3.25703C21.0986 4.21797 21.0986 5.17891 21.0986 6.13984C21.0986 6.59453 21.8018 6.59453 21.8018 6.13984Z"
          fill={fill}
        />
        <path
          d="M21.8767 6.91282C21.8533 7.46126 21.0517 7.46126 21.0283 6.91282C21.0048 6.36907 21.8533 6.36907 21.8767 6.91282C21.8954 7.36282 22.5986 7.36751 22.5798 6.91282C22.5564 6.28001 22.0829 5.81126 21.4548 5.78782C20.8267 5.76439 20.3533 6.3222 20.3298 6.91282C20.3064 7.54095 20.8642 8.01439 21.4548 8.03782C22.0829 8.06126 22.5564 7.50345 22.5798 6.91282C22.5939 6.46282 21.8908 6.46282 21.8767 6.91282Z"
          fill={fill}
        />
      </svg>
      <div>Кухня</div>
    </div>
  );
};
